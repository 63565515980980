import { curry } from 'lib/util'
import countLocations from 'app/_shared/events/search/count'
import searchLocations from 'app/_shared/events/search'
import searchWith from 'app/_shared/events/search/with'

/**
 * My Locations Event Handler
 *
 * Gets the count of the users locations and the location itself in case
 * the count is 1.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      the event object that triggered this handler
 *
 * @return {Object}                     the count and optionally a device
 */
const countLocationsFn = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const additionalParameters = await searchWith(['query'], module, component, event)
  const items = await countLocations(null, module, component, additionalParameters)

  const item = items.total.count !== 1
    ? await searchLocations(null, module, component, additionalParameters)
    : null

  return {
    total: { count: items.total.count },
    ...item && {
      item: {
        title: item[0]?.value?.name,
        key: item[0]?.key,
      },
    },
  }
}

export default curry(countLocationsFn)
