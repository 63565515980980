/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Open Electric Diagram Event Handler
 *
 * Gets the attachment information about the electric diagram and returns it
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.AppModule.Action} component action component
 * @param {Event} event                     the event that triggered this handler
 * @param {Object} event.detail             custom event details
 * @param {string} event.detail.key         a model key to pass to {@link redirectSequence}
 * @returns {{value, key}|null}
 */
const openElectricDiagram = (module, component, event) => {
  const model = module[G.MODEL][G.CHILDREN]?.equipment || {}
  const { schematic = {} } = model?.[G.CACHE] || {}
  const attachmentId = schematic?.value?.attachmentId || null

  if (!attachmentId) return null

  return {
    key: schematic.value.attachmentId,
    value: schematic.attachment.value,
  }
}

export default curry(openElectricDiagram)
