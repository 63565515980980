/* global G */
import { curry } from 'lib/util'
import seqModelRead from 'lib/sequence/model/api/read'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Swap Organisation And Action Event Handler
 *
 * Rebuild the current organisation model instance with the organisation the user clicked on by
 * Changing its {@code G.CACHE} to the incoming organisation and executing {@link seqModelRead}
 * again.
 *
 * Executes the current action afterward and rebuild the view.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<void>}
 * @constructor
 */
const swapOrganisationAndAction = async (module, component, event) => {
  const { organisation: model } = module[G.MODEL][G.CHILDREN]

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { detail: { item: organisation } } = event

  // We need to swap out {@code model[G.CHILDREN].organisation} with the org
  // the user clicked on. We do this be setting the cache to it and then
  // execute the model read sequence again.
  model[G.STATE][G.REF] = organisation.key
  model[G.CACHE] = {
    acl: model[G.CACHE].acl,
    ...organisation,
  }

  try {
    await seqModelRead(model)(actionComponent)
    await actionWithHooks(module[G.STATE][G.ACTION])([])

    // We need to rebuild the view
    await module[G.ADAPTER][G.UI].create(module)
  } catch (e) {
    console.error(e)
  }
}

export default curry(swapOrganisationAndAction)
