/* eslint-disable no-unused-vars */
/* global G */
import sequenceComponentState from 'lib/sequence/component/state'
import { settings } from 'app/_shared/session'

const {
  get: getFieldsConfig,
  set: setFieldsConfig,
} = sequenceComponentState('fields')

/**
 * If the serialFieldConfig setting is set, initializes the serial field component with it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const initSerialField = module => async (components, ...args) => {
  const { serial } = components

  if (settings.serialFieldConfig && !getFieldsConfig(serial)) {
    const config = settings.serialFieldConfig?.map(item => ({
      ...item,
      label: item.label ? module[G.ADAPTER][G.INTL]._t(
        `serialField.${item.key}.label`,
        {
          ns: 'custom',
          _key: `serialField.${item.key}.label`,
          defaultValue: item.label,
        },
      ) : '',
      placeholder: item.placeholder ? module[G.ADAPTER][G.INTL]._t(
        `serialField.${item.key}.placeholder`,
        {
          ns: 'custom',
          _key: `serialField.${item.key}.placeholder`,
          defaultValue: item.placeholder,
        },
      ) : '',
      helperText: item.helperText ? module[G.ADAPTER][G.INTL]._t(
        `serialField.${item.key}.helperText`,
        {
          ns: 'custom',
          _key: `serialField.${item.key}.helperText`,
          defaultValue: item.helperText,
        },
      ) : '',
    }))

    setFieldsConfig(serial, config)
  }

  return [components, ...args]
}

export default initSerialField
