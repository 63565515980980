/* global G */

/**
 * PPT Validator
 * @src apps/puppeteer/src/modules/fields/validators.js
 * @type {RegExp}
 */
const re = /[!?/.@#$%^&*|\-_]/

/**
 *
 * Model Password Validator
 *
 * validates data values on model level, propagating
 * to the same scope as children validators
 *
 * @param {Object} obj  the model object composition
 * @return {Object}     the call's arguments
 */
/**
 * So the idea would be as follows:
 * introduce error | another option name, which contains current validator names on model attribute
 * level set default errors for everything, since we are doing negation by default. error is true,
 * unless it is not
 *
 *
 * iterate over existing errors
 * @param obj
 * @returns {function(...[*]): *[]}
 */
export default obj => async (uiState, key) => {
  const password = uiState.value
  if (!re.test(password)) {
    throw TypeError(obj[G.CONFIGURATION].validator.password.options.error)
  }
  return [uiState, key]
}

// export default (value, options) => (re.test(value) ? true : throw TypeError(options.error))
