/* global G */
import { curry } from 'lib/util'

/**
 * Message Remove Event Handler
 *
 * Removes a single message and instructs the message adapter to delete it.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        information about the event that triggered this handler
 * @returns {Promise<void>}
 */
const remove = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const eventBus = module[G.ADAPTER][G.EVENTS]
  const { key, callback } = event.detail

  eventBus.dispatch(
    eventBus.type(G.MESSAGE, G.DELETE),
    {
      [G.DATA]: {
        key,
      },
      [G.FN]: callback,
    },
  )
}

export default curry(remove)
