/* eslint-disable no-param-reassign,no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { get, set } from 'lib/sequence/component/state/value'
import {
  back,
  bulk,
  list,
  persistChange,
  persistCheck,
  persistOption,
  persistOptions,
  redirect,
} from '@app/_shared/events'
import { add as addAttachment, remove } from 'app/_shared/events/attachment'
import { jump, next } from 'app/_shared/events/step'
import persistCheckAndAction from 'app/_shared/events/combined/persistCheckAndAction'
import listCountries from 'app/_shared/events/collection/listCountries'
import listUserCountries from 'app/_shared/events/collection/listUserCountries'
import listRegisterOrganisationTypes from 'app/_shared/events/collection/listRegisterOrganisationTypes'
import listRegisterRequestTypes from 'app/_shared/events/collection/listRegisterRequestTypes'
import search from 'app/_shared/events/search'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'
import actionByKey from '@app/_shared/events/action/byKey'
import chain from 'app/_shared/events/util/chain'
import setTab from '@app/_shared/events/tabs/set'
import reloadTab from '@app/_shared/events/tabs/reload'
import persistChangeAndAction from 'app/_shared/events/combined/persistChangeAndAction'
import debounce from 'app/_shared/events/util/debounce'
import listLanguages from 'app/_shared/events/collection/listLanguages'
import permission from 'app/_shared/events/acl/permission'
import openExternalPortal from 'app/_shared/events/redirect/openExternalPortal'
import validateLogin from 'app/registration/event/validateLogin'
import validateAccount from 'app/registration/event/validateAccount'
import changeLanguage from 'app/registration/event/changeLanguage'
import searchEquipment from 'app/registration/event/searchEquipment'
import redirectWithUsername from 'app/registration/event/redirectWithUsername'
import validateAndSubmit from 'app/registration/event/invite/validateAndSubmit'
import checkMailAndBulk from 'app/registration/event/checkMailAndBulk'

export default {
  acl: {
    /**
     * Third param being {@code true} will skip acl check, needs
     * to be done for pre-login.
     */
    permission: curry((module, component, _) => permission(module, component, true)),
  },
  onClick: {
    redirect,
    jump,
    next,
    bulk,
    checkMailAndBulk,
    validateLogin,
    validateRedirect: actionByKey('validateRedirect'),
    validateAndSubmit,
    completeRegister: actionByKey('completeRegister'),
    setContactTab: setTab('contact'),
    remove,
    redirectWithUsername,
    openExternalPortal,
    back,
  },
  onOpen: {
    listing: list('short'),
    listWithRefs: list(null),
    listVerbose: list('verbose'),
    listCountries,
    search: search(null),
    searchVerbose: search('verbose'),
    listLanguages,
  },
  onSearch: {
    searchAndRecreate,
  },
  onChange: {
    persistChange,
    persistSerialChange: curry(async (module, component, event) => {
      const moduleState = module[G.STATE]
      const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
      const { serialField } = asObject(actionComponent[G.CHILDREN])
      const { first, second } = asObject(serialField[G.CHILDREN])
      const firstValue = get(first)
      const secondValue = get(second)
      set(`${firstValue}-${secondValue}`, 'value', moduleState)
    }),
    persistOption,
    persistOptions,
    persistCheck,
    persistChangeAndAction,
    persistCheckAndAction,
    searchEquipment: debounce(chain(
      persistChange,
      searchEquipment,
    ), 300),
    persistFinishOption: chain(
      persistCheck,
      reloadTab,
    ),
    persistContactOption: chain(
      persistCheck,
      reloadTab,
    ),
    persistChangeAndValidateAccount: debounce(chain(
      persistChange,
      validateAccount,
    ), 500),
    persistLanguage: chain(
      persistOptions,
      changeLanguage,
    ),
    add: addAttachment,
    addAttachment,
  },
  onAttachment: {
    addAttachment,
  },
  getSelection: {
    listRegisterOrganisationTypes,
    listRegisterRequestTypes,
    listUserCountries,
  },
}
