/* eslint-disable no-return-assign,no-unused-vars */
/* global G, PLATFORM */
import map from 'lib/sequence/model/api/map'
import find from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { asyncPipeSpread, bulk } from 'lib/util'
import { get, set } from 'lib/sequence/component/state/value'
import { settings } from 'app/_shared/session'
import { disable } from 'lib/sequence/component/state/disabled'

const WIDE_LOGIN_CLASS = 'wideLogin'
const SECONDARY_LOGIN_CLASS = 'secondaryLogin'

const bulkHide = bulk(hide)
const bulkShow = bulk(show)

const {
  set: setClasses,
} = sequenceComponentState('classes')

/**
 * Copies the properties of {@param data} to the application's session state object.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Object} data                 an object containing the properties to be copied
 */
export const setSessionStateData = (module, data = {}) => {
  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]
  Object.keys(data).forEach(key => sessionState[key] = data[key])
  // setKey(data, G.DATA, sessionState)
}

/**
 * Returns the state of the current session.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {Object}                    an object representing the current session's state
 */
export const getSessionStateData = (module) => {
  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]
  // const data = sessionState[G.USER] || {}
  // setKey(data, G.DATA, sessionState)
  // return data
  return sessionState
}

/**
 * Toggles the display of the remember me checkbox.
 *
 * @type {Gaia.AppModule.ActionPartial}
 */
const toggleCheckboxDisplay = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { sticky, textRegister, btnRegister } = components

  PLATFORM === 'mobile'
    && bulkHide(sticky, textRegister, btnRegister)
    && (model[G.DATA].sticky = true)

  return [components, ...args]
}

/**
 * Toggles the display of the password field and the Continue and Log in buttons depending on
 * whether the username field has a valid value.
 *
 * @type {Gaia.AppModule.ActionPartial}
 */
const togglePasswordAndButtonsDisplay = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { password, btnContinue, btnLogin } = components
  const { username: usernameData } = model[G.DATA]
  const { username: sessionUsername = usernameData } = getSessionStateData(module)
  const { passwordGenerated } = model[G.STATE]

  PLATFORM === 'mobile' || settings.singleStepLogin || (sessionUsername && !passwordGenerated)
    ? bulkShow(password, btnLogin) && bulkHide(btnContinue)
    : bulkShow(btnContinue) && bulkHide(password, btnLogin)

  return [components, ...args]
}

/**
 * Maps the model or session username to the username field.
 *
 * @type {Gaia.AppModule.ActionPartial}
 */
const mapUsername = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { username: usernameData } = model[G.DATA]
  const { username: sessionUsername } = getSessionStateData(module)
  const { form, options, username } = components

  const bulkMapModel = bulk(map(model))

  !usernameData && bulkMapModel(form, options)
  sessionUsername && !get(username) && set(username, sessionUsername)

  return [components, ...args]
}

/**
 * Disables the email validator of the username field and changes its labels to 'Username'.
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const toggleUsernameEmailValidator = module => async (components, ...args) => {
  if (settings.allowNonEmailUsernames) {
    const model = module[G.MODEL]
    const { username } = model[G.CHILDREN]
    const { username: usernameField } = components

    const emptyValidator = username[G.VALIDATOR].find(validator => validator._name === 'notEmpty')
    const emailValidator = username[G.VALIDATOR].find(validator => validator._name === 'isEmail')

    const usernameFieldConfig = usernameField[G.CONFIGURATION]
    const emptyValidatorConfig = emptyValidator[G.CONFIGURATION]

    const tUsername = { ns: 'user', _key: 'label.username' }

    usernameFieldConfig.t.label.options = tUsername
    emptyValidatorConfig.t.options.label = tUsername
    disable(emailValidator)
  }
  return [components, ...args]
}

/**
 * Toggles the display of the legacy application block according to the {@code showLegacyAppText}
 * setting.
 *
 * @type {Gaia.AppModule.ActionPartial}
 */
const toggleLegacyAppBlockDisplay = module => async (components, ...args) => {
  const { legacyAppBlock } = components

  PLATFORM === 'web' && (settings.showLegacyAppText ? show(legacyAppBlock) : hide(legacyAppBlock))

  return [components, ...args]
}

/**
 * Toggles the action's ui layout according to the {@code wideLayoutLogin} and
 * {@code secondaryColorLogin} settings.
 *
 * @type {Gaia.AppModule.ActionPartial}
 */
const toggleLayoutClasses = module => async (components, ...args) => {
  const { component } = components
  const classes = component[G.PROPS].classes || []

  settings.wideLayoutLogin
    && !classes.includes(WIDE_LOGIN_CLASS)
    && classes.push(WIDE_LOGIN_CLASS)

  settings.secondaryColorLogin
    && !classes.includes(SECONDARY_LOGIN_CLASS)
    && classes.push(SECONDARY_LOGIN_CLASS)

  setClasses(component, classes)

  return [components, ...args]
}

/**
 * Hides or shows elements from the login screen depending on whether the user exists and is active.
 *
 * @param {Gaia.AppModule.Spec} module - application module
 * @returns {function}    a function expecting the index component
 */
export default module => component => async (...args) => await asyncPipeSpread(
  toggleLayoutClasses(module),
  toggleLegacyAppBlockDisplay(module),
  toggleUsernameEmailValidator(module),
  mapUsername(module),
  togglePasswordAndButtonsDisplay(module),
  toggleCheckboxDisplay(module),
)(find(component), ...args)
