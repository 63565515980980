/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import map from 'lib/sequence/model/api/map'
import find from 'lib/sequence/component/children/find'
import { show, hide } from 'lib/sequence/component/state/hidden'
import { get, reset, set } from 'lib/sequence/component/state/value'
import sequenceComponentState from 'lib/sequence/component/state'
import { asyncPipeSpread, asyncPipeSpreadIf, bulk } from 'lib/util'
import { checked } from 'lib/sequence/component/state/checked'
import initSerialField from 'app/_shared/action/partial/initSerialField'
import { CONFIRM } from 'app/_shared/events/setStepTab'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

const {
  get: getDecorators,
  set: setDecorators,
} = sequenceComponentState('decorators')

const {
  get: activeTab,
} = sequenceComponentState('activeTab')

/**
 * Merges {@param decorators} with the ones already found in the {@param component}'s state.
 *
 * @param {Gaia.Component} component  a component accepting a 'decorators' state
 * @param {Object} decorators         decorators to merge with the ones of the {@param component}
 */
const decorate = (component, decorators) => {
  const current = getDecorators(component)
  setDecorators(component, { ...current, ...decorators })
}

/**
 * Returns whether the checkbox "Register as installed at" from the Additional Party step is
 * checked.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {boolean}                   whether the device is marked to be registered at the
 *                                      selected additional party
 * @private
 */
const _checkedRegisterAtAdditionalParty = (module) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const { party } = asObject(stepper[G.CHILDREN])
  if (party) {
    const { confirm } = asObject(party[G.CHILDREN])
    const { registerInstalledAt } = asObject(confirm[G.CHILDREN])
    return !!checked(registerInstalledAt)
  }
  return false
}

/**
 * Translates the {@param component}'s template prop, replaces all {@param parameters} to be found
 * and stores the result as the value of its {@param key} state.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component component to translate
 * @param {Object} [parameters]           values to replace parts of the string
 * @param {string} [key='label']          the state property in which to store the result
 * @returns {Promise<void>}
 */
export const translate = async (module, component, parameters, key = 'label') => {
  const { t } = component[G.CONFIGURATION]
  const componentState = component[G.STATE]
  componentState[key] = await module[G.ADAPTER][G.INTL]._t(
    t.template.options._key,
    {
      ...t.template.options,
      defaultValue: component[G.PROPS].template,
      ...parameters,
    },
  )
}

/**
 * Toggles the display of the list of search results, the list of suggestions, the new item list or
 * the no item list depending on the current value of the search field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayLists = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg } = model[G.CHILDREN]

  const { search } = components
  const { searchField, list, newItemList, noItemList, suggestions } = find(search)

  if (!get(searchField)) {
    hide(newItemList)
    hide(noItemList)
    if (requesterOrg && requesterOrg[G.STATE][G.REF]) {
      suggestions[G.STATE][G.REF] = 'requesterOrg'
      show(suggestions)
    } else {
      hide(suggestions)
    }
    hide(list)
  } else {
    hide(suggestions)
    show(list)
  }

  return [components, ...args]
}

/**
 * If the model's requesterOrg attribute is set, marks the step as completed and sets the
 * attribute's name and serial to be shown as the step title and subtitle, otherwise sets the step
 * as non-completed and non-confirmed and clears its title and subtitle.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setStepState = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item } = model[G.CHILDREN]
  const { name, serial } = item[G.CHILDREN]

  const { component } = components
  const stepState = component[G.STATE]

  if (item[G.CACHE]) {
    stepState.completed = true
    // filling step's title and subtitle with model data
    stepState.title = name[G.CACHE]
    stepState.subtitle = serial[G.CACHE]
  } else {
    // setting step as incomplete and unconfirmed
    stepState.completed = false
    stepState.confirmed = false
    // clearing step's title and subtitle
    stepState.title = ''
    stepState.subtitle = ''
  }

  return [components, ...args]
}

/**
 * Checks whether the item is installed at some of the organisations stored in the ticket and shows
 * an error feedback and a checkbox to install it at requesterOrg if it already isn't.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const checkInstalledAt = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item, requesterOrg } = model[G.CHILDREN]
  const { equipment, installedAt } = item[G.CHILDREN]

  const { confirm } = components
  const { serviceItem } = asObject(confirm[G.CHILDREN])
  const serviceItemChildren = asObject(serviceItem[G.CHILDREN])
  const { installedAtInfo } = serviceItemChildren
  const { registerInstalledAt, registerServiceBy } = serviceItemChildren
  const installedAtInfoChildren = asObject(installedAtInfo[G.CHILDREN])
  const { installedAtLabel } = installedAtInfoChildren

  if (requesterOrg[G.CACHE]) {
    const requesterOrgRef = requesterOrg[G.STATE][G.REF]
    const installedAtRef = installedAt[G.STATE][G.REF]
    const installedAtCache = installedAt[G.CACHE]
    // const serviceByRef = serviceBy[G.STATE][G.REF]
    // const serviceByCache = serviceBy[G.CACHE]
    const equipmentCache = equipment[G.CACHE]
    const requesterOrgType = requesterOrg[G.CHILDREN].type[G.CACHE]
    const organisation = requesterOrg[G.CHILDREN].name[G.CACHE]
    const customer = requesterOrgType === 'customer'

    const isInstalledAt = installedAtRef === requesterOrgRef
    // const isServiceBy = serviceByRef === requesterOrgRef

    decorate(installedAtLabel, { error: customer && !isInstalledAt })
    // decorate(serviceByLabel, { error: !customer && !isServiceBy })

    const registerAtAdditionalParty = _checkedRegisterAtAdditionalParty(module)
    const canBeInstalledAt = requesterOrgRef && equipmentCache && customer
      && !registerAtAdditionalParty && (!installedAtCache || !isInstalledAt)
    // const canBeServiceBy = equipmentCache && !customer && (!serviceByCache || !isServiceBy)

    canBeInstalledAt ? show(registerInstalledAt) : hide(registerInstalledAt)
    // canBeServiceBy ? show(registerServiceBy) : hide(registerServiceBy)

    await translate(module, registerInstalledAt, { organisation })
    // await translate(module, registerServiceBy, { organisation })
  } else {
    decorate(installedAt, { error: false })
    bulkHide(registerInstalledAt, registerServiceBy)
  }

  return [components, ...args]
}

/**
 * Toggles the display of some labels according to the equipment model's data.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayEquipmentLabels = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item } = model[G.CHILDREN]
  const { equipment } = item[G.CHILDREN]
  const { confirm } = components
  const { labels } = find(confirm)
  const { noEquipment, notRegisteredYet } = find(labels)

  equipment[G.CACHE]
    ? hide(noEquipment)
    : hide(notRegisteredYet)
      && show(noEquipment)

  return [components, ...args]
}

/**
 * Toggles the display of the organisation that services the item according to the model's data.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayServiceBy = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item } = model[G.CHILDREN]
  const { serviceBy } = item[G.CHILDREN]
  const { confirm } = components
  const { serviceByLabel, serviceBySection } = find(confirm)

  serviceBy[G.CACHE]
    ? bulkShow(serviceByLabel, serviceBySection)
    : bulkHide(serviceByLabel, serviceBySection)

  return [components, ...args]
}

/**
 * Toggles the display of the organisation where the item is installed according to the model's
 * data.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayInstalledAt = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item } = model[G.CHILDREN]
  const { installedAt } = item[G.CHILDREN]
  const { confirm } = components
  const { installedAtLabel, installedAt: installedAtSection, notRegisteredYet } = find(confirm)

  installedAt[G.CACHE]
    ? bulkShow(installedAtLabel, installedAtSection)
      && hide(notRegisteredYet)
      && set(installedAtSection, [installedAt[G.CACHE]])
    : bulkHide(installedAtLabel, installedAtSection)
      && show(notRegisteredYet)
      && reset(installedAt)

  return [components, ...args]
}

/**
 * Maps the current item model's serial to its label by replacing its wildcard with the model value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapSerial = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item } = model[G.CHILDREN]
  const { serial } = item[G.CHILDREN]
  const { confirm } = components
  const { serviceItem } = find(confirm)
  const { serial: serialField } = find(serviceItem)

  await translate(module, serialField, { serial: serial[G.CACHE] }, 'value')

  return [components, ...args]
}

/**
 * Maps the current model's item attribute to the step's confirm view.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapItem = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item } = model[G.CHILDREN]
  const { confirm } = components
  const { serviceItem, installedAtInfo, serviceByInfo } = find(confirm);

  [serviceItem, installedAtInfo, serviceByInfo].map(map(item))

  return [components, ...args]
}

/**
 * Hides the lists that should only be shown depending on the device's search result.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const hideLists = module => async (components, ...args) => {
  const { newItemList, noItemList } = components

  hide(newItemList)
  hide(noItemList)

  return [components, ...args]
}

/**
 * Device identification step.
 *
 * If no device is selected, displays a list to search among them and a button to add a new
 * one. Otherwise, sets the step as complete and displays the selected device's data together
 * with buttons to edit it, to clear the confirm view and to go to the next step.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => await asyncPipeSpread(
  asyncPipeSpreadIf(() => activeTab(component) === CONFIRM)(
    hideLists(module),
    mapItem(module),
    initSerialField(module),
    mapSerial(module),
    displayInstalledAt(module),
    displayServiceBy(module),
    displayEquipmentLabels(module),
    checkInstalledAt(module),
  ),
  asyncPipeSpreadIf(() => activeTab(component) !== CONFIRM)(
    displayLists(module),
  ),
  setStepState(module),
)(find(component), ...args)
