/* global G */
import { isArr } from 'lib/util'

/**
 * Translates the value of an item.
 *
 * TODO: Refactor. Promises don't work inside decorators, which causes them to usually finish too
 *                 late.
 *
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} t            an object specifying the items' translation path
 * @param {string} key          the key unique to the language
 * @param {string} defaultValue the language's name in the default language
 * @returns {*}
 */
export const translateItemValue = (obj, t, { key, value: defaultValue }) => {
  const _key = `${t._key}.${key}`
  return t ? obj[G.ADAPTER][G.INTL]._t(_key, { ns: t.ns, _key, defaultValue }) : defaultValue
}

/**
 * From Collection Decorator
 *
 * @param {string} key              the name of the attribute to store the data to
 * @param {Object[]} list           an array of items each with a key and a value properties
 * @param {string|number} list.key  the unique identifier of a list's element
 * @param {string} list.value       the value to be set as the cache if its current value matches
 *                                  the item's key
 * @param {Object} [t]              an object specifying the items' translation path
 */
const fn = (key, list, t) => (obj, component) => {
  const source = obj[G.CHILDREN][key]
  const cache = source[G.CACHE]
  // if it already is an array, it means it has been already decorated
  cache
  && !isArr(cache)
  && (source[G.CACHE] = list.filter(entry => entry.key === cache).map(entry => ({
    ...entry,
    value: translateItemValue(obj, t, entry),
  })))

  return component
}

export default fn
