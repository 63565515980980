import noop from '@gaia/util/noop/action'
import tickets from './action/tickets'
import requests from './action/requests'
import users from './action/user'
import userEdit from './action/user/edit'
import userBulk from './action/user/bulk'
import userCreate from './action/user/create'
import teamEdit from './action/team/edit'
import teamCreate from './action/team/create'
import teamSubmit from './action/team/submit'
import checkDuplicate from './action/approve/checkDuplicate'
import organisationSubmit from './action/approve/submit/organisation'
import approveAsOriginal from './action/approve/original'
import approveAsDuplicate from './action/approve/duplicate'
import originalSubmit from './action/approve/submit/asOriginal'
import duplicateSubmit from './action/approve/submit/asDuplicate'
import blockSubmit from './action/approve/submit/block'
import approveBlock from './action/approve/block'
import approveDelete from './action/approve/delete'
import deleteSubmit from './action/approve/submit/delete'

export default {
  tickets,
  requests,

  // user actions
  users,
  usersNew: users,
  userEdit,
  userBulk,
  userCreate,
  invitations: noop,

  // team actions
  teams: noop,
  teamEdit,
  teamCreate,
  teamSubmit,

  // approve actions
  checkDuplicate,
  organisationSubmit,

  // approve original
  approveAsOriginal,
  originalSubmit,

  // approve duplicate
  approveAsDuplicate,
  duplicateSubmit,

  // approve block
  approveBlock,
  blockSubmit,

  // approveDelete
  approveDelete,
  deleteSubmit,
}
