/* global G, PLATFORM */
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import { get } from 'lib/sequence/component/state/value'
import { setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import validate from 'lib/sequence/model/validate'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { setSessionStateData } from 'app/guest/action/index'

/**
 * Redirects to the contact action of the support module.
 *
 * @param {Gaia.AppModule.Spec} module the current module object
 * @returns {(function(): Promise<void>)|*}
 */
const handler = module => async () => {
  const moduleState = module[G.STATE]
  const loginComponent = module[G.ACTIONS].index[G.COMPONENT]
  const { username } = find(loginComponent)

  moduleState[G.ERROR] = false
  moduleState[G.ROUTE] = routeComposition('support', 'contact')

  await redirectSequence(module)({ username: get(username) })
}

/**
 * Shows a dialog informing the user that the inserted account is inactive.
 *
 * @param {Gaia.AppModule.Spec} module the current module object
 */
const showInactiveUserDialog = (module) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  const okHandler = handler(module)

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: module[G.ADAPTER][G.INTL]._t(
      'dialog.accountInactive.title',
      {
        ns: 'guest',
        _key: 'dialog.accountInactive.title',
        defaultValue: 'Account Inactive',
      },
    ),
    text: module[G.ADAPTER][G.INTL]._t(
      'dialog.accountInactive.text',
      {
        ns: 'guest',
        _key: 'dialog.accountInactive.text',
        defaultValue: 'Your account is inactive. Please contact customer support.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.contactSupport',
          {
            ns: 'guest',
            _key: 'button.contactSupport',
            defaultValue: 'Contact support',
          },
        ),
      },
      cancel: {
        key: 'cancel',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.close',
          {
            ns: 'common',
            _key: 'button.close',
            defaultValue: 'Close',
          },
        ),
      },
    },
    okHandler,
  })
}

/**
 * Cancels a previously set redirection by nullifying it and setting the module's error state to
 * true.
 *
 * @param {Object} state  the current module's state
 * @returns {boolean}     always true
 * @private
 */
const _cancelRedirect = (state) => {
  const moduleState = state
  moduleState[G.ERROR] = true
  moduleState[G.ROUTE] = null
  return true
}

/**
 * Parses errors regarding the username field, so that proper messages are shown.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object
 * @param {...any} args                 additional arguments
 * @returns {any[]}                     the args parameter
 * @private
 */
const _onUserError = (module, args) => {
  const modelStateError = module[G.MODEL][G.STATE][G.ERROR]
  const userError = modelStateError[G.REF]?.username
  const { btnRegister, textRegister } = find(module[G.STATE][G.ACTION][G.COMPONENT])

  PLATFORM === 'web' && (userError === 404 && show(btnRegister) && hide(textRegister))
  userError === 403 && _cancelRedirect(module[G.STATE]) && showInactiveUserDialog(module)

  return args
}

/**
 * Bifurcates the application flow depending on whether the username or the password field are
 * erroneous / have an invalid value.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object
 * @param {...any} [args]               additional arguments
 * @returns {any[]}                     the args parameter
 * @private
 */
const _takeAction = (module, ...args) => {
  const error = module[G.MODEL][G.STATE][G.ERROR]

  switch (true) {
    case !!error.username:
      return _onUserError(module, ...args)
    case !!error.password:
      return args
    default:
  }
  return args
}

/**
 * validateRedirect Action.
 *
 * the action reacts specifically to model errors
 * based on the error, it will use router adapter toAction(currentAction)
 *
 * If the user has no password {@code passwordGenerated === true}, it will
 * redirect to {@code passwordAction} defined in config ({@code loginLink}).
 *
 * case 1: coming from login screen
 *  401 -> redirect to different action
 *  403 -> show create new account(btn 1)
 *  200 -> authenticationType = email -> btnResend visible
 *  200 -> authenticationType = pwd -> btnReset visible
 * case 2: coming from pwd screen
 *  tbd
 * @param {Gaia.AppModule.Spec} module                      the current module object
 * @return {function(): function(*, ...[*]=): Promise<*[]>}
 */
// eslint-disable-next-line no-unused-vars
const actionFn = module => () => async (route, ...args) => {
  const model = module[G.MODEL]
  const { form, password, textRegister, btnRegister } = find(module[G.STATE][G.ACTION][G.COMPONENT])
  const passwordHidden = password && hidden(password)

  btnRegister && hide(btnRegister)
  textRegister && show(textRegister)

  // Validating
  password && hide(password)
  form && await validate(model, form)
  password && !passwordHidden && show(password)

  const { passwordGenerated } = model[G.STATE]
  passwordGenerated && setSessionStateData(module, { passwordGenerated })

  const { action, passwordAction } = route
  const modelError = module[G.MODEL][G.STATE][G.ERROR]
  const moduleState = module[G.STATE]

  moduleState[G.ROUTE] = routeComposition(
    route.module && route.module,
    !passwordGenerated
      ? action
      : passwordAction,
  )

  /**
   * Once we've seen it, delete this property again,
   * so it doesn't interfere with future redirects
   * (because of logic above).
   */
  setKey(false, 'passwordGenerated', model[G.STATE])

  moduleState[G.ERROR] = !!modelError
  modelError && _takeAction(module, args)
  return args
}

export default actionFn
