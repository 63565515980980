/* global G */
import { curry } from 'lib/util'
import concat from 'model/_shared/decorator/concat'

/**
 * Concat If Empty Decorator
 *
 * Calls the {@link concat} decorator if the attribute defined by {@param ref} has no G.CACHE set.
 *
 * @param {string} ref          the name of the attribute to set the joined values to
 * @param {string[]} keys       the name of the attributes whose values have to be joined
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} component    the component that matched the attribute name during the mapping
 */
const fn = (ref, keys, obj, component) => {
  const attribute = obj[G.CHILDREN][ref]
  return attribute[G.CACHE] ? component : concat(ref, keys, obj, component)
}

export default curry(fn)
