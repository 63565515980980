/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Returns the translated label to be used to identify devices that are not confirmed.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Event} event                   information about the event that triggered this handler
 * @return {[{color: string, icon: string, value: *, key: string}]|[{}]}
 */
const getConfirmedStatus = (module, component, event) => {
  const model = module[G.MODEL]
  const { notConfirmed } = model[G.CHILDREN]
  const notConfirmedValue = notConfirmed[G.CACHE]

  return notConfirmedValue
    ? [{
      key: 'notConfirmed',
      icon: 'fiber_manual_record',
      color: 'warning',
      value: module[G.ADAPTER][G.INTL]._t(
        'label.notConfirmed',
        {
          ns: 'device',
          _key: 'label.notConfirmed',
          defaultValue: 'Not confirmed',
        },
      ),
    }] : [{}]
}

export default curry(getConfirmedStatus)
