/* global G */
import { curry, setKey } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import claimBody from './claim'
import forwardBody from './forward'
import stopBody from './stop'
import postponeBody from './postpone'
import closeBody from './close'
import reopenBody from './reopen'
import resetStatusReasonBody from './reset'

/**
 * @typedef QuickPayload
 * @description Returns the body to be used for a specific quick action's request.
 * @type function
 * @param {Object} module     the ticket module composition object
 * @param {Object} component  the clicked component
 * @param {Object} event      the triggered event
 * @returns {Object}
 */

/**
 * Uses the payload function to obtain the body of a quick action call and sends it to the module
 * model's PATCH endpoint.
 *
 * TODO: Refactor quick/ event handlers for them to be cleaner and easier to understand.
 *
 * @param {QuickPayload} payload  the specific quick action's function
 * @param {boolean} [modal=true]  whether the quick action is displayed as a modal
 * @returns {(function(*=, *, *): Promise<void>)|*}
 */
const patch = payload => curry(async (module, component, event) => {
  try {
    const model = module[G.MODEL]
    const action = module[G.STATE][G.ACTION]
    const { modal } = action[G.UI]
    const actionComponent = action[G.COMPONENT]
    const { api, version } = model[G.PROPS]
    const { key: id, _rev } = model[G.CACHE]
    const url = `/api/v${version}/${api}/${id}`
    const params = await payload(module, actionComponent, event)

    params._rev = _rev

    // Set a temporary property to declare that we did this change
    model[G.STATE][G.UPDATE] = true

    await model[G.ADAPTER][G.HTTP].patch({ url, params })
    // forcing read call
    model[G.CACHE] = null
    // clearing dirty state
    setKey(null, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
    // calling back only if the current action is displayed as a modal
    modal && await module[G.ADAPTER][G.ROUTER].back()
    await actionWithHooks(module[G.ACTIONS].detail)({})
  } catch (e) {
    await module[G.ADAPTER][G.UI].update(module)
    console.error(e)
  }
})

const claim = patch(claimBody)
const forward = patch(forwardBody)
const stop = patch(stopBody)
const postpone = patch(postponeBody)
const close = patch(closeBody)
const reopen = patch(reopenBody)
const resetStatusReason = patch(resetStatusReasonBody)

const quick = {
  claim,
  forward,
  stop,
  postpone,
  close,
  reopen,
  resetStatusReason,
}

export {
  quick as default,
  claim,
  forward,
  stop,
  postpone,
  close,
  reopen,
  resetStatusReason,
}
