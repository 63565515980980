/* global G */
/* eslint-disable no-unused-vars */
import { set } from 'lib/sequence/component/state/value'

/**
 * Displays the name of the organisation
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetOrganisationName = module => async (children, ...args) => {
  const { organisationDescription } = children
  const { organisation = {} } = module[G.MODEL][G.CHILDREN] || {}
  const organisationName = organisation?.[G.CACHE]?.value?.name

  organisationName && set(organisationDescription, organisationName)

  return [children, ...args]
}

export default presetOrganisationName
