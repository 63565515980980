/* global G */
import { curry, isArr } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { persistOptions } from 'app/_shared/events'
import { empty } from 'lib/util/object'

/**
 * Set User Organisation If Not Set Event Handler
 *
 * Sets the `organisation` field to the value of the incoming organisation
 * if it's not already set.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 */
const setUserOrganisationIfNotSet = (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { organisation } = sequenceComponentFindProxy(actionComponent)

  const currentUserOrg = get(organisation)

  if (!currentUserOrg || (isArr(currentUserOrg) && empty(currentUserOrg))) {
    persistOptions(module, organisation, event)
  }
}

export default curry(setUserOrganisationIfNotSet)
