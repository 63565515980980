/* eslint-disable no-unused-vars,arrow-body-style */
/* global G */
import { hide, show } from 'lib/sequence/component/state/hidden'
import find from 'lib/sequence/component/children/find'
import { asyncPipeSpread } from 'lib/util'
import session from 'app/_shared/session'
import sequenceComponentState from 'lib/sequence/component/state'

const {
  get: getDecorators,
  set: setDecorators,
} = sequenceComponentState('decorators')

/**
 * Merges {@param decorators} with the ones already found in the {@param component}'s state.
 *
 * @param {Gaia.Component} component  a component accepting a 'decorators' state
 * @param {Object} decorators         decorators to merge with the ones of the {@param component}
 */
const decorate = (component, decorators) => {
  const current = getDecorators(component)
  setDecorators(component, { ...current, ...decorators })
}

/**
 * Displays or hides the edit buttons depending on the current ticket's status and on whether the
 * current user is the ticket's assignee.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const displayEditButtons = module => component => async (components, ...args) => {
  const model = module[G.MODEL]
  const { status, assignee } = model[G.CHILDREN]
  const { editItem } = components

  status[G.CACHE]
  && (status[G.CACHE]?.[0]?.key === 50 || status[G.CACHE] === 50)
  && assignee[G.CACHE]
  && assignee[G.CACHE][0].key === session(module).user.key()
    ? show(editItem)
    : hide(editItem)

  return [components, ...args]
}

/**
 * Displays or hides information about the requester depending on some of their state values.
 *
 * @param module
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const displayInformationLabels = module => component => async (components, ...args) => {
  const model = module[G.MODEL]
  const { item, equipment, itemInstalledAt } = model[G.CHILDREN]
  const { requesterOrg, requesterContactOrg, additionalParty } = model[G.CHILDREN]
  const organisations = [requesterOrg, requesterContactOrg, additionalParty]
  const { installedAtLabel, labels } = components
  const { noEquipment, notRelated, notInMasterData } = find(labels)

  const itemRef = item[G.STATE][G.REF]
  const equipmentRef = equipment[G.STATE][G.REF]
  const itemInstalledAtRef = itemInstalledAt[G.STATE][G.REF]

  const installedAtRequester = itemInstalledAtRef && organisations.some((organisation) => {
    return organisation[G.STATE][G.REF] === itemInstalledAtRef
  })

  !itemRef && !equipmentRef
    ? show(notInMasterData)
    : hide(notInMasterData)

  itemRef && !equipmentRef
    ? show(noEquipment)
    : hide(noEquipment)

  !itemRef && equipmentRef
    ? show(notRelated)
    : hide(notRelated)

  itemInstalledAtRef
    ? show(installedAtLabel) && decorate(installedAtLabel, {
      error: !installedAtRequester,
    })
    : hide(installedAtLabel)

  return [components, ...args]
}

export default module => component => async (...args) => asyncPipeSpread(
  displayInformationLabels(module)(component),
  displayEditButtons(module)(component),
)(find(component), ...args)
