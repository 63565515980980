import { curry } from 'lib/util'
import addNote from 'app/ticket/event/note/add'
import updateNote from 'app/ticket/event/note/update'

/**
 * Add Or Edit Note Event Handler
 *
 * Executes the proper event handler based on whether we want to add
 * or update a note.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @returns {Promise<void>}
 */
const addOrEdit = async (module, component, event) => {
  const { key = null } = event?.detail || event || {}

  !key
    ? await addNote(module, component, event)
    : await updateNote(module, component, event)
}

export default curry(addOrEdit)
