/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import { asyncPipeSpread, getFirstItem } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import search from 'app/_shared/events/search'
import deleteSubItems from 'app/admin/action/approve/partials/submit/deleteSubItems'
import checkModelErrorAndUpdateState from 'app/admin/action/approve/partials/submit/checkModelErrorAndUpdateState'

/**
 * List of document types that should be ignored when bulk deleting
 * @type {string[]}
 */
const ignoredDeleteDocTypes = []

/**
 * Composes the user to be updated. Will update its status and role.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, *, ...[*]): Promise<[{payload: *[]},undefined,...*[]]>}
 */
const deleteUser = module => async (components, ...args) => {
  const { [G.HTTP]: httpAdapter } = module[G.MODEL][G.ADAPTER]

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const moduleState = module[G.STATE]

  const { organisation, user } = module[G.MODEL][G.CHILDREN]
  const { options: { api, version } } = user[G.CONFIGURATION]
  const { [G.STATE]: { [G.REF]: orgRef = null } = {} } = organisation
  const orgId = orgRef?.replaceAll(':', '\\:')

  if (!moduleState[G.ERROR]) {
    try {
      const result = await search(null, module, actionComponent, { detail: {
        query: `metatype:user AND organisation:${orgId}`,
      } })

      const fetchedUser = getFirstItem(result)
      const url = `/api/v${version}/${api}/${fetchedUser.key}`

      await httpAdapter.delete({ url })
    } catch (e) {
      console.error(e)
    }
  }

  return [components, ...args]
}

/**
 * Deletes the main organisation
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, *, ...[*]): Promise<[{payload: *[]},undefined,...*[]]>}
 */
const deleteOrganisation = module => async (components, ...args) => {
  const { [G.HTTP]: httpAdapter } = module[G.MODEL][G.ADAPTER]

  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { options: { version, api } } = organisation[G.CONFIGURATION]
  const { [G.STATE]: { [G.REF]: orgRef = null } = {} } = organisation

  const url = `/api/v${version}/${api}/${orgRef}`

  if (orgRef) {
    try {
      await httpAdapter.delete({ url })
    } catch (e) {
      console.error(e)
    }
  }

  return args
}

/**
 * Admin Action Submit Block
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  checkModelErrorAndUpdateState('ticket')(module),
  deleteSubItems(module)([]),
  deleteUser(module),
  deleteOrganisation(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
