import { curry, PlatformEvent } from 'lib/util'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'

/**
 * Search And Recreate With Event Handler
 * Executes {@link searchAndRecreate} for the given {@param key} list.
 *
 * @param {string} key                    key of the list to update
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @returns {Promise<void>}
 */
const searchAndRecreateWithListKey = async (key, module, component, event) => {
  const searchEvent = new PlatformEvent(event, {
    ...event.detail, listKey: key,
  })

  await searchAndRecreate(module, component, searchEvent)
}

export default curry(searchAndRecreateWithListKey)
