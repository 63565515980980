/* eslint-disable no-param-reassign,no-unused-vars */
/* global G */
import ui from 'lib/hook/ui/update'
import search from 'app/_shared/hook/appbar/search'
import create from 'app/_shared/hook/appbar/create'
import clear from 'app/_shared/hook/model/clear'
import reset from 'app/_shared/hook/component/reset'
import read from 'lib/hook/model/read'
import confirm from 'lib/hook/router/confirm'
import check from 'app/_shared/hook/check'
import bulk from 'lib/hook/model/bulk'
import modelReset from 'lib/hook/model/reset'
import validate from 'lib/hook/model/validate'
import submit from 'lib/hook/model/submit'
import { end } from 'lib/hook/transaction'
import back from 'app/_shared/hook/redirect/back'
import recall from 'app/_shared/hook/component/recall'
import updateCache from 'app/_shared/hook/model/updateCache'
import setAttribute from 'lib/hook/model/setAttribute'
import submitAttribute from 'lib/hook/model/submitAttribute'
import validateAttribute from 'lib/hook/model/validateAttribute'
import confirmation from 'lib/hook/transaction/confirmation'
import { currentAction } from 'lib/hook/module/action'
import deleteOrReplaceLastHistoryEntry from 'app/_shared/hook/action/deleteOrReplaceLastHistoryEntry'
import changeRefOfLastHistoryEntry from 'app/_shared/hook/action/changeRefOfLastHistoryEntry'

const _ticketListHooks = {
  before: [
    search,
    recall,
  ],
  after: [
    ui,
  ],
}

const _listHooks = {
  before: [
    search,
    create,
    recall,
  ],
  after: [
    ui,
  ],
}

const _invitationHooks = {
  before: [],
  after: [
    search,
    ui,
  ],
}

const _editHooks = {
  before: [
    clear,
    reset,
    read,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _bulkHooks = {
  after: [
    ui,
    check,
    bulk,
    back,
  ],
}

const _createHooks = {
  before: [
    modelReset,
    reset,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _submitHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    check,
    submit,
    updateCache,
    // confirmation('success'),
    back,
    end,
  ],
}

const _teamSubmitHooks = {
  before: [],
  after: [
    ui,
    check,
    submit,
    updateCache,
    // confirmation('success'),
    back,
    end,
  ],
}

const _checkDuplicateHooks = {
  before: [
    setAttribute('organisation'),
  ],
  after: [
    ui,
  ],
}

const _approveHooks = {
  before: [
    setAttribute('organisation'),
  ],
  after: [
    ui,
  ],
}

const _approveBlockHooks = {
  before: [
    setAttribute('organisation'),
  ],
  after: [
    ui,
    check,
  ],
}

const _submitOrganisationHooks = {
  before: [
    validateAttribute('organisation'),
  ],
  after: [
    ui,
    check,
    // TODO: Make it so the server returns the document here
    //  So we don't have to refetch it in setAttribute hook
    submitAttribute('organisation'),
    currentAction,
    end,
  ],
}

const _submitApproveOriginalHooks = {
  before: [
    validateAttribute('organisation'),
  ],
  after: [
    ui,
    check,
    submitAttribute('organisation'),
    updateCache,
    confirmation('successApproveOriginal', 'success'),
    back,
    end,
  ],
}

const _submitApproveDuplicateHooks = {
  before: [],
  after: [
    ui,
    check,
    // submission takes place in action logic
    updateCache,
    confirmation('successApproveDuplicate', 'success'),
    changeRefOfLastHistoryEntry,
    back,
    end,
  ],
}

const _submitBlockHooks = {
  before: [
    // validation and submit takes place in action logic
  ],
  after: [
    ui,
    check,
    confirmation('successApproveBlock', 'success'),
    deleteOrReplaceLastHistoryEntry('organisation', 'index'),
    back,
  ],
}

const _submitDeleteHooks = {
  before: [],
  after: [
    ui,
    check,
    confirmation('successApproveDelete', 'success'),
    deleteOrReplaceLastHistoryEntry('organisation', 'index'),
    back,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    tickets: _ticketListHooks,
    requests: _ticketListHooks,

    // user actions
    users: _listHooks,
    usersNew: _listHooks,
    userEdit: _editHooks,
    userBulk: _bulkHooks,
    userCreate: _createHooks,
    invitations: _invitationHooks,

    // team actions
    teams: _listHooks,
    teamEdit: _editHooks,
    teamCreate: _createHooks,
    teamSubmit: _teamSubmitHooks,

    // approve actions
    checkDuplicate: _checkDuplicateHooks,
    organisationSubmit: _submitOrganisationHooks,

    approveAsOriginal: _approveHooks,
    originalSubmit: _submitApproveOriginalHooks,

    approveAsDuplicate: _approveHooks,
    duplicateSubmit: _submitApproveDuplicateHooks,

    approveBlock: _approveBlockHooks,
    blockSubmit: _submitBlockHooks,

    approveDelete: _approveBlockHooks,
    deleteSubmit: _submitDeleteHooks,
  },
}

export default hooks
