/* global G */
import { curry } from 'lib/util'

/**
 * Count event handler using FTS
 *
 * @param {string} key - listing type, ie short, verbose, etc
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 *
 * @param {Event|object} [eventOrOptions]       information about the event that triggered this
 *                                              handler or a search term
 * @param {Object} [eventOrOptions.detail]      information about the target of the event
 * @return {Promise<{value: string, key: *}[]>} void
 */
// eslint-disable-next-line no-unused-vars
const countFn = async (key, module, component, eventOrOptions) => {
  try {
    const options = (eventOrOptions && eventOrOptions.detail) || eventOrOptions || {}
    const { term: eventTerm, type: eventType, filter: eventFilter, query: eventQuery } = options
    const { version, api } = module[G.MODEL][G.PROPS]
    const { api: { type = eventType } = {} } = component[G.PROPS]
    const { term = eventTerm, filter, transform, query } = component[G.STATE][G.META] || {}

    const filters = filter && Object.keys(filter).reduce((acc, name) => {
      const hasValues = !(filter[name]?.length === 0)
      if (hasValues) {
        acc[name] = filter[name]
      } else if (!hasValues) {
        acc.refexists = `!${name}`
      }
      return acc
    }, {})

    const params = {
      transform: transform || {
        key: key || 'default',
      },
      query: {
        term,
        type: type || api, // document type. Prioritizing what is defined in component.
      },
      filter: {
        ...eventFilter,
        ...filters,
      },
      lucene: query || eventQuery,
    }

    const url = `/api/v${version}/count/search`
    try {
      const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]

      return await remoteAdapter.post({ url, params })
    } catch {
      console.error('search:: tried and failed getting remote resources', url)
    }
  } catch (e) {
    console.error(e)
  }
  return [
    { key: -1, value: 'Not Found' },
  ]
}

export default curry(countFn)
