/* global G */
import { curry } from 'lib/util'

/**
 * Message Edit Event Handler
 *
 * Edits a single message and instructs the message adapter to update it.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        information about the event that triggered this handler
 * @returns {Promise<void>}
 */
const edit = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const eventBus = module[G.ADAPTER][G.EVENTS]
  const { data, callback, hasAttachments = false } = event.detail
  const { key, text } = data

  eventBus.dispatch(
    eventBus.type(G.MESSAGE, G.UPDATE),
    {
      [G.DATA]: {
        key,
        value: {
          text,
        },
      },
      [G.FN]: callback,
      [G.ATTACHMENT]: hasAttachments,
    },
  )
}

export default curry(edit)
