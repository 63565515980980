/* eslint-disable import/prefer-default-export */
/* global G */
import appInit from '@gaia/sequence/app/init'
import { isPlatformError } from 'lib/util/error'
import bootstrappedPlatform from './bootstrap'

const app = bootstrappedPlatform()

/**
 * Native Platform Web Initialisation.
 *
 * Entry point for web client.
 * It uses bootstrapped native platform, starts the application flow, renders results in DOM.
 *
 * Once the DOM result has been rendered, the application provides the follow-up activity based
 * on presented context.
 *
 * Current Flow is for demonstration purposes only.
 *
 * @return {Promise<void>}
 */
appInit(app)()
  .then((...args) => {
    app[G.EVENTS].dispatchEvent(new CustomEvent('gaia:init'))
    return args
  })
  .catch(
    async (e) => {
      if (!isPlatformError(e)) {
        await app[G.ADAPTER][G.ROUTER][G.API].error(e, () => {
          console.error('%c Web Application Fatal:', 'color:white', e.message)
          console.groupCollapsed('%c error details', 'color:red')
          console.log(e)
          console.groupEnd()
        })
      }
    },
  )
