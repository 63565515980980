/* eslint-disable no-unused-vars */
/* global G */
import { curry, isArr, isObj, isStr } from 'lib/util'
import roles, { reducedRoles } from 'model/account/collection/roles'

/**
 * A list of key/value pairs that defines a mapping between a key used in the client (key)
 * and the key that needs to be sent to the server (value).
 *
 * Example: We have a role with the key `Customers`, and we want to use a translation with
 * the key `Customers` for it (and not the translation with the key `Customer`). But we want
 * to send the key `Customer` to the server whenever we use that role in a request.
 *
 * @type {{(userRole.key): userRole.key}}
 */
const overlappingKeysMapping = {
  Customers: 'Customer',
}

/**
 * Helper function to map keys using {@link overlappingKeysMapping}.
 *
 * @param {userRole.key} key the client-defined key of the role.
 * @returns {string}
 * @private
 */
const _mapKey = key => (Object.keys(overlappingKeysMapping).includes(key)
  ? overlappingKeysMapping[key]
  : key)

/**
 * Get a list of reduced {@link userRole}s.
 *
 * @param {Gaia.AppModule.Spec} module              app module
 * @param {Gaia.Component.Spec} component           action component
 * @param {Gaia.PlatformEvent|string} eventOrKey    event that triggered this handler
 * @return {(userRole&{value: string})[]} user roles
 */
const listReducedUserRoles = (module, component, eventOrKey) => reducedRoles.map(item => ({
  ...item,
  key: _mapKey(item.key),
  value: module[G.ADAPTER][G.INTL]._t(
    `roles.${item.key}`,
    {
      ns: 'custom',
      _key: `roles.${item.key}`,
      defaultValue: item.value,
    },
  ),
}))

export default curry(listReducedUserRoles)
