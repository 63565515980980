/* eslint-disable no-unused-vars */
/* global G */
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { asyncPipeSpread, setKey } from 'lib/util'
import { show, hide } from 'lib/sequence/component/state/hidden'

/**
 * Set Document Key
 *
 * Sets the `key` of the document we couldn't find if it's present.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setDocumentKey = module => async (children, ...args) => {
  const { key = null } = args[0]
  const actionState = module[G.STATE][G.ACTION][G.STATE]

  // Key of the document we couldn't find. We'll need it in `goBackOrHome` handler
  setKey(key, 'key', actionState)

  return [children, ...args]
}

/**
 * Display Error Type
 *
 * Chooses the correct components to show based on `error`.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayErrorType = module => async (children, ...args) => {
  const { error } = args[0]

  const { generic, notFound, noPermission, noConnection } = children

  hide(generic)

  switch (error?.code) {
    case 404: show(notFound)
      break
    case 403: show(noPermission)
      break
    case 'FETCH_FAILED': show(noConnection)
      break
    default: show(generic)
      break
  }

  return args
}

/**
 *
 * Error Index Action
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  setDocumentKey(module),
  displayErrorType(module),
)(sequenceComponentFind(component), ...args)
