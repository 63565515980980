/* global G */
import { curry } from 'lib/util'

/**
 * From Address Decorator
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} component    the component that matched the attribute name during the mapping
 */
const fn = (key, obj, component) => {
  const attribute = obj[G.CHILDREN][key]
  const childKeys = Object.keys(attribute[G.CHILDREN])
  const cache = attribute[G.CACHE]
  // taking cache from [{ value }] in case the cache was set by seqModelCreate
  attribute[G.CACHE] = cache && cache[0] ? cache[0].value : cache

  attribute[G.CACHE] && childKeys.map((childKey) => {
    const child = attribute[G.CHILDREN][childKey]
    child[G.CACHE] = attribute[G.CACHE][childKey]
    return childKey
  })

  return component
}

export default curry(fn)
