import { isArr } from 'lib/util'
import { get, set } from 'lib/sequence/component/state/value'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'

/**
 * Presets the {@code role} field with the {@code Customer} role
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetRoleField = module => component => async (children, ...args) => {
  const { role } = children
  const customerRole = listUserRoles(module, component, 'Customer')

  const roleValue = get(role)
  const hasValue = roleValue && isArr(roleValue) && roleValue.length

  !hasValue && set(role, customerRole)

  return [children, ...args]
}

export default presetRoleField
