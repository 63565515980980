/* global G */
import { curry, getFirstItem } from 'lib/util'
import search from 'app/_shared/events/search'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'

/**
 * List Organisations And Sort Event Handler
 *
 * Lists all available organisations and sorts the user's one (if available)
 * to the top.
 *
 * @param {Gaia.AppModule.Spec} module    app module
 * @param {Gaia.Component.Spec} component action component
 * @param {Gaia.PlatformEvent} event      event
 * @returns {Promise<*|(function(...[*]): (*))>}
 */
const listOrganisationsAndSort = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { organisation } = sequenceComponentFindProxy(actionComponent)
  const userOrganisation = getFirstItem(get(organisation))
  const userOrganisationKey = userOrganisation?.key

  const result = await search(
    null, module, component, { ...event?.detail?.term && { term: event.detail.term } },
  )

  const fetchedUserOrgIndex = result.findIndex(item => item.key === userOrganisationKey)

  if (fetchedUserOrgIndex > -1) {
    const fetchedUserOrg = result[fetchedUserOrgIndex]
    result.splice(fetchedUserOrgIndex, 1)
    result.unshift(fetchedUserOrg)
  }

  return result
}

export default curry(listOrganisationsAndSort)
