/* global G */
import { curry } from 'lib/util'
import session from 'app/_shared/session'
import getUserRole from 'app/_shared/events/user/role'
import setStepTab, { SEARCH, SELECT } from 'app/_shared/events/setStepTab'
import excludeNotConfirmedForRoles
  from 'app/_shared/events/search/query/excludeNotConfirmedForRoles'
import find from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import { hide } from 'lib/sequence/component/state/hidden'

/**
 * Hides the back button found at the search step.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 */
const hideSearchStepBackButton = (module) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const { device } = asObject(stepper[G.CHILDREN])
  const { search } = asObject(device[G.CHILDREN])
  const { btnBack } = find(search)
  hide(btnBack)
}

/**
 * Perform a device search based on the user's role and save it in the action component's state.
 *
 * @param {Function} searchFn
 * @param {boolean} [changeStep]
 * @type {(function(...[*]): (*))|*}
 */
const searchWithRequestCount = (searchFn, changeStep) => curry(async (module, component, event) => {
  const { user, organisation, person } = session(module)
  const userRole = getUserRole(user.ref()?.value?.roles, module, component, null)?.key

  const options = userRole === 'FieldServiceEngineer'
    ? { filter: { maintainedBy: person.key({ wrap: true })[0] } }
    : {
      filter: { installedAt: organisation.key({ wrap: true }[0]) },
      query: await excludeNotConfirmedForRoles(module, component, event),
    }

  const result = await searchFn('defaultWithRequestCount', module, component, { ...event.detail, ...options })
  const results = result?.length || 0

  if (changeStep) {
    await setStepTab(results ? SELECT : SEARCH, 'device')(module, component, event)
    !results && hideSearchStepBackButton(module)
  }

  return result
})

export default searchWithRequestCount
