/* global G */
import { curry } from 'lib/util'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'

/**
 * Edit Message Event Handler
 *
 * Redirect to the edit action using the component level-defined route.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @returns {Promise<void>}
 */
const editMessage = async (module, component, event) => {
  try {
    const { detail } = event
    const moduleState = module[G.STATE]
    const { editRoute } = component[G.CONFIGURATION]
    moduleState[G.ROUTE] = routeComposition(editRoute.module, editRoute.action)
    await redirectSequence(module)({ detail })
  } catch (e) {
    console.error(e)
  }
}

export default curry(editMessage)
