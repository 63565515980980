/* global G */
import { curry } from 'lib/util'
import session from 'app/_shared/session'
import setMessagesAsRead from '@app/_shared/events/message/setAsRead'

/**
 * Mark the ticket's messages as read.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 */
const checkAssigneeAndSetAsRead = (module, component, event) => {
  const user = session(module).user.key()
  const model = module[G.MODEL]
  const ticket = model[G.CACHE]
  const userIsAssignee = !!(ticket?.refs?.assignee?.length && ticket?.refs?.assignee?.[0]?.key === user)

  if (userIsAssignee && ticket.value.status === 50) {
    setMessagesAsRead(module, component, event)
  }
}

export default curry(checkAssigneeAndSetAsRead)
