/* global G */

import { getFirstItem } from 'lib/util'

/**
 *
 * Model Confirmation Validator
 *
 * validates data values on model level, propagating
 * to the same scope as children validators
 *
 * @param {Object} obj  the model object composition
 * @return {Object}     the call's arguments
 */
export default obj => async (uiState, key) => {
  const { value } = uiState
  const { options } = obj[G.CONFIGURATION].validator[key]
  const { target, error } = options

  // Getting the cache of the target (supportedBy or parent) and use it as a fallback
  const targetCache = getFirstItem(obj[G.CHILDREN][target]?.[G.CACHE]) || null

  const { [target]: targetFieldValue } = obj[G.DATA]

  if (!(value && value.length) && !((targetFieldValue && targetFieldValue.length) || targetCache)) {
    throw TypeError(error)
  }
  return [uiState, key]
}
