/* eslint-disable no-unused-vars */
/* global G */
import { hasInternalParent } from 'app/organisation/action/detail'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import { curry } from 'lib/util'

/**
 * Parent Dialog
 *
 * Checks if the current organisations parent or grandparent is {@code ORGANISATION:RA} and shows
 * a confirmation dialog if so, returning its result. Otherwise returns {@code true}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<*>}
 */
const parentDialog = async (module, component, event) => {
  const model = module[G.MODEL]

  // Org's parent or grandparent is ORGANISATION:RA
  if (!hasInternalParent(model)) {
    return await showBlockingDialog(module, component, {
      title: {
        ns: 'organisation',
        key: 'dialog.parentChanged.title',
        defaultValue: 'Confirm hierarchy change',
      },
      text: {
        ns: 'organisation',
        key: 'dialog.parentChanged.text',
        defaultValue: 'Changing the parent will affect read and update permissions that flow from top to bottom. Do you really want to change the parent?',
      },
    })
  }

  return true
}

export default curry(parentDialog)
