/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import { setKey, curry } from 'lib/util'

/**
 *
 * @param {Object} obj
 * @returns {Object}
 */
const resetItemState = (obj) => {
  const objState = obj[G.STATE]
  setKey(null, 'error', objState)
  setKey(true, 'success', objState)
  return obj
}

/**
 *
 * @param {Object} obj
 * @returns {Object}
 */
const setItemState = (obj) => {
  const objState = obj[G.STATE]
  setKey(true, 'error', objState)
  setKey(false, 'success', objState)
  return obj
}

/**
 *
 * @param {Object} obj
 * @param {Object} component
 * @returns {*}
 */
const passwordRequirementsDecorator = (obj, component) => {
  const errors = obj[G.STATE][G.ERROR] && obj[G.STATE][G.ERROR].requirements

  const componentChildren = asObject(component[G.CHILDREN])

  Object.keys(componentChildren).reduce((acc, name) => {
    const item = componentChildren[name]
    resetItemState(item)
    errors && errors[name] && setItemState(item)
    return acc
  }, componentChildren)

  return component
}

export default curry(passwordRequirementsDecorator)
