/* global G */
import { curry, getFirstItem } from 'lib/util'
import infinite from 'app/_shared/events/search/infinite'
import noInternalOrgsAndNotMyself from 'app/_shared/events/search/query/noInternalOrgsAndNotMyself'
import isCandidate from 'app/admin/event/search/isCandidate'
import internalOrganisations from 'model/organisation/collection/internal'

/**
 * Infinite Candidate Event Handler
 *
 * If {@link searchField} has a value, it will execute {@link infinite}
 * with that search term.
 *
 * If it doesn't, it will execute {@link infinite} with the given {@code query}.
 * It will then map over the result, extract the organisations from the contained
 * service items and segment, sort and remove duplicate from the data.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<*|(function(...[*]): (*))|{data: *}|*[]>}
 */
const infiniteCandidates = async (module, component, event) => {
  const { organisation = {} } = module[G.MODEL][G.CHILDREN]
  const orgId = organisation?.[G.STATE]?.[G.REF] || null

  const { term = null } = component[G.STATE]?.[G.META] || {}
  const hasTerm = term && !!term.length

  try {
    // Apply different query to the search based on search term
    const query = hasTerm
      ? noInternalOrgsAndNotMyself('organisation', module, component, event)
      : isCandidate(module, component, event)

    const result = await infinite('verbose', module, component, {
      ...event, detail: { ...event.detail, query },
    })

    // Then, map over the data, it will contain organisations and service items, but we only
    // want organisation. So use {@code installedAt} from each service item
    const aggregatedData = result.data.map((item) => {
      const installedAt = getFirstItem(item.refs?.installedAt) || null

      if (installedAt) {
        return { ...installedAt, value: { ...installedAt.value, docType: 'machine' } }
      }

      return {
        ...item,
        value: {
          ...item.value,
          docType: 'address',
        },
      }
      // Removing duplicates
    }).filter((item, i, arr) => arr.findIndex(obj2 => obj2.key === item.key) === i)
    // Removing our org
      .filter(x => x.key !== orgId)
    // Filtering our internal orgs
      .filter((x) => {
        const parents = getFirstItem(x.trees?.parent) || []
        return parents.filter(y => internalOrganisations.includes(y))?.length === 0
      })
    // sorting
      .sort((a, b) => (a.value.docType === 'machine' && b.value.docType === 'address'
        ? -1
        : 1))

    return {
      page: result.page,
      data: aggregatedData,
    }
  } catch (e) {
    console.error(e)
  }

  return []
}

export default curry(infiniteCandidates)
