/* global G */
import { curry } from 'lib/util'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import { remove } from 'app/_shared/events/message/index'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { purge } from 'app/_shared/events/attachment'

/**
 * Delete Message Event Handler
 *
 * Deletes a message by first deleting all of its attachments and finally instructing
 * the message adapter to delete the message itself. The message itself won't be deleted
 * itself, but rather its text and the status will be set to `90`.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      event that triggered this handler
 * @returns {Promise<void>}
 */
const deleteMessage = async (module, component, event) => {
  const confirm = await showBlockingDialog(module, component, {
    title: {
      ns: 'ticket',
      key: 'dialog.deleteMessage.title',
      defaultValue: 'Delete message',
    },
    text: {
      ns: 'ticket',
      key: 'dialog.deleteMessage.text',
      defaultValue: 'Are you sure that you want to delete the message?',
    },
  })

  if (confirm) {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { list } = sequenceComponentFindProxy(actionComponent)

    const message = event.detail
    const messageAttachments = message?.value?.attachments || []

    const { options: { group = null } = {} } = list[G.CONFIGURATION]

    for (let i = 0; i < messageAttachments.length; i++) {
      const { key = null, id = null } = messageAttachments[i]
      const attachment = {
        ...messageAttachments[i],
        // 'key' may still be the local attachment name, but we set 'id'
        // to the correct remote id when we activated the message, so we
        // need to check for both, id should take prescedence.
        key: id || key,
      }

      attachment?.key && purge(module, component, group, attachment, event)
    }

    await remove(module, component, event)
    await module[G.ADAPTER][G.UI].update(module)
  }
}

export default curry(deleteMessage)
