/* eslint-disable object-curly-newline */
/* global G */
import asObject from 'lib/sequence/component/children/asObject'
import validate from 'lib/sequence/model/validate'
import { hidden } from 'lib/sequence/component/state/hidden'
import validateStepper from 'app/_shared/component/stepper/validate'
import session from 'app/_shared/session'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { checked } from 'lib/sequence/component/state/checked'

/**
 * Sets type, parent and status of the location.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 */
const composeLocation = (module) => {
  const model = module[G.MODEL]
  const { itemInstalledAt } = model[G.CHILDREN]
  const { parent: itemInstalledAtParent } = itemInstalledAt[G.CHILDREN]
  const { organisation } = session(module)
  const userOrganisationKey = organisation.key({ wrap: true })
  const parentKey = itemInstalledAtParent[G.CACHE] || userOrganisationKey
  const organisationType = itemInstalledAt[G.CHILDREN].type[G.DATA].value
  if (itemInstalledAt[G.STATE][G.BULK]) {
    itemInstalledAt[G.DATA].status = 50
    itemInstalledAt[G.DATA].parent = parentKey
    itemInstalledAtParent[G.DATA].value = parentKey
    itemInstalledAt[G.CHILDREN].type[G.DATA].value = organisationType || 'customer'
  }
}

/**
 * Ends the creation of a request object by assigning the required references from its other inner
 * objects and setting its default status in case none was previously selected.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {Promise<void>}
 */
const composeRequest = (module) => {
  const model = module[G.MODEL]
  const { item, status, submitter } = model[G.CHILDREN]
  const { requesterContact, requesterContactOrg, itemServiceBy } = model[G.CHILDREN]
  const { serviceBy } = item[G.CHILDREN]
  const { user, person, organisation } = session(module)

  !model[G.DATA].status
   && (status[G.DATA].value = 10)

  const options = { wrap: true }
  submitter[G.STATE][G.REF] = user.key()
  submitter[G.DATA].value = user.ref(options)
  requesterContact[G.STATE][G.REF] = person.key()
  requesterContact[G.DATA].value = person.ref(options)
  requesterContactOrg[G.STATE][G.REF] = organisation.key()
  requesterContactOrg[G.DATA].value = organisation.ref(options)

  if (serviceBy[G.CACHE]) {
    itemServiceBy[G.STATE][G.REF] = serviceBy[G.STATE][G.REF]
    itemServiceBy[G.DATA].value = serviceBy[G.CACHE]
  }
}

/**
 * Validates all the stepper (except for the sub-models) with the ticket model. Validate
 * means also that all data will be saved inside the model, in case it is valid.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {Promise<boolean>}          {@code true} if all data is valid or {@code false} otherwise
 */
const validateRequest = async (module) => {
  const model = module[G.MODEL]
  const { description } = model[G.CHILDREN]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { stepper } = asObject(actionComponent[G.CHILDREN])
  const steps = asObject(stepper[G.CHILDREN])
  const {
    description: descriptionSection,
    softwareInfo,
    fieldServiceInfo,
  } = asObject(steps.issue[G.CHILDREN])

  await validate(model)(steps.type)
  await validate(description)(descriptionSection)
  softwareInfo && !hidden(softwareInfo) && await validate(description)(softwareInfo)
  fieldServiceInfo && !hidden(fieldServiceInfo) && await validate(description)(fieldServiceInfo)
  return !model[G.STATE][G.ERROR] && !description[G.STATE][G.ERROR]
}

/**
 * Request wizard submit
 *
 * Checks whether the currently inserted request data is valid and all required steps are completed.
 * If that's the case, finishes filling the request with data that can be deduced.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const submitWizard = async (module) => {
  const model = module[G.MODEL]
  const { item, itemInstalledAt } = model[G.CHILDREN]
  const { installedAt, maintainedBy } = item[G.CHILDREN]
  const moduleState = module[G.STATE]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { addToMaintainedDevices } = sequenceComponentFind(actionComponent)

  const requestValid = await validateRequest(module)
  const stepsValid = validateStepper(module)
  const valid = requestValid && stepsValid

  model[G.STATE][G.ERROR] = !valid
  moduleState[G.ERROR] = !valid

  if (valid) {
    const itemRef = item[G.STATE][G.REF]
    const itemInstalledAtRef = itemInstalledAt[G.STATE][G.REF]
    const installedAtRef = installedAt[G.STATE][G.REF]
    const isMaintained = checked(addToMaintainedDevices)

    item[G.STATE][G.BULK] = (itemRef && itemInstalledAtRef !== installedAtRef)
        || isMaintained

    if (item[G.STATE][G.BULK]) {
      const { person } = session(module)
      const itemInstalledAtCache = itemInstalledAt[G.CACHE]
      isMaintained && (maintainedBy[G.STATE][G.REF] = person.key())

      installedAt[G.STATE][G.REF] = itemInstalledAtRef
      installedAt[G.DATA].value = itemInstalledAtCache ? [itemInstalledAtCache] : []
      item[G.STATE][G.REF] = itemRef
    }

    composeLocation(module)
    await composeRequest(module)
  }
}

/**
 * Checks whether the currently inserted ticket data is valid and all required steps are completed.
 * If that's the case, finishes filling the ticket with data that can be deduced.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const bulk = module => () => async (...args) => {
  await submitWizard(module)
  return args
}

export default bulk
