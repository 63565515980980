/* eslint-disable no-unused-vars,no-param-reassign,no-return-assign,arrow-body-style */
/* global G */
import { asyncpipe, bulk, curry, deleteKey, setKey } from 'lib/util'
import { hide, show } from 'lib/sequence/component/state/hidden'
import find from 'lib/sequence/component/children/find'
import { actionWithHooks } from 'lib/sequence/module/action'
import validate from 'lib/sequence/model/validate'
import sequenceComponentState from 'lib/sequence/component/state'
import { get } from 'lib/sequence/component/state/value'
import search from 'app/_shared/events/search'
import { setData } from 'lib/sequence/model/api/set'
import reset from 'lib/sequence/model/api/reset'

const {
  set: setError,
  unset: unsetError,
} = sequenceComponentState('error')

const {
  set: setHelperText,
  unset: unsetHelperText,
} = sequenceComponentState('helperText')

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Helper function used to simplify the calls used to obtain translated strings.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {string} [ns]                 optional namespace name to be used for all calls
 * @return {(options: object) => Promise<string>}
 */
export const translator = (module, ns) => async (options) => {
  return await module[G.ADAPTER][G.INTL]._t(options._key, { ...options, ns })
}

/**
 * Sets the createRequest property of the ticket {@param model} as true if {@param user} has the
 * 'Customer' or the 'Requester' roles.
 *
 * @param {Gaia.Model.Spec} model  the current ticket model object
 * @param {Object} user       user data to check for Customer or Request role
 */
export const setCreateRequest = (model, user) => {
  const { roles } = user.value
  const createRequestRoles = ['Customer', 'Requester']
  const createRequest = roles.some(role => createRequestRoles.includes(role.split('@')[0]))

  setData(model, { createRequest })
}

/**
 * Sets {@param organisation} as the requesterOrg's data of the ticket {@param model} object.
 *
 * @param {Gaia.Model.Spec} model the current ticket model object
 * @param {Object} organisation   organisation data to set as the {@param model}'s requesterOrg.
 */
const setRequesterOrg = (model, organisation) => {
  const { requesterOrg } = model[G.CHILDREN]

  reset(requesterOrg, { clear: true })
  setKey(organisation.key, G.REF, requesterOrg[G.STATE])
  setData(requesterOrg, {
    ...organisation.value,
    ...organisation.refs,
  })
}

/**
 * Sets {@param person} as the requesterContact's data of the ticket {@param model} object.
 *
 * @param {Gaia.Model.Spec} model the current ticket model object
 * @param {Object} person         person data to set as the {@param model}'s requesterContact.
 */
const setRequesterContact = (model, person) => {
  const { requesterContact } = model[G.CHILDREN]
  const { firstName, lastName } = person.value

  reset(requesterContact, { clear: true })
  setKey(person.key, G.REF, requesterContact[G.STATE])
  setData(requesterContact, {
    firstName,
    lastName,
    ...person.refs,
    contactChannels: person.value.contactChannels.reduce((acc, channel) => {
      acc[channel.name] = channel.value
      return acc
    }, {}),
  })
}

/**
 * Performs a search request looking for all persons whose username is the one inserted in the
 * account field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Object} components           the find sequence proxy wrapping the action's component
 * @returns {Promise<*>}
 */
const findAccount = async (module, components) => {
  const { component, account } = components
  const username = get(account)
  const options = { type: 'user', filter: { _id: `org.couchdb.user:${username}` } }
  const [user] = await search(null, module, component, options) || []
  const [person] = user?.refs?.person || []
  const [organisation] = user?.refs?.organisation || []
  return [user, person, organisation]
}

/**
 * Instructs the current {@param module} to execute the current action again.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the component that triggered the event
 * @param {Gaia.PlatformEvent} event    the event object
 */
export const action = curry(async (module, component, event) => {
  await actionWithHooks(module[G.STATE][G.ACTION])()
  return event
})

/**
 * Displays and hides components depending on whether  {@param foundRequest} is truthy or falsy.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the component that triggered the event
 * @param {Gaia.PlatformEvent} event    the event object
 */
export const toggleSections = curry(async (module, component, event) => {
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const children = find(actionComponent)
  const { personForm, organisationForm, deviceForm, requestTypeForm, requestForm } = children
  const { requestTitle, noteTitle, btnBulk, btnSend } = children

  modelState[G.REF]
    ? bulkShow(noteTitle, requestForm, btnSend)
      && bulkHide(organisationForm, deviceForm, requestTypeForm, requestTitle, btnBulk)
    : bulkHide(noteTitle, btnSend)
      && bulkShow(organisationForm, requestTypeForm, requestTitle, btnBulk)

  modelState[G.USER]
    ? bulkHide(personForm, organisationForm)
    : bulkShow(personForm)

  return event
})

/**
 * Sets the state of the account field according to its validation's result.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the component that triggered the event
 * @param {Gaia.PlatformEvent} event    the event object
 */
const setFieldState = curry(async (module, component, event) => {
  const translate = translator(module, 'user')
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const { account } = find(module[G.STATE][G.ACTION][G.COMPONENT])

  if (get(account)) {
    if (modelState[G.ERROR]?.[G.REF]?.account === 404) { // if user wasn't found
      setKey(null, G.ERROR, modelState)
      unsetError(account)
      setHelperText(account, await translate({
        _key: 'label.userNotRegistered',
        defaultValue: 'New user',
      }))
    } else if (!modelState[G.ERROR]) {
      modelState[G.REF]
        ? setHelperText(account, await translate({
          _key: 'label.userRegisteredDone',
          defaultValue: 'Registered user; no further information is necessary',
        }))
        : setHelperText(account, await translate({
          _key: 'label.userRegistered',
          defaultValue: 'Registered user',
        }))
    }
  }
})

/**
 * Validates the inserted user account and requests its person's data if it is valid.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @param {Gaia.Component} component    the component that triggered the event
 * @param {Gaia.PlatformEvent} event    the event object
 */
const validateAccount = curry(async (module, component, event) => {
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const components = find(actionComponent)
  const { user: userForm, account } = components

  unsetError(account)
  unsetHelperText(account)
  deleteKey(G.USER, modelState)
  setData(model, { createRequest: null })

  if (get(account)) {
    await validate(model)(userForm)

    if (!modelState[G.ERROR]) {
      const [user, person, organisation] = await findAccount(module, components)
      setRequesterContact(model, person)
      setRequesterOrg(model, organisation)
      setCreateRequest(model, user)
      setKey(true, G.USER, modelState)
    }
  }

  return event
})

/**
 * Check Account Event Handler
 *
 * Checks whether the introduced account exists, stores it and alters the display of screen
 * components according to it.
 *
 * @type {Gaia.AppModule.EventHandler}.
 */
export default curry(async (module, component, event) => asyncpipe(
  validateAccount(module, component),
  setFieldState(module, component),
  toggleSections(module, component),
  action(module, component),
)(event))
