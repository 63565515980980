/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import submitRole from 'model/ticket/collection/filter/submitRole'

/**
 * Lists static options for the `submitRole` filter menu for the ticket list.
 *
 * @param {Gaia.AppModule.Spec} module    app module
 * @param {Gaia.Component.Spec} component action component
 * @param {Gaia.PlatformEvent} event      event
 * @return {Array} submitRoles static options
 */
const listTicketFilterSubmitRole = (module, component, event) => submitRole.map(item => ({
  ...item,
  value: module[G.ADAPTER][G.INTL]._t(
    `filter.submitRole.${item.key}`,
    { ns: 'ticket', _key: `filter.submitRole.${item.key}`, defaultValue: item.value },
  ),
}))

export default curry(listTicketFilterSubmitRole)
