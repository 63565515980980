/* global G */
import { curry } from 'lib/util'

/**
 * Note Get Event Handler
 *
 * Returns a list of the current notes or a specific note if `event.key` or
 * `event.detail.key` is present.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        information about the note
 * @returns {Promise<unknown>}
 */
const get = async (module, component, event) => {
  const { key = null } = event?.detail || event || {}
  const eventBus = module[G.ADAPTER][G.EVENTS]

  return new Promise((resolve) => {
    eventBus.add(eventBus.type(G.NOTE, G.DONE), ({ detail }) => {
      const notes = detail[G.DATA]

      resolve(key
        ? notes.find(note => note.key === key)
        : notes)
    }, { once: true })
    eventBus.dispatch(eventBus.type(G.NOTE, G.CACHE))
  })
}

export default curry(get)
