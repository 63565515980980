/* global G */
import noLoaderMiddleware from './middleware'

/**
 * Submit the aggregated payload to the server.
 *
 * @param {boolean} showLoader whether to show the loader for this request
 * @returns {function(*, *, ...[*]): Promise<*[]>}
 */
const submitPayload = showLoader => module => async (previousData, components, ...args) => {
  const model = module[G.MODEL]
  const moduleState = module[G.STATE]
  const { [G.HTTP]: httpAdapter } = model[G.ADAPTER]
  const { options: { version } } = model[G.CONFIGURATION]

  if (!moduleState[G.ERROR] && previousData.payload.length) {
    try {
      const requestArgs = { url: `/api/v${version}/bulk`, params: previousData.payload }

      !showLoader
        ? await httpAdapter.post(requestArgs, { middleware: noLoaderMiddleware })
        : await httpAdapter.post(requestArgs)
    } catch (e) {
      console.error(e)
    }
  }

  return [components, ...args]
}

export default submitPayload
