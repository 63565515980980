/* eslint-disable no-unused-vars */
/* global G */
import { curry, isArr, setKey } from 'lib/util'
import settings from '@tenant/settings'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { bulk } from 'app/_shared/events'
import showDialog, { showBlockingDialog } from 'app/_shared/events/dialog'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Helper function to redirect to `bannedEmailRoute` with the given email.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        the event object that triggered this handler
 * @returns {Promise<void>}
 * @private
 */
const _redirectToContact = async (module, component, event) => {
  try {
    const moduleState = module[G.STATE]
    // Resetting the module state error. Even if we have an error we want to redirect
    // to the contact page
    setKey(null, G.ERROR, moduleState)
    const { bannedEmailRoute } = component[G.CONFIGURATION]
    moduleState[G.ROUTE] = routeComposition(bannedEmailRoute.module, bannedEmailRoute.action)
    await redirectSequence(module)({ username: event.username })
  } catch (e) {
    console.error(e)
  }
}

/**
 * Check Mail And Bulk Event Handler
 *
 * Checks if the email entered by the user has a TLD that is amongst the banned emails in
 * {@link settings.bannedMailDomains}. If it is, it'll show a dialog that redirects the
 * user to the support contact page.
 *
 * If the email TLD is not banned or the setting is not defined at all, it will execute
 * {@link bulk} like usual.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        the event object that triggered this handler
 * @returns {Promise<void>}
 */
const checkMailAndBulk = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const bannedMailDomains = settings?.bannedMailDomains && isArr(settings.bannedMailDomains)
    ? settings.bannedMailDomains
    : []

  const { account } = sequenceComponentFindProxy(actionComponent)
  const email = get(account)

  const isBannedEmail = email?.length
    ? bannedMailDomains.some(bannedEmail => email.split('@').pop().includes(bannedEmail))
    : false

  if (isBannedEmail) {
    const confirmed = await showBlockingDialog(module, component, {
      title: { ns: 'guest', key: 'dialog.bannedEmail.title' },
      text: { ns: 'guest', key: 'dialog.bannedEmail.text' },
      ok: { ns: 'guest', key: 'button.createRequest' },
    })

    confirmed && await _redirectToContact(module, component, { username: email })
  } else {
    await bulk(module, component, event)
  }
}

export default curry(checkMailAndBulk)
