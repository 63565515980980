/* eslint-disable prefer-destructuring */
/* global G */
import ui from 'lib/hook/ui/update'
import validate from 'lib/hook/model/validate'
import read from 'lib/hook/model/read'
import confirm from 'lib/hook/router/confirm'
import submit from 'lib/hook/model/submit'
import create from 'app/_shared/hook/appbar/create'
import search from 'app/_shared/hook/appbar/search'
import clear from 'app/_shared/hook/model/clear'
import reset from 'app/_shared/hook/component/reset'
import check from 'app/_shared/hook/check'
import { setKey } from 'lib/util'
import back from 'app/_shared/hook/redirect/back'
import modelReset from 'lib/hook/model/reset'
import toggleEmailValidator from 'app/_shared/hook/model/toggleEmailValidator'
import recall from 'app/_shared/hook/component/recall'
import cacheTitle from 'app/_shared/hook/model/cacheTitle'
import updateCache from 'app/_shared/hook/model/updateCache'

const _searchAndCreateHooks = {
  before: [
    create, // configuring AppBar CreateButton
    search, // configuring AppBar SearchField
    recall,
  ],
  after: [
    ui,
  ],
}

const _newHooks = {
  before: [
    modelReset,
    reset,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _editHooks = {
  before: [
    clear,
    reset,
    read,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _detailHooks = {
  before: [
    clear,
    reset,
    read,
    // Setting the count (which contains the user) we got from the FTS so that we can use it
    obj => (...args) => {
      const { count } = args[0]
      count && setKey(count, 'count', obj[G.MODEL][G.CACHE].value)

      return args
    },
    cacheTitle('firstName', 'lastName'),
    recall,
  ],
  after: [
    ui,
  ],
}

const _submitHooks = {
  before: [
    toggleEmailValidator(),
    validate, // seq validate
  ],
  after: [
    ui, // show validation errors, if exist
    check,
    submit, // seq create/update, getValue, transform, post/put
    updateCache,
    obj => (...args) => {
      setKey(obj[G.ADAPTER][G.ROUTER].currentRoute(), G.PREV, obj[G.STATE])
      return args
    },
    back,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _searchAndCreateHooks,
    newColleague: _newHooks,
    newContact: _newHooks,
    edit: _editHooks,
    detail: _detailHooks,
    submit: _submitHooks,
  },
}

export default hooks
