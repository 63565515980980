import detail from './action/detail'
import edit from './action/edit'
import submit from './action/submit'
import index from './action/index'
import create from './action/create'

export default {
  index,
  new: create,
  edit,
  detail,
  submit,
}
