/* global G */
import { curry } from 'lib/util'

/**
 * Recursive data fetcher
 *
 * executes remote api call with given parameters via POST
 *
 * @param fn - callback
 * @param url
 * @param params
 * @param values
 * @return {Promise<Promise<*|{data: *[], page: *}>|{data: *[], page}>}
 */
export const search = async (fn, url, params, values = []) => {
  const { value, next: page } = await fn({ url, params })
  const data = [...values, ...value]
  return page && data.length < params.limit
    ? await search(fn, url, { ...params, page }, data)
    : { data, page }
}

/**
 * Search Event Handler
 *
 * used for fetching data for rendered iterable ui components
 *
 * requires a predefined set of parameters, where as the filter body should
 * be provided via an action FILTER implementation
 *
 *
 * @param {string} key - listing type, ie short, verbose, etc
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 *
 * @param {Event|object} [eventOrOptions]       information about the event that triggered this
 *                                              handler or a search term
 * @param {Object} [eventOrOptions.detail]      information about the target of the event
 * @return {Promise<{value: string, key: *}[]>} void
 */
// eslint-disable-next-line no-unused-vars
const searchFn = async (key, module, component, eventOrOptions) => {
  try {
    const componentStateMeta = component[G.STATE][G.META] || {}
    const defaultTransform = { key: key || 'default' }
    const options = (eventOrOptions && eventOrOptions.detail) || eventOrOptions || {}
    const { term: eventTerm, page: eventPage, type: eventType, limit: eventLimit } = options
    const { filter: eventFilter, query: eventQuery } = options
    const { version, api } = module[G.MODEL][G.PROPS]
    const { limit = 10, api: { type = eventType } = {}, sort, unsorted } = component[G.PROPS]
    const { term = eventTerm, filter, query, transform = defaultTransform } = componentStateMeta

    const correctType = query?.includes?.('metatype') || eventQuery?.includes?.('metatype')
      ? undefined
      : type || api

    const params = {
      page: eventPage || undefined,
      // page: (incoming in next param payload)
      // if next is full, and body is empty, keep polling for next, ie {value: [], next: 'passcode'}
      // if no next in response, only then there are no results, ie {value: []} - finish search
      limit: eventLimit ?? limit,
      sort: !unsorted ? sort || [
        // ['customerData.value.name<string>'], // does not work atm
        // ['dateCreated<string>', 'desc'], // only first level value.* can be respected in sorting
        // only first level value.* can be respected in sorting
        ['name<string>', 'asc'], // only first level value.* can be respected in sorting
      ] : [],
      transform,
      query: {
        term,
        type: correctType,
      },
      filter: {
        ...filter,
        ...eventFilter,
      },
      lucene: query || eventQuery,
      // example: {
      //   "dateCreated": {
      //     "minDate": "2020-07-16T13:40:20.233Z"
      //   }
      // }
    }

    const url = `/api/v${version}/search`
    try {
      const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]

      // const result = await remoteAdapter.post({ url, params })
      // return result.value
      return await search(remoteAdapter.post, url, params, [], null)
    } catch {
      console.error('search:: tried and failed getting remote resources', url)
    }
  } catch (e) {
    console.error(e)
  }
  return [
    { key: -1, value: 'Not Found' },
  ]
}

export default curry(searchFn)
