/* eslint-disable no-nested-ternary */
/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Get Component Attachment Event Handler
 *
 * Will be given a list of attachments inside `event.detail`, which are the attachments
 * for the group defined in the action config. It'll then use the `target` component to
 * find the correct attachment using either the `url` property, in case it's a video, or
 * the `value.resourceId` property.
 *
 * @param {string} target                 name of the component to get the attachment from
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      the event object that triggered this handler
 * @returns {Attachment|null}
 */
const getComponentAttachment = (target, module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { attachments = [] } = event?.detail || event || {}

  const { [target]: targetComponent } = sequenceComponentFindProxy(actionComponent)

  if (targetComponent) {
    const { attachment: { value: { url = null, resourceId = null } = {} } = {} } = targetComponent?.[G.STATE] || {}

    return attachments.find(attachment => (url && attachment?.url === url)
      || (resourceId && attachment?.value?.resourceId === resourceId))
      || null
  }

  return null
}

export default curry(getComponentAttachment)
