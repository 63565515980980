/* global G */
import { curry, PlatformEvent, setKey } from 'lib/util'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Search And Update With Event Handler
 * Executes {@link searchAndRecreate} for the given {@param key} list. Instructs the
 * handler to not rebuild the view, but instead only rebuilds the list.
 *
 * @param {string} key                    key of the list to update
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @returns {Promise<void>}
 */
const searchAndUpdateWithListKey = async (key, module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const searchEvent = new PlatformEvent(event, {
    ...event.detail,
    listKey: key,
    reload: false,
  })

  const { [key]: list } = sequenceComponentFindProxy(actionComponent)
  setKey({}, 'reload', list[G.STATE])

  await searchAndRecreate(module, component, searchEvent)
  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(searchAndUpdateWithListKey)
