/* eslint-disable no-unused-vars */
/* global G */
import { curry, isArr } from 'lib/util'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Redirect To Check Or Approve With Handler
 *
 * Redirects to the {@code checkRoute} defined in the component if the current organisation
 * has not been checked for duplicates yet. If it has, it will redirect to {@code originalRoute},
 * if the organisation is not a duplicate and to {@code duplicateRoute} if it is.
 *
 * Will also pass the current organisation to the new module/action.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 * @returns {Promise<void>}
 */
const redirectToCheckOrApproveWith = async (module, component, event) => {
  const model = module[G.MODEL]
  const modelCache = model[G.CACHE]
  const moduleState = module[G.STATE]

  const { duplicateOf = null } = modelCache?.refs || {}
  const { checkRoute, originalRoute, duplicateRoute } = component[G.CONFIGURATION]

  // eslint-disable-next-line no-nested-ternary
  const targetRoute = duplicateOf
    ? isArr(duplicateOf) && duplicateOf.length === 0
      ? originalRoute
      : duplicateRoute
    : checkRoute

  moduleState[G.ROUTE] = routeComposition(targetRoute.module, targetRoute.action)

  await redirectSequence(module)({ organisation: model })
}

export default curry(redirectToCheckOrApproveWith)
