/* global G */
import { curry, getFirstItem, setKey } from 'lib/util'
import search from 'app/_shared/events/search'
import showDialog from 'app/_shared/events/dialog'
import back from 'app/_shared/events/redirect/back'

/**
 * Search Organisation Multi Handler
 *
 * Uses {@code query} to search for an organisations related persons, serviceitems
 * and requests.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<*>}
 */
const searchOrganisationMulti = async (module, component, event) => {
  const moduleState = module[G.STATE]
  const model = module[G.MODEL]
  const { organisation = {} } = model[G.CHILDREN] || {}
  const { [G.STATE]: { [G.REF]: orgRef = null } = {} } = organisation
  const orgId = orgRef?.replaceAll(':', '\\:')

  const user = getFirstItem(
    await search(null, module, component, { detail: {
      query: `metatype:user AND organisation:${orgId}`,
    } }),
  )?.key
  const userId = user?.replaceAll?.(':', '\\:') || null

  const conditions = [
    `metatype:request AND requesterContactOrg:${orgId}`,
    `metatype:serviceitem AND tree_installedAt:${orgId}`,
    `metatype:person AND organisation:${orgId}`,
    `metatype:organisation AND tree_parent:${orgId}`,
  ]

  userId && conditions.push(`metatype:ticket AND submitter:${userId}`)

  const query = conditions.map(cond => `(${cond})`).join(' OR ')

  const result = await search('verbose', module, component, { detail: { query } })

  const items = result.map((item) => {
    const targetType = (item.refs.requesterContactOrg && 'request')
        || (item.refs.team && 'ticket')
        || (item.refs.installedAt && 'serviceitem')
        || (item.refs.organisation && 'person')
        || (item.refs.parent && 'organisation')
    return { ...item, value: { ...item.value, docType: targetType } }
  })

  // Sorting items according to docType
  const sortedItems = items.sort((a, b) => (a.value.docType === 'person' && -1)
        || (a.value.docType === 'organisation' && b.value.docType === 'serviceitem' && -1)
        || 0)

  // Saving data in the module for submit later
  setKey(sortedItems, 'data', moduleState)

  const subUser = sortedItems.filter(item => item.value.docType === 'person')
  const subOrganisations = sortedItems.filter(item => item.value.docType === 'organisation')

  // If there is more than one user, or any sub organisation, we consider this a complex case.
  // As of now, we forbid these scenarios. We will enable them later when we know what actually
  // needs to be done in these cases. For now, show a dialog and go back to the details view.
  if (subUser.length > 1 || subOrganisations.length) {
    await showDialog(module, component, {
      title: {
        ns: 'admin',
        key: 'dialog.complexCaseError.title',
        defaultValue: 'Sorry',
      },
      text: {
        ns: 'admin',
        key: 'dialog.complexCaseError.text',
        defaultValue: 'The approval comfort function cannot yet handle complex cases. Please process the approval manually or contact ApproLogic GmbH.',
      },
      ok: {
        ns: 'common',
        key: 'button.close',
        defaultValue: 'Close',
      },
    })
    await back(module, component, event)

    return []
  }

  // Return all fetched items but ticket
  // We may need them later on in the submit flows, but we'll never show
  // them in the actual list
  return sortedItems.filter(item => item.value.docType !== 'ticket')
}

export default curry(searchOrganisationMulti)
