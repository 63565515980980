/* global G */
import { get } from 'lib/sequence/component/state/value'
import { getFirstItem, isStr } from 'lib/util'
import { mapRefs } from 'app/admin/action/approve/partials/submit/composePayloadSubItems'
import noLoaderMiddleware from 'app/admin/action/approve/partials/submit/middleware'

/**
 * Composes the payload for the user by setting its {@code roles} property
 * to whatever was selected in the UI.
 *
 * @param {boolean} showLoader whether to show the loader for this request
 * @returns {function(*, *, ...[*]): Promise<[{payload: [...*,{refs: {}, _rev, _id, type: string, value: {[p: string]: *}}]},undefined,...*[]]>}
 */
const submitUser = showLoader => module => async (components, ...args) => {
  const moduleState = module[G.STATE]
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]

  const { organisation, user: userModel } = module[G.MODEL][G.CHILDREN]
  const { [G.CONFIGURATION]: { options: { api, version } = {} } = {} } = userModel

  // Getting the correct orgId, in case of duplicate approval, we need to get it from
  // the duplicateOf ref of the main org, if that's not defined we're in the original
  // approval scenario, in which case we can use the original id stored in the model
  // state
  const { refs: { duplicateOf: duplicateOfRef } } = organisation[G.CACHE]
  const duplicateOf = getFirstItem(duplicateOfRef)
  const duplicateId = duplicateOf && isStr(duplicateOf) ? duplicateOf : duplicateOf?.key
  const { [G.STATE]: { [G.REF]: orgRef = null } = {} } = organisation

  const orgId = duplicateId || orgRef

  // Getting the correct userId from the previously fetched data in the module state
  const person = moduleState.data.find(x => x.value.docType === 'person')
  const user = getFirstItem(person.refs.user)
  const userId = isStr(user) ? user : user.key

  // Getting the correct role from the input field
  const { role } = components
  const roleValue = get(role)
  const newRoleKey = getFirstItem(roleValue)?.key

  if (!moduleState[G.ERROR]) {
    try {
      // Querying the user again to get a fresh copy, a server WF has changed the
      // user doc since the last time we fetched it
      const searchArgs = {
        url: `/api/v${version}/search`,
        params: { lucene: `metatype:user AND id:${userId.replaceAll(':', '\\:')}` },
      }

      const result = !showLoader
        ? await httpAdapter.post(searchArgs, { middleware: noLoaderMiddleware })
        : await httpAdapter.post(searchArgs)

      const userDoc = getFirstItem(result?.value)
      const params = {
        _id: userDoc.key,
        _rev: userDoc._rev,
        refs: mapRefs(userDoc.refs),
        value: {
          ...userDoc.value,
          roles: userDoc.value.roles.map(x => (x.includes(orgId) ? `${newRoleKey}@${orgId}` : x)),
        },
      }
      const userArgs = { url: `/api/v${version}/${api}/${userDoc.key}`, params }

      // Saving the user with the correct role at the correct org
      !showLoader
        ? await httpAdapter.put(userArgs, { middleware: noLoaderMiddleware })
        : await httpAdapter.put(userArgs)
    } catch (e) {
      console.error(e)
    }
  }

  return [components, ...args]
}

export default submitUser
