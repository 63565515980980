/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { checked } from 'lib/sequence/component/state/checked'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import validate from 'lib/sequence/model/validate'

/**
 * Validate Note Event Handler
 *
 * Validates the given note for cancelled tickets on the fly and updates the UI.
 * Will display a possible validation error in the UI and enable/disable the
 * confirm button based on the value of `confirm` and the ticket note.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<void>}
 */
const validateNote = async (module, component, event) => {
  const model = module[G.MODEL]
  const { ticket } = model[G.CHILDREN]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const [checkboxes, actions] = actionComponent[G.ACTIONS]

  const { confirmTicketNote, ticketNoteSection } = sequenceComponentFindProxy(actionComponent)

  const { note: noteValidator } = ticket[G.VALIDATOR]

  if (checked(confirmTicketNote)) {
    enable(noteValidator)
    await validate(ticket)(ticketNoteSection)
  } else {
    disable(noteValidator)
    ticket[G.STATE][G.ERROR] = null
  }

  const hasError = model[G.STATE][G.ERROR] || ticket[G.STATE][G.ERROR]

  const { confirm } = sequenceComponentFindProxy(checkboxes)
  const { btnVerify } = sequenceComponentFindProxy(actions)

  checked(confirm) && !hasError
    ? enable(btnVerify)
    : disable(btnVerify)

  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(validateNote)
