/* eslint-disable no-unused-vars */
/* global G, PLATFORM */
import { asyncPipeSpread } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import initDocumentationFeedback from 'app/_shared/action/partial/feedback/initDocumentation'

/**
 * EquipmentInformation EquipmentBulletin Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  initDocumentationFeedback(module)(component),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
