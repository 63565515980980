/* eslint-disable no-unused-vars,arrow-body-style,no-param-reassign */
/* global G */
import { curry } from 'lib/util'
import {
  back,
  edit,
  list,
  persistChange,
  persistCheck,
  persistOption,
  persistOptions,
  redirect,
  submit,
} from '@app/_shared/events'
import { jump as jumpBack, list as listBreadcrumbs } from 'app/_shared/events/breadcrumbs'
import detail from 'app/_shared/events/action/detail'
import search from 'app/_shared/events/search'
import infinite from 'app/_shared/events/search/infinite'
import createRedirect from 'app/_shared/events/appbar/createRedirect'
import listCountries from 'app/_shared/events/collection/listCountries'
import listUserCountries from 'app/_shared/events/collection/listUserCountries'
import listStatus from 'app/_shared/events/collection/listStatus'
import { conflict, count, document, item, own, reference } from 'app/_shared/events/pubsub'
import menu from 'app/_shared/events/contextmenu'
import link from 'app/_shared/events/link'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'
import listTicketStatus from 'app/_shared/events/collection/listTicketStatus'
import listStatusReasons from 'app/_shared/events/collection/listStatusReasons'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'
import countOpenRequests from 'app/_shared/events/widget/count/openRequests'
import countPersons from 'app/_shared/events/widget/count/contacts'
import countServiceItems from 'app/_shared/events/widget/count/activeDevices'
import { acl, cell, model } from 'app/_shared/events/acl'
import toDetailsOrIndex from 'app/_shared/events/redirect/toDetailsOrIndex'
import chain from 'app/_shared/events/util/chain'
import prefillSupportedBy from 'app/_shared/events/prefillSupportedBy'
import { refList } from 'app/_shared/events/list'
import searchExclude from 'app/_shared/events/searchExclude'
import sublist from 'app/_shared/events/util/sublist'
import searchUnsortedAndRecreate from 'app/_shared/events/appbar/searchUnsortedAndRecreate'
import searchWith from 'app/_shared/events/search/with'
import setCountedLabel from 'app/_shared/events/tabs/setCountedLabel'
import persistStore, { persistOptionsStore } from 'app/_shared/events/combined/persistStore'
import destroy from 'app/_shared/events/destroy'
import showDialogAndPersistOptions from 'app/_shared/events/combined/showDialogAndPersistOptions'
import persistCheckAndAction from 'app/_shared/events/combined/persistCheckAndAction'
import hasMultipleFilters from 'app/_shared/events/search/query/hasMultipleFilters'
import listInvitationStatuses from 'app/_shared/events/collection/listInvitationStatuses'
import listInvitationRoles from 'app/_shared/events/collection/listInvitationRoles'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'
import persistTab from 'app/_shared/events/tabs/persist'
import persistOptionsAndAction from 'app/_shared/events/combined/persistOptionsAndAction'
import remember from 'app/_shared/events/remember'
import action from 'app/_shared/events/action'
import excludeNotConfirmedForRoles
  from 'app/_shared/events/search/query/excludeNotConfirmedForRoles'
import listRequestStatus from 'app/_shared/events/collection/listRequestStatus'
import setListDrawerTitle from 'app/_shared/events/tabs/setListDrawerTitle'
import fromUserOrgs from 'app/_shared/events/search/query/fromUserOrgs'
import listSupportedBy from 'app/organisation/events/listSupportedBy'
import destroyIfNoSubItems from 'app/organisation/events/destroyIfNoSubItems'
import supportedBy from 'app/organisation/events/dialog/supportedBy'
import parent from 'app/organisation/events/dialog/parent'
import submitIfConfirmed from 'app/organisation/events/submitIfConfirmed'
import checkMergeAndPersistStore from 'app/organisation/events/checkMergeAndPersistStore'
import redirectWithOrganisation from 'app/organisation/events/redirectWithOrganisation'
// TODO: Move to _shared
import getValidationStatus from 'app/person/events/getValidationStatus'
import redirectToCheckOrApproveWith from 'app/organisation/events/redirectToCheckOrApproveWith'
import showDialogOrRedirectWith from 'app/organisation/events/showDialogOrRedirectWith'

export default {
  onOpen: {
    listing: list('default'),
    listWithRefs: list(null),
    listVerbose: list('verbose'),
    refListVerbose: refList('verbose'),
    refListWithCount: curry(async (module, component, event) => (
      module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT] === 'req'
        ? await refList('defaultWithRequestCount', module, component, event)
        : await refList('defaultWithTicketCount', module, component, event)
    )),
    infinite: infinite(null),
    infiniteWithQuery: chain(
      searchWith(['query']),
      infinite(null),
    ),
    infiniteWithFilter: chain(
      searchWith(['filter']),
      infinite(null),
    ),
    infiniteShortWithFilter: chain(
      searchWith(['filter']),
      infinite('short'),
    ),
    infiniteVerboseWithFilter: chain(
      searchWith(['filter']),
      infinite('verbose'),
    ),
    infiniteWithCountWithFilter: curry(async (module, component, event) => {
      await searchWith(['filter'], module, component, event)
      return module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT] === 'req'
        ? await infinite('defaultWithRequestCount', module, component, event)
        : await infinite('defaultWithTicketCount', module, component, event)
    }),
    infiniteWithCountWithFilterAndQuery: curry(async (module, component, event) => {
      await searchWith(['filter', 'query'], module, component, event)
      return module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT] === 'req'
        ? await infinite('defaultWithRequestCount', module, component, event)
        : await infinite('defaultWithTicketCount', module, component, event)
    }),
    listCountries,
    search: search(null),
    searchVerbose: search('verbose'),
    searchWithFilter: chain(
      searchWith(['filter']),
      search(null),
    ),
    searchWithCountWithFilterAndQuery: curry(async (module, component, event) => {
      await searchWith(['filter', 'query'], module, component, event)
      return module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT] === 'req'
        ? await search('defaultWithRequestCount', module, component, event)
        : await search('defaultWithTicketCount', module, component, event)
    }),
    searchOther: searchExclude('short'),
    getValues: curry(async (module, component, event, values) => values || []),
    reference, // edit form
    own,
    link,
    setTabSearch: searchWith(['filter']),
    setTabSearchWithQuery: searchWith(['filter', 'query']),
    setTabSearchAndListDrawerTitle: chain(
      searchWith(['filter']),
      setListDrawerTitle,
    ),
    setTabSearchWithQueryAndListDrawerTitle: chain(
      searchWith(['filter', 'query']),
      setListDrawerTitle,
    ),
    getValidationStatus,
    listStatus,
    listBreadcrumbs,
  },
  onShowAll: {
    infinite: infinite('verbose'),
    infiniteWithFilter: chain(
      searchWith(['filter']),
      infinite(null),
    ),
    infiniteVerboseWithFilter: chain(
      searchWith(['filter']),
      infinite('verbose'),
    ),
    infiniteWithCountWithFilter: curry(async (module, component, event) => {
      await searchWith(['filter'], module, component, event)
      return module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT] === 'req'
        ? await infinite('defaultWithRequestCount', module, component, event)
        : await infinite('defaultWithTicketCount', module, component, event)
    }),
    infiniteWithCountWithFilterAndQuery: curry(async (module, component, event) => {
      await searchWith(['filter', 'query'], module, component, event)
      return module[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT] === 'req'
        ? await infinite('defaultWithRequestCount', module, component, event)
        : await infinite('defaultWithTicketCount', module, component, event)
    }),
  },
  onSearch: {
    searchUnsortedAndRecreate,
  },
  onCreate: {
    createRedirect,
  },
  onClose: {
    redirect,
    back,
  },
  onClick: {
    edit,
    redirect,
    submit,
    submitIfConfirmed,
    detail,
    back,
    toDetailsOrIndex,
    redirectWith: redirectWithOrganisation,
    redirectToRequestWith: redirectWithOrganisation,
    redirectToTicketWith: redirectWithOrganisation,
    redirectToCheckOrApproveWith,
    showDialogOrRedirectWith,
    destroy,
    destroyIfNoSubItems,
    jumpBack,
  },
  onChange: {
    persistChange,
    persistCheckAndAction,
    persistOptions,
    persistStore,
    checkMergeAndPersistStore,
    persistOptionsStore,
    persistCountry: chain(
      persistOptions,
      prefillSupportedBy,
    ),
    fillSupportedByAndPersistChange: chain(
      persistChange,
      prefillSupportedBy,
    ),
    persistOption,
    persistOptionsAndAction,
    showDialogAndPersistOptions,
    persistTabAndRemember: chain(
      persistTab,
      remember,
    ),
    persistOptionsRememberAndAction: chain(
      persistOptions,
      remember,
      action,
    ),
    persistCheckRememberAndAction: chain(
      persistCheck,
      remember,
      action,
    ),
  },
  // form listener on pub
  onPublication: {
    document,
    conflict,
    countOpenRequests,
    countPersons,
    countServiceItems,
  },
  // cell count
  count: {
    sub: count,
  },
  // list item row
  item: {
    sub: item,
  },
  list: {
    sub: own,
  },
  onContextMenu: {
    menu: menu(null),
  },
  onPage: {
    remember,
  },
  onLinkChange: {
    setLink: link,
  },
  getTicketType: {
    listTicketTypes,
  },
  getStatus: {
    listTicketStatus,
    listRequestStatus,
    listStatus,
  },
  getInvitationStatus: {
    listInvitationStatuses,
  },
  getInvitationRole: {
    listInvitationRoles,
  },
  getUserRole: {
    listUserRoles,
  },
  getStatusReason: {
    listStatusReasons,
  },
  getOrganisationType: {
    listOrganisationTypes,
  },
  getSelection: {
    listSupportedBy,
    listOrganisationTypes: curry(async (module, component, event) => {
      return sublist(
        ['customer', 'independent_contractor', 'internal', 'partner'],
        listOrganisationTypes,
      )(module, component, event).map((type) => {
        delete type.icon
        return type
      })
    }),
    listUserCountries,
  },
  acl: {
    acl,
    cell,
    model,
  },
  getLabel: {
    setCsmLabel: setCountedLabel({ ns: 'ticket', key: 'tickets' }),
    setReqLabel: setCountedLabel({ ns: 'ticket', key: 'requests' }),
    setDeviceLabel: setCountedLabel({ ns: 'device', key: 'devices' }),
    setPersonLabel: setCountedLabel({ ns: 'person', key: 'contacts' }),
    setChildOrganisationLabel: setCountedLabel({ ns: 'organisation', key: 'childOrganisations' }),
    setOrganisationLabel: curry(async (module, component, event) => {
      const providesSupport = module[G.MODEL][G.CACHE]?.value?.support?.provides
      const label = await setCountedLabel(
        { ns: 'organisation', key: 'supportedOrganisations' },
        module,
        component,
        event,
      )

      return providesSupport || label.count > 0
        ? label
        : { ...label, hidden: true }
    }),
    listOrganisationTypes,
  },
  dialog: {
    supportedBy,
    parent,
  },
  filter: {
    requesterOrganisation: curry((module, component, event) => (
      { requesterContactOrg: module[G.MODEL][G.STATE][G.REF] }
    )),
    itemInstalledAt: curry((module, component, event) => (
      { itemInstalledAt: module[G.MODEL][G.STATE][G.REF] }
    )),
    installedAt: curry((module, component, event) => (
      { installedAt: module[G.MODEL][G.STATE][G.REF] }
    )),
    organisation: curry((module, component, event) => (
      { organisation: module[G.MODEL][G.STATE][G.REF] }
    )),
    requesterOrg: curry((module, component, event) => (
      { requesterOrg: module[G.MODEL][G.STATE][G.REF] }
    )),
    requesterContactOrg: curry((module, component, event) => (
      { requesterContactOrg: module[G.MODEL][G.STATE][G.REF] }
    )),
    supportedBy: curry((module, component, event) => (
      { supportedBy: module[G.MODEL][G.STATE][G.REF] }
    )),
    isNotInactiveAndMyChild: curry((module, component, event) => ({
      status: { max: 79 },
      parent: module[G.MODEL][G.STATE][G.REF],
    })),
    isActiveAndNotMyself: curry((module, component, event) => ({
      status: 50,
      id: `!${module[G.MODEL][G.STATE][G.REF]}`,
    })),
  },
  query: {
    orgHasMultipleFilters: hasMultipleFilters(null, [
      'status',
      'country',
      'toBeApproved',
      'toBeValidated',
      'providesSupport',
    ]),
    userOrgsAsParentOrId: fromUserOrgs('parent', 'id'),
    excludeNotConfirmedForRoles,
  },
}
