/* eslint-disable no-plusplus,no-unused-vars,max-len */
/* global G */
import { curry, deleteKey, getFirstItem, setKey } from 'lib/util'
import { _group } from 'app/_shared/events/attachment'

/**
 * Clean up the residual event listeners and transient properties.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Object[]} attachments        the message attachments
 * @param {Function} handler            event handler for each attachment
 * @private
 */
const _cleanup = (module, component, attachments, handler) => {
  const eventAdapter = module[G.ADAPTER][G.EVENTS]
  for (let i = 0; i < attachments.length; i++) {
    eventAdapter.remove(eventAdapter.type(G.ATTACHMENT, G.READ, attachments[i].key), handler)
  }
}

/**
 * Attempts to update the attachments for a given message by inspecting their upload state
 * and setting `key` and `_rev` of their local counterparts properly once their upload
 * is finished.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {Object[]} [attachments]          optional attachments for the message
 * @returns {Promise<boolean>}
 */
const updateAttachments = async (module, component, attachments) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  return new Promise((resolve) => {
    if (attachments.length) {
      for (let i = 0; i < attachments.length; i++) {
        const handler = ({ detail }) => {
          /**
           * The {@code G.ATTACHMENT, G.READ, key} event handler is the correct one to listen to.
           * It will get fired when the server returns a response after uploading an attachment.
           * However, it also gets fired before that. So in order to catch the correct event
           * we check for {@code status === 201}.
           */
          if (detail.key === attachments[i].key && detail.status === 201) {
            const { id: key = null, rev = null } = getFirstItem(detail?.response)

            key
              && key !== attachments[i].key
              && setKey(key, 'key', attachments[i])

            rev
              && rev !== attachments[i]._rev
              && setKey(rev, '_rev', attachments[i])
          }

          if (!attachments.find(att => !att._rev)) {
            _cleanup(module, component, attachments, handler)
            resolve(true)
          }
        }
        eventBus.add(eventBus.type(G.ATTACHMENT, G.READ, attachments[i].key), handler)
      }
    } else {
      resolve(true)
    }
  })
}

export default curry(updateAttachments)
