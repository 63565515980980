/* global G */

/**
 * Change Ref Of Last History Entry Hook
 *
 * Changes the ref of the last history entry and deletes its {@code G.CACHE}
 * attribute. Can be used if the last entry is a details view, but we want
 * to redirect back to a different document afterward.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
export default obj => async (...args) => {
  const { key = null } = args[0]

  if (key) {
    const history = obj[G.ADAPTER][G.SESSION][G.STATE][G.PREV]
    const lastEntry = history[history.length - 1]

    lastEntry && (lastEntry[G.CACHE] = {})
    lastEntry && (lastEntry[G.REF] = key)
  }

  return args
}
