/* global G */

import routeComposition from 'trait/composition/route'

/**
 * Delete Or Replace Last History Entry Hook
 *
 * In case the history stack has at least two items, it will delete the last entry
 * from the stack and return. In case the stack has 0 or 1 item, it will mutate
 * the stack to only contain one item, which is a route based on `module` and `action`.
 *
 * @param {string} module module to potentially set as the next entry
 * @param {string} action action to potentially set as the next entry
 *
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default (module, action) => obj => async (...args) => {
  const history = obj[G.ADAPTER][G.SESSION][G.STATE][G.PREV]

  switch (true) {
    case history.length > 1:
      history.pop()
      break
    default:
      history[0] = routeComposition(module, action)
      break
  }

  return args
}
