/* eslint-disable no-unused-vars */
/* global G */
import { bulk, setKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { check, checked, uncheck } from 'lib/sequence/component/state/checked'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { get, reset, set } from 'lib/sequence/component/state/value'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import find from 'lib/sequence/component/children/find'

const bulkHide = bulk(hide)

/**
 * Contact editing and creation.
 *
 * Toggles the visibility and mapping of fields depending on whether the contact should just be
 * saved for a ticket or as an individual entity in the database and whether there is already an
 * entity selected.
 *
 * @param {Object} module the ticket module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  // model attributes
  const { requesterOrg, requesterContact } = module[G.MODEL][G.CHILDREN]
  const { contactChannels } = asObject(requesterContact[G.CHILDREN])
  const { email } = contactChannels[G.CHILDREN]
  const emailAttributeValidators = email[G.VALIDATOR]
  // attribute validator
  const notEmptyValidator = emailAttributeValidators.find(validator => validator._name === 'notEmpty')

  // view components
  const {
    organisation: organisationField,
    buttons,
    toBeInvited,
    contactChannels: contactChannelsField,
    invite,
  } = asObject(component[G.CHILDREN])
  // role was replaced with roleMessage in SP-897
  const { roleMessage, message } = asObject(invite[G.CHILDREN])
  const { store: storeField } = find(component[G.ACTIONS][0])
  const { email: emailField } = asObject(contactChannelsField[G.CHILDREN])
  // data to check
  const storeRequesterOrg = requesterOrg[G.STATE][G.BULK]
  const requesterOrgCache = requesterOrg[G.CACHE]
  const requesterOrgExists = !!requesterOrgCache?._rev
  const requesterContactCache = requesterContact[G.CACHE]
  const requesterContactUser = requesterContactCache?.value.count?.user?.person?.data || false
  const inviteStatus = requesterContactCache?.value?.invite
  const isInvited = inviteStatus?.status === 'invited'
  const selectedOrganisation = get(organisationField)?.[0]
  const organisationIsRequesterOrg = selectedOrganisation?.key === requesterOrgCache?.key
  // if our model doesn't yet have a contact stored, ...
  !requesterContactCache
  // ... there is no selected organisation or the selected one is requesterOrg and ...
  && (!selectedOrganisation || organisationIsRequesterOrg)
  // ... requesterOrg is marked to be stored or exists, ...
  && (storeRequesterOrg || requesterOrgExists)
  // ... check contact to be stored and select requesterOrg as its organisation
  && get(storeField) === undefined
  && check(storeField)
  && set(storeField)
  && set(organisationField, [requesterOrgCache])
  // depending on whether store field is checked...
  const storeChecked = checked(storeField)

  // show or hide the organisation and toBeInvited field
  storeChecked ? show(organisationField) : hide(organisationField)
  storeChecked && !requesterContactUser && !isInvited
    ? show(toBeInvited)
    : bulkHide(toBeInvited, invite)

  !storeChecked && uncheck(toBeInvited)

  const bulkReset = bulk(reset)
  checked(toBeInvited)
    ? show(invite)
      && enable(notEmptyValidator)
    : hide(invite)
      && disable(notEmptyValidator)
      // Resetting error message on unchecking
      && setKey(false, 'error', emailField[G.STATE])
      && setKey(null, 'helperText', emailField[G.STATE])
      && bulkReset(message)

  return args
}
