/* global G */
import { curry, isStr } from 'lib/util'
import { edit as editNote } from 'app/_shared/events/note'
import { actionWithHooks } from 'lib/sequence/module/action'
import { showBlockingDialog } from 'app/_shared/events/dialog'

/**
 * Maps the given refs to their singular string value
 * @param {object} refs
 * @returns {{}}
 */
export const mapRefs = refs => Object.keys(refs).reduce((acc, key) => {
  const ref = refs[key]
  acc[key] = ref.map(x => (isStr(x) ? x : x.key))

  return acc
}, {})

/**
 * Delete Time From Note Event Handler

 * Deletes time specific entries from the current note and instructs the note adapter
 * to update it.
 *
 * @param {Gaia.Module} module        the current module composition object
 * @param {Gaia.Component} component  the current action's main component
 * @param {Gaia.PlatformEvent} event  the event object that triggered this handler
 * @returns {Promise<void>}
 */
const deleteTimeFromNote = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const eventBus = module[G.ADAPTER][G.EVENTS]
  const { timeNotes } = module[G.STATE]

  const { id } = event?.detail || event || {}
  const noteIndex = timeNotes.findIndex(x => x.key === id)
  const note = timeNotes.find(x => x.key === id)

  const item = {
    key: id,
    _rev: note._rev,
    refs: mapRefs(note.refs),
    value: {
      ...note.value,
      spentTime: null,
      startDate: null,
    },
  }

  const confirmed = await showBlockingDialog(module, component, {
    title: {
      ns: 'ticket',
      key: 'dialog.deleteTimeEntry.title',
      defaultValue: 'Delete time entry',
    },
    text: {
      ns: 'ticket',
      key: 'dialog.deleteTimeEntry.text',
      defaultValue: 'Are you sure that you want to delete the time entry from the note? The note itself won\'t be deleted',
    },
  })

  try {
    if (confirmed) {
      const done = await new Promise((resolve) => {
        eventBus.add(eventBus.type(G.NOTE, G.DONE), () => {
          resolve(true)
        }, { once: true })

        note && editNote(module, component, { key: id, item })
      })

      if (done) {
        timeNotes.splice(noteIndex, 1)

        await actionWithHooks(module[G.STATE][G.ACTION])([])
        await module[G.ADAPTER][G.UI].create(module)
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export default curry(deleteTimeFromNote)
