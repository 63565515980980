/* global G */
import { curry } from 'lib/util'

/**
 * BulkFetch event handler using /v1/bulk/fetch
 *
 * @param {Gaia.AppModule.Spec} module          app module
 * @param {Gaia.Component.Spec} component       action component
 * @param {Event|object} [eventOrOptions]       information about the event that triggered this
 *                                              handler or a search term
 * @param {Object} [eventOrOptions.detail]      information about the target of the event
 * @return {Promise<{value: string, key: *}[]>} void
 */
// eslint-disable-next-line no-unused-vars
const bulkFn = async (module, component, eventOrOptions) => {
  const { version } = module[G.MODEL][G.PROPS]
  const ids = (eventOrOptions && eventOrOptions.detail) || eventOrOptions || []
  const url = `/api/v${version}/bulk/fetch`

  try {
    const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
    const result = await remoteAdapter.post({ url, params: ids })

    return result.value
  } catch {
    console.error('search:: tried and failed getting remote resources', url)
  }
  return [
    { key: -1, value: 'Not Found' },
  ]
}

export default curry(bulkFn)
