/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Get Tree Event Handler
 *
 * Retrieve the tree from the action state.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      the event object that triggered this handler
 * @returns {Promise<*>}
 */
const getTree = async (module, component, event) => {
  const { tree } = module[G.STATE][G.ACTION][G.STATE] || {}

  return tree?.value ? tree.value : tree
}

export default curry(getTree)
