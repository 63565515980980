/* global G */

/**
 * Edit Note Event Handler
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.Model.Note} note            the note to edit
 * @param {Function} [callback]             callback to execute when update is done
 * @returns {Promise<void>}
 */
const edit = async (module, component, { key, type, text, startDate, spentTime, mentions = [], hasAttachments = false }, callback) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  const item = {
    key,
    value: {
      ...type && { type },
      ...text && { text },
      ...spentTime && { spentTime },
      ...startDate && { startDate },
    },
    refs: {
      toBeNotified: mentions,
    },
  }

  eventBus.dispatch(
    eventBus.type(G.NOTE, G.UPDATE),
    {
      [G.DATA]: item,
      [G.ATTACHMENT]: hasAttachments,
      [G.FN]: callback,
    },
  )
}

export default edit
