/* eslint-disable object-curly-newline */
/* global G */
import session from 'app/_shared/session'

/**
 * Returns a new Note object.
 *
 * @typedef Gaia.Model.Note
 *
 * @param {string[]} parent             the ref of the object the message is submitted from
 * @param {string[]} submitter          the ref of the user that sent the message
 * @param {string} type                 the type of note
 * @param {string} text                 the text content of the note
 * @param {string} [date]               the date for the notes of type 'postpone'
 * @param {String} [startDate]          the date of which {@param spentTime} took place as an ISO date
 *                                      string
 * @param {Number} [spentTime]          the time in seconds spent for the current task
 * @param {string[]} [team]             the team for the notes of type 'forwarded'
 * @param {string[]} [assignee]         the assignee for the notes of type 'assign/unassign'
 * @param {String[]} [mentions]         the assignee for the notes of type 'assign/unassign'
 * @param {boolean} [timeOnly=false]    whether this note is only for time tracking
 * @returns {Object}                    a new note object
 * @constructor
 */
const Note = ({
  parent,
  submitter,
  type,
  text,
  date,
  spentTime,
  startDate,
  team,
  assignee,
  mentions,
  timeOnly = false,
}) => ({
  value: {
    type,
    text,
    date,
    spentTime,
    startDate,
    timeOnly,
  },
  refs: {
    parent,
    submitter,
    team,
    assignee,
    toBeNotified: mentions,
  },
})

/**
 * Adds a note to be stored and remotely created by the note api.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component      the current action's main component
 * @param {string[]} [parent]               the id of the parent model - defaults to the id of the
 *                                          current one.
 * @param {string} [text]                   the text content of the note
 * @param {string} [type]                   the type of note
 * @param {string} [date]                   the date for the notes of type 'postpone'
 * @param {String} [spentTime]              the time in seconds spent for the current task
 * @param {String} [startDate]              the date of which {@param spentTime} took place as an
 *                                          ISO date string
 * @param {string[]} [team]                 the team for the notes of type 'forwarded'
 * @param {string[]} [assignee]             the assignee for the notes of type 'assign/unassign'
 * @param {String[]} [mentions]             the assignee for the notes of type 'assign/unassign'
 * @param {boolean} [hasAttachments=false]  whether the note as attachments
 * @param {Function} [callback]             an optional function to be called once the note is
 *                                          created
 * @returns {Object}
 */
const add = (
  module,
  component,
  {
    parent,
    text,
    type,
    date,
    spentTime,
    startDate,
    team,
    assignee,
    mentions,
    timeOnly = false,
    hasAttachments = false,
  },
  callback,
) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  const eventType = eventBus.type(G.NOTE, G.CREATE)

  const note = Note({
    parent: parent || [module[G.MODEL][G.STATE][G.REF]],
    submitter: session(module).user.key({ wrap: true }),
    type,
    text,
    date,
    spentTime,
    startDate,
    team,
    assignee,
    mentions,
    timeOnly,
  })

  eventBus.dispatch(eventType, {
    [G.DATA]: note,
    [G.ATTACHMENT]: hasAttachments,
    [G.FN]: callback,
  })

  return note
}

export default add
