/* eslint-disable arrow-body-style */
/* global G */
import { curry } from 'lib/util'

const _reverseString = string => string.split('').reverse().join('')

/**
 * Returns the serial of {@param item}.
 * @param item
 * @returns {*}
 * @private
 */
const _serial = item => item?.value?.serial || ''

/**
 * splits a {@param serial} between its initial characters and 0s and its final digits.
 * @param {string} serial   a serial to split
 * @returns {string[]|null}
 * @private
 */
const _splitSerial = serial => serial?.match(/(^[\D|0]*)(\d*)/, '')

/**
 * Checks if {@param itemSerial} matches {@param serial} by checking that both end in the same
 * numbers and checking that each remaining character from {@param serial} matches, starting from
 * the end, the remaining characters of the {@param itemSerial}. Case-insensitive.
 *
 * @param {string} serial     a serial to check {@param itemSerial} against
 * @param {string} itemSerial the serial of an item
 * @returns {boolean}         {@code true} if {@param serial} matches {@param itemSerial} according
 *                            to the described conditions
 * @private
 */
const _serialsMatch = (serial, itemSerial) => {
  const [, serialCharacters, serialNumbers] = _splitSerial(serial) || []
  const [, itemSerialCharacters, itemSerialNumbers] = _splitSerial(itemSerial) || []
  let match = !!serialNumbers && itemSerialNumbers === serialNumbers

  if (match && serialCharacters) {
    const serialArray = Array.from(serialCharacters.toLowerCase())
    const itemSerialArray = Array.from((itemSerialCharacters || '').toLowerCase())
    let serialCount = serialArray.length
    let itemSerialCount = itemSerialArray.length

    while (serialCount-- && match) {
      itemSerialCount--
      const char = serialArray[serialCount]
      const itemChar = itemSerialArray[itemSerialCount]
      match = char === itemChar
    }
  }

  return match
}

/**
 * Event Search Event Handler
 *
 * Attempts to find service items and equipments by their serial number.
 *
 * @param {string} key                              transform key (e.g. default, verbose, etc.)
 * @param {Gaia.AppModule.Spec} module              the current module composition object
 * @param {Gaia.Component.Spec} component              the current action's main component
 * @param {Gaia.PlatformEvent|string} eventOrSerial information about the event that triggered this
 *                                                  handler or a serial number
 * @param {Object} [eventOrSerial.detail]           relevant information about the event
 * @param {string} [eventOrSerial.detail.value]     a serial number
 * @return {Promise<void>}
 * @return {Promise<object>}
 */
const searchDevice = async (key, module, component, eventOrSerial) => {
  const { version } = module[G.MODEL][G.PROPS]
  const eventSerial = eventOrSerial?.detail ? eventOrSerial.detail.value : eventOrSerial
  const { term: serial = eventSerial, filter, transform } = component[G.STATE][G.META] || {}
  const url = `/api/v${version}/search/device`

  if (serial) {
    try {
      const serialReverse = _reverseString(serial)

      const params = {
        transform: transform || {
          key,
        },
        filter: {
          serialReverse,
          ...filter,
        },
      }

      const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
      const result = await remoteAdapter.post({ url, params })
      /**
       * The server returns items whose serial contain ours. From them, we want just those items
       * whose serial numbers match our serial according to the rules described in
       * {@link _serialsMatch}
       */
      return result.value.filter(item => _serialsMatch(serial, _serial(item)))
    } catch (e) {
      console.error('search:device:: tried and failed getting remote resources', url)
    }
  }

  return []
}

export default curry(searchDevice)
