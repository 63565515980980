/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import { asyncPipeSpread, bulk } from 'lib/util'
import { get } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'
import find from 'lib/sequence/component/children/find'
import { setData } from 'lib/sequence/model/api/set'
import checkModelErrorAndUpdateState from 'app/admin/action/approve/partials/submit/checkModelErrorAndUpdateState'

const display = x => (get(x) ? show(x) : hide(x))
const bulkDisplay = bulk(display)

/**
 * Deduces the required missing properties after validation and sets them as the model's data.
 *
 * @param {Gaia.Component.Spec} module current module
 * @returns {*}
 */
const composeOrganisation = module => async (components, ...args) => {
  const [organisation, { duplicateOf }] = args

  const parentModel = module[G.MODEL]
  const model = parentModel[G.CHILDREN].organisation
  const validationError = parentModel[G.STATE][G.ERROR] || model[G.STATE][G.ERROR]

  !validationError && setData(model, { duplicateOf: duplicateOf || null })

  return [components, ...args]
}

/**
 * Admin Action Submit Organisation
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  composeOrganisation(module),
  checkModelErrorAndUpdateState(null)(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
