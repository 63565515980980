/* global G */
import { isArr } from 'lib/util'

/**
 * Translates the value of an {@param item}'s prop.
 *
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} t            an object specifying the items' translation path
 * @param {Object} item         the item to translate
 * @param {string} name         the name of the {@param item}'s prop to translate
 * @returns {*}
 */
export const translateItemProp = (obj, t, item, name) => {
  const _key = `${t._key}.${item.key}.${name}`
  const defaultValue = item[name]
  return t ? obj[G.ADAPTER][G.INTL]._t(_key, { ns: t.ns, _key, defaultValue }) : defaultValue
}

/**
 * From Message Type Decorator
 *
 * @param {string} key              the name of the attribute to store the data to
 * @param {Object[]} list           an array of items each with a key and a value properties
 * @param {string|number} list.key  the unique identifier of a list's element
 * @param {string} list.value       the value to be set as the cache if its current value matches
 *                                  the item's key
 * @param {Object} [t]              an object specifying the items' translation path
 */
const fn = (key, list, t) => (obj, component) => {
  const source = obj[G.CHILDREN][key]
  const cache = source[G.CACHE]
  // if it already is an array, it means it has been already decorated
  cache
  && !isArr(cache)
  && (source[G.CACHE] = list.filter(entry => entry.key === cache).map(entry => ({
    ...entry,
    value: translateItemProp(obj, t, entry, 'value'),
    labelText: translateItemProp(obj, t, entry, 'labelText'),
    backgroundText: translateItemProp(obj, t, entry, 'backgroundText'),
    proposedText: translateItemProp(obj, t, entry, 'proposedText'),
  })))

  return component
}

export default fn
