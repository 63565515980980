/* global G */
/* eslint-disable no-unused-vars */
import { arrayOf, curry, setKey } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Set At Org Selection
 *
 * Sets the `selection` prop of the `newAtOrganisation` field to the
 * currently selected organisation.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        the event object that triggered this handler
 */
const setAtOrgSelection = (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { newAtOrganisation } = sequenceComponentFindProxy(actionComponent)

  const { item } = event?.detail || event || {}
  const selection = item ? arrayOf(item) : []

  setKey(selection, 'selection', newAtOrganisation[G.STATE])
}

export default curry(setAtOrgSelection)
