/* eslint-disable object-curly-newline,no-undefined,no-unused-vars */
/* eslint-disable no-unused-expressions,object-curly-newline */
/* global G */
import { asyncPipeSpread, getFirstItem } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { get, set } from 'lib/sequence/component/state/value'
import session, { settings } from 'app/_shared/session'
import swapListSerialAndName from 'app/_shared/action/partial/swapListSerialAndName'
import listTicketFilterStatus from 'app/_shared/events/collection/listTicketFilterStatus'
import { hide } from 'lib/sequence/component/state/hidden'

/**
 * Returns the minimum date value according to the selected option.
 *
 * @param {string} value        the selected option's value
 * @returns {{minDate: string}} an object containing the minimum date
 */
const toDateString = (value) => {
  const date = new Date()

  switch (value) {
    // case '5m':
    //   date.setMinutes(date.getMinutes() - 5)
    //   break
    case '1h':
      date.setHours(date.getHours() - 1)
      break
    case '24h':
      date.setDate(date.getDate() - 1) // today ???
      break
    case '48h':
      date.setDate(date.getDate() - 2) // yesterday
      break
    case '7d':
      date.setDate(date.getDate() - 7)
      break
    default:
    // current timestamp
  }
  return { minDate: date.toISOString() }
}

/**
 * Returns the min / max filter.
 *
 * @param {number} min  the minimum value
 * @param {number} max  the maximum value
 * @returns {Object}
 */
const toMinMax = ({ min, max }) => {
  const result = {}
  min && Object.assign(result, { min })
  max && Object.assign(result, { max })
  return result
}

/**
 * Returns the ref of {@code item}, if it has one.
 *
 * @param {Object} item     the item to return the ref from
 * @returns {*|undefined}   either the ref of the item or undefined
 */
const ref = item => item?.[0]?.key || undefined

/**
 * Returns either the key of {@param item} or the values that should correspond to the currently
 * selected static filters.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @param {Object} item     the item to return the ref from
 * @returns {*|undefined}   either the ref of the item or undefined
 */
const filterRef = (module, item) => {
  const key = ref(item)
  const meKey = key === 'me' ? session(module).user.key() : key
  const noneMeKey = key === 'none' ? [] : meKey
  return !key || key === 'any' ? undefined : noneMeKey
}

/**
 * Shows/hides filter fields according to the {@link supressedTicketFilters} setting.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayFilters = module => async (children, ...args) => {
  const { filter } = children
  const availableFilters = asObject(filter[G.CHILDREN])

  const { suppressedTicketFilters = [] } = settings

  suppressedTicketFilters.forEach((suppressedFilter) => {
    availableFilters?.[suppressedFilter] && hide(availableFilters[suppressedFilter])
  })

  return [children, ...args]
}

/**
 * Sets the status filter according to the selected value.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*, {filter?: {}}=, ...[*]): Promise<[undefined,{filter: {}},...*[]]>}
 */
const setStatusFilter = module => component => async (children, { filter = {} } = {}, ...args) => {
  const { status } = children

  const accumulatedFilter = filter
  const selectedStatus = parseInt(get(status), 10)
  const statusSelection = listTicketFilterStatus(module, component, null)
  const statusOption = statusSelection.find(option => option.key === selectedStatus)
  statusOption?.key && (accumulatedFilter.workState = statusOption.key)

  return [children, { filter: accumulatedFilter }, ...args]
}

/**
 * Sets the team filter according to the selected value.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*, {filter?: {}}=, ...[*]): Promise<[undefined,{filter: {}},...*[]]>}
 */
const setTeamFilter = module => async (children, { filter = {} } = {}, ...args) => {
  const { team } = children

  const accumulatedFilter = filter
  const selectedTeam = get(team)
  accumulatedFilter.team = filterRef(module, selectedTeam)

  return [children, { filter: accumulatedFilter }, ...args]
}

/**
 * Sets the assignee filter according to the selected value.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*, {filter?: {}}=, ...[*]): Promise<[undefined,{filter: {}},...*[]]>}
 */
const setAssigneeFilter = module => async (children, { filter = {} } = {}, ...args) => {
  const model = module[G.MODEL]
  const { assignee } = children

  const accumulatedFilter = filter
  const selectedAssignee = get(assignee)

  assignee[G.STATE][G.REF] = !!accumulatedFilter.team && 'team'
  model[G.CHILDREN].team[G.STATE][G.REF] = accumulatedFilter.team

  !get(assignee) && set(assignee, [])

  const noAssignee = getFirstItem(selectedAssignee)?.key === 'none'
  noAssignee
    ? accumulatedFilter.refexists = '!assignee'
    : accumulatedFilter.assignee = filterRef(module, selectedAssignee)

  return [children, { filter: accumulatedFilter }, ...args]
}

/**
 * Sets the date created filter according to the selected value.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*, {filter?: {}}=, ...[*]): Promise<[undefined,{filter: {}},...*[]]>}
 */
const setDateCreatedFilter = module => async (children, { filter = {} } = {}, ...args) => {
  const { dateCreated } = children

  const accumulatedFilter = filter
  const created = get(dateCreated)
  created && created !== '0' && (accumulatedFilter.submit_timestamp = toDateString(created))

  return [children, { filter: accumulatedFilter }, ...args]
}

/**
 * Sets the submitter role filter according to the selected value.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*, {filter?: {}}=, ...[*]): Promise<[undefined,{filter: {}},...*[]]>}
 */
const setSubmitterRoleFilter = module => async (children, { filter = {} } = {}, ...args) => {
  const { submitterRole } = children

  const accumulatedFilter = filter
  const selectedSubmitterRole = getFirstItem(get(submitterRole))?.key || null
  if (selectedSubmitterRole) {
    selectedSubmitterRole === 'none'
      ? (delete accumulatedFilter.submit_role)
      : (accumulatedFilter.submit_role = selectedSubmitterRole)
  }

  return [children, { filter: accumulatedFilter }, ...args]
}

/**
 * Applies the accumulated filters by mutating the `list`'s `G.META` and
 * recreates the view.
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*, {filter?: {}}=, ...[*]): Promise<*[]>}
 */
const applyFilters = module => async (children, { filter = {} } = {}, ...args) => {
  const { list } = children

  const meta = list[G.STATE][G.META] || {}

  // Reset pagination
  list[G.STATE][G.NEXT] = undefined
  list[G.STATE][G.META] = { ...meta, filter }
  // temporary solution for suspended memoized list component
  // recreate ui, instead of update ui
  await module[G.ADAPTER][G.UI].create(module)

  return args
}

/**
 * Ticket Index Action
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  displayFilters(module),
  swapListSerialAndName(module),
  setStatusFilter(module)(component),
  setTeamFilter(module),
  setAssigneeFilter(module),
  setDateCreatedFilter(module),
  setSubmitterRoleFilter(module),
  applyFilters(module),
)(sequenceComponentFind(component), ...args)
