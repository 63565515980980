/* eslint-disable no-unused-vars,implicit-arrow-linebreak */
/* global G */
import { curry } from 'lib/util'

/**
 * Concatenates the results of executing all queries, separated by 'AND', in a single string.
 *
 * @param {...Function} queries           query event handlers
 * @returns {Gaia.AppModule.EventHandler} this event handler
 * @return {string}                       the results of the passed queries, concatenated
 */
export default (...queries) => curry((module, component, event) => {
  event?.persist?.()

  const statements = queries.map(query => query(module, component, event)).filter(query => !!query)

  return statements.join(' AND ')
})
