/* eslint-disable no-unused-vars */
/* global G */
import modelReset from 'lib/sequence/model/api/reset'
import { curry, setKey } from 'lib/util'

/**
 * Resets the current model or one of its children, depending on whether a child name {@param key}
 * is passed.
 *
 * @param {string|null} [key]          the name of the model's property the child model is stored at
 */
export default key => curry(async (module, component, event) => {
  const model = key ? module[G.MODEL][G.CHILDREN][key] : module[G.MODEL]
  setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  // clearing currently stored data
  modelReset(model)
})
