/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import concatQueryParams from 'app/_shared/events/search/query/util'
import internal from 'model/organisation/collection/internal'

/**
 * Returns a search string that includes internal orgs and the current org from the result.
 *
 * @param {string} [attribute]              name of the sub-model to use, will default to the main
 *                                          model if not defined
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 *
 * @returns {string}
 */
const noInternalOrgsAndNotMyself = (attribute, module, component, event) => {
  const model = attribute
    ? module[G.MODEL][G.CHILDREN][attribute]
    : module[G.MODEL]

  const orgId = model?.[G.STATE]?.[G.REF] || null
  const internalOrganisationsId = internal.map(x => x.key.replaceAll(':', '\\:'))

  const params = {
    type: 'metatype:organisation',
    notInternal: `AND NOT tree_parent:(${internalOrganisationsId.join(' OR ')})`,
    ...orgId && { notMyself: `AND NOT id:(${orgId.replaceAll(':', '\\:')})` },
  }

  return concatQueryParams(params)
}

export default curry(noInternalOrgsAndNotMyself)
