/* global G */
import { cancelRedirect, setRoute } from 'app/_shared/action/util'

/**
 * validateRedirect Action.
 *
 * Validates the model state and sets the route
 *
 * @param {Object} module the current module object
 * @return {function(): function(*, ...[*]): *[]}
 */
const actionFn = module => () => (route, ...args) => {
  setRoute(module)(route)
  args.push(module[G.MODEL][G.DATA])
  !!module[G.MODEL][G.STATE][G.ERROR] && cancelRedirect(module)

  return args
}

export default actionFn
