/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Get Merged Tree Event Handler
 *
 * Retrieve the tree from the action state. Expects an array of multiple trees and merges
 * them into one.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        the event object that triggered this handler
 * @returns {Promise<*>}
 */
const getMergedTree = async (module, component, event) => {
  const { tree } = module[G.STATE][G.ACTION][G.STATE] || {}

  const mergedChildren = tree.reduce(
    (acc, key) => [
      ...acc,
      ...key?.value?.$children ? key.value.$children.reduce(
        (agg, item) => [...agg, ...item?.$children ? item.$children : []],
        [],
      ) : [],
    ],
    [],
  )

  const [rootTree] = tree?.[0]?.value?.$children || [{}]
  rootTree.$children = mergedChildren

  return { $children: [rootTree] }
}

export default curry(getMergedTree)
