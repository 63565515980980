/* global G */
import { curry, isStr } from 'lib/util'

const descriptor = 'model::transformer::toRef'

/**
 * Angrily convert the whole payload to key string
 * @todo: rename to toSingleRef and also refactor usage.
 * BASED ON SET STATE REF
 * SINGLE REF
 *
 * Example: { key: 'foo', value: {...}, refs: {...}, _rev: 'x'} to 'foo'
 *
 * @param {string} key - key, expected to be provisioned in composition
 * @param {Gaia.Model.Spec} obj - model, provided by sequence call
 * @param {Object} data - data, provided by sequence call
 * @return {*}
 */
const fn = (key, obj, data) => {
  console.warn(descriptor)
  const _data = data
  const item = obj[G.CHILDREN][key]
  const itemState = item[G.STATE]
  const { type } = item[G.PROPS]

  try {
    const modelData = obj[G.STATE][G.DATA][type]?.[key]
    // const modelCache = obj[G.CACHE]?.[type]?.[key]?.[0]

    const dataKey = modelData && isStr(modelData)
      ? modelData
      : modelData[0] && (isStr(modelData[0])
        ? modelData[0] : modelData[0].key)
    const { _id, key: modelKey } = itemState ? itemState[G.DATA] || {} : {}
    const ref = _id || modelKey || (itemState ? itemState[G.REF] : dataKey)

    ref
    && (_data[type] = _data[type] || {})
    && (_data[type][key] = [ref])
    !ref && delete _data[type][key]
    // _data[type] = _data[type] || {}
    // _data[type][key] = ref ? [ref] : []
    //
    // !ref && !modelCache && delete _data[type][key]
    ref
    && (_data[type] = _data[type] || {})
    && (_data[type][key] = [ref])
    !ref && delete _data[type][key]
  } catch (e) {
    console.warn(descriptor, obj._name, key, e.message)
  }
  return _data
}

export default curry(fn)
