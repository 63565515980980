/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { openModalWith } from 'app/_shared/events/modal/open'

/**
 * Toggle Favorite From Attachment Handler
 *
 * Wraps {@link toggle} by giving it the `payload` and `type` of the attachment contained in
 * `target`'s state. `target` should be the component rendering the attachment.
 *
 * @param {string} target                 name of the attachment component
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      the event object that triggered this handler
 * @returns {Promise<void>}
 */
const toggleFeedbackFromAttachment = async (target, module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { [target]: targetComponent } = sequenceComponentFindProxy(actionComponent)
  const targetComponentState = targetComponent[G.STATE]

  const {
    attachment: {
      documentationId: payload = null,
      value: { parentType = null } = {},
    } = {},
  } = targetComponentState || {}

  const type = parentType
    || (!!payload && 'documentation')
    || 'attachment'

  await openModalWith('feedbackModal', module, component, { ...event, detail: { payload, options: { type } } })
}

export default curry(toggleFeedbackFromAttachment)
