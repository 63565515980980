/* global G */
import { minLength, specialCharacter, notEmpty } from 'model/_shared/validator'

/**
 *
 * Model Requirements Validator
 *
 * validates data values on model level, propagating
 * to the same scope as children validators
 *
 * @param {Object} obj  the model object composition
 * @return {Object}     the call's arguments
 */
export default obj => async (uiState, key) => {
  const { options: { target, error } } = obj[G.CONFIGURATION].validator[key]

  const { [target]: value } = obj[G.DATA]
  const objState = obj[G.STATE]
  const errors = {}
  const validators = { notEmpty, minLength, specialCharacter }
  Object.keys(validators).map((name) => {
    try {
      validators[name](value, error[name])
    } catch (e) {
      errors[name] = e.message
      objState[G.ERROR] = { ...objState[G.ERROR], [key]: errors }
    }
    return name
  })
  return [uiState, key]
}
