/* eslint-disable no-param-reassign,no-restricted-syntax */
/* global G */
import { curry, setKey } from 'lib/util'
import structuredClone from '@ungap/structured-clone'
import fetchTree from 'app/_shared/events/file/tree/fetchTree'

/**
 * Get Documentation Tree Event Handler
 *
 * Fetches the given documentation tree if it hasn't already been fetched. It'll first
 * look into the `actionState`. If a tree is present there, it'll return it.
 * If not, it'll look into the `moduleState`, if a tree is present here, it'll copy
 * a deepClone of it to the actionState and return it.
 *
 * If it's not in the module state either, it'll fetch the tree, save it to the module
 * state, copy a deep clone of it to the action state and return it.
 *
 * NOTE: It will return a {@link structuredClone} of the tree, because the return value
 * will be mutated by other event handlers (such as {@code filter}). We mustn't mutate
 * the original object (saved into the module state), because depending on what component
 * calls this handler, the mutation done by {@code filter} might be different.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {PlatformEvent} event         information about the event that triggered this handler
 * @returns Object
 */
const getDocumentationTree = async (module, component, event) => {
  const moduleState = module[G.STATE]
  const actionState = moduleState[G.ACTION][G.STATE]

  const { tree: actionTree = null } = actionState

  if (actionTree) {
    return actionState
  }

  try {
    // This is the full tree we first fetched and saved to the module state
    const moduleTree = moduleState?.tree ? moduleState.tree : null

    // If we neither have {@code moduleTree} or {@code cachedTree}, we need to fetch
    // the tree, we will save it into the module state, so we don't have to fetch it again
    const result = !moduleTree
    // skipKey tells the handler to not use model[G.STATE][G.REF], even if defined
      ? await fetchTree(module, component, { ...event, detail: { skipKey: true } })
      : null
    const fetchedTree = result?.$children ? result : { $children: result, type: 'Directory' }

    if (!moduleTree && fetchedTree) {
      setKey(fetchedTree, 'tree', moduleState)
    }

    const clonedTree = structuredClone(moduleTree || fetchedTree)

    // Saving the tree in the action state
    setKey(clonedTree, G.STATE, moduleState[G.ACTION])

    // We need to deep clone the object. We'll pass a reference to the original object to the filter
    // handler, and it will mutate it. We don't want that, we want to work with a copy of it.
    return structuredClone(clonedTree)
  } catch (e) {
    console.error(e)
    return { $children: [], type: 'Root' }
  }
}

export default curry(getDocumentationTree)
