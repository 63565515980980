/* global G */
import { cancelRedirect, setRoute } from 'app/_shared/action/util'

/**
 * validateRedirect Action.
 *
 * Validates the model state and sets the route
 *
 * @param {Object} module the current module object
 * @return {function(): function(*, ...[*]): *[]}
 */
const actionFn = module => () => (route, ...args) => {
  route && setRoute(module)(route)

  const { username, password } = module[G.ADAPTER][G.SESSION][G.STATE]

  const additionalArgs = {
    ...module[G.MODEL][G.DATA],
    ...username && { username },
    ...password && { password },
  }

  args.push(additionalArgs)

  !!module[G.MODEL][G.STATE][G.ERROR] && cancelRedirect(module)
  return args
}

export default actionFn
