/* eslint-disable no-unused-vars */
/* global G */
import { hide, show } from 'lib/sequence/component/state/hidden'
import { asyncPipeSpread, bulk, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { set } from 'lib/sequence/component/state/value'

/**
 * Save the current message to the module state so that the button's onClick
 * handler can access it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const saveMessageToActionState = module => async (components, ...args) => {
  const { detail: message = {} } = args[0]

  setKey(message, 'currentMessage', module[G.STATE][G.ACTION][G.STATE])

  return [components, ...args]
}

/**
 * Presets the message field with the text.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetMessageField = module => async (components, ...args) => {
  const { detail: message = {} } = args[0]
  const { messageField } = components

  set(messageField, message?.value?.text)

  return args
}

/**
 * Ticket Edit Message Action
 *
 * @param {Gaia.AppModule.Spec} module the ticket module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => await asyncPipeSpread(
  saveMessageToActionState(module),
  presetMessageField(module),
)(find(component), ...args)
