/* eslint-disable no-unused-expressions */
/* global G */
import claim from 'app/ticket/event/quick/claim'
import { get } from 'lib/sequence/component/state/value'
import { add as addNote } from 'app/_shared/events/note/index'
import sequenceComponentState from 'lib/sequence/component/state'
import validate from 'lib/sequence/model/validate'
import sequenceComponentFind from 'lib/sequence/component/children/find'

const {
  set: error,
} = sequenceComponentState('error')

/**
 * Verifies the reopen ticket form and returns the payload used to assign the ticket to the current user.
 *
 * @type QuickPayload
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @returns {Object}
 */
export default async (module, component) => {
  const model = module[G.MODEL]
  const { content, note } = sequenceComponentFind(component)

  await validate(model)(content)
  model[G.STATE][G.ERROR] && throw Error('Model validation error')

  const noteText = get(note)

  noteText && noteText.length && addNote(module, component, {
    text: noteText,
    type: 'reopen',
  })

  return claim(module, component)
}
