/* eslint-disable no-unused-vars */
/* global G */
import { curry, getFirstItem, isObj } from 'lib/util'
import internalOrganisations from 'model/organisation/collection/internal'

/**
 * Search Sub Organisations Or Get Parent
 *
 * If the current organisation is a root or, meaning it has {@code ORGANISATION:RA} as a
 * parent, it will return a list of saved sub organisation from the module state.
 *
 * If not, it will return its parent in an array
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {*[]}
 */
const getSubLocationsOrParent = (module, component, event) => {
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const parent = getFirstItem(organisation[G.CACHE]?.refs?.parent)

  // If the parent org is ORG:RA, we're viewing the root requester org and need to
  // provide a list of the sub locations
  if (internalOrganisations.some(x => x.key === parent?.key)) {
    const { subOrganisations = [] } = module[G.STATE]

    return subOrganisations
  }

  // Otherwise, we're already viewing a sub location, so just return its parent
  // this way, the user can switch between sub locations and the root org
  // (a sub location can't have another sub location)
  return [parent]
}

export default curry(getSubLocationsOrParent)
