/* global G */
/* eslint-disable object-curly-newline,prefer-destructuring,no-unused-vars,no-extra-parens */
import { asyncPipeSpreadIf, def } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import { get, set } from 'lib/sequence/component/state/value'
import { show } from 'lib/sequence/component/state/hidden'
import { checked } from 'lib/sequence/component/state/checked'
import { settings } from 'app/_shared/session'
import initOrganisationType from 'app/_shared/action/partial/initOrganisationType'
import processRequestType from 'app/_shared/action/partial/processRequestType'
import toggleDeviceInstalledAt from 'app/_shared/action/partial/toggleDeviceInstalledAt'
import initSerialImage from 'app/_shared/action/partial/initSerialImage'
import initSerialField from 'app/_shared/action/partial/initSerialField'
import listLanguages from 'app/_shared/events/collection/listLanguages'
import listCountries from 'app/_shared/events/collection/listCountries'

/**
 * If the URL has a third parameter, displays the form in its extended variant.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const toggleExtendedForm = module => async (components, ...args) => {
  const { [G.REF]: ref, key = ref } = args[0] || {}
  const showExtendedForm = key || settings.forceExtendedRegistration

  showExtendedForm && show(components.requestTypeForm)

  return [components, ...args]
}

/**
 * Initializes the language field with the current language.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const initLanguage = module => async (components, ...args) => {
  const { lang } = components

  if (!get(lang)) {
    const iso = module[G.ADAPTER][G.INTL].get().language
    const languages = listLanguages(module, null, null)
    const currentLanguage = languages.find(language => language.key === iso)
    set(lang, [currentLanguage])
  }

  return [components, ...args]
}

/**
 * If provided, sets the username passed as argument as the value of the account field.
 *
 * @param {Object} components the find component sequence initialized with the action's component
 * @param {Object[]} args     the action's arguments
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
export const initUsername = async (components, ...args) => {
  const { username } = args[0] || {}

  if (username) {
    const { account } = components
    !def(get(account)) && set(account, username)
  }

  return [components, ...args]
}

/**
 * Obtains the list of translated countries and sets the initial selection.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const initCountry = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { country, deviceInstalledAt, sameLocation } = components
  const { address: deviceAddress } = asObject(deviceInstalledAt[G.CHILDREN])
  const { country: deviceCountry } = asObject(deviceAddress[G.CHILDREN])

  if (!def(get(country))) {
    try {
      const countries = listCountries(module, null, null)
      const { country_code: countryCode } = await model[G.ADAPTER][G.HTTP].get({
        url: '/api/v1/public/ipinfo',
      }, { middleware: ({ persistence }) => [persistence] })

      const selectedCountry = countries.filter(i => i.key === countryCode)
      countryCode && set(country, selectedCountry)
    } catch (ignored) {}
  }

  // Copy value from country to deviceCountry if not set already
  !checked(sameLocation)
  && def(get(country))
  && set(deviceCountry, get(country))

  return [components, ...args]
}

/**
 * Register action
 *
 * Initializes and controls the action's business logic.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => (
  asyncPipeSpreadIf(() => !settings.externalPortalUrl)(
    initCountry(module),
    initUsername,
    initLanguage(module),
    initOrganisationType,
    processRequestType(module, true),
    toggleExtendedForm(module),
    initSerialField(module),
    initSerialImage(module),
    toggleDeviceInstalledAt,
  )(find(component), ...args)
)
