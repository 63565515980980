/* eslint-disable no-param-reassign */
/* global G */
import { curry } from 'lib/util'
import session from 'app/_shared/session'
import asObject from 'lib/sequence/component/children/asObject'
import { show } from 'lib/sequence/component/state/hidden'
import { set } from 'lib/sequence/component/state/value'

/**
 * Determines the desired partner of the last message
 * based on the submitter and sets the assignee
 * (partner) of the request to it.
 *
 * The logic is: The partner of "other side" is
 * relevant.
 * - Am I submitter (source) ? Take targetPartner
 * - Am I recipient (target) ? Take sourcePartner
 *
 * @param {Object} messages
 * @param module
 * @param component
 * @param event
 * @returns {Promise<*>}
 */
// eslint-disable-next-line no-unused-vars
const setPartner = curry(async (messages, module, component, event) => {
  const { user } = session(module)
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { request } = asObject(actionComponent[G.CHILDREN])
  const { info } = asObject(request[G.CHILDREN])
  const { assigneeBox } = asObject(info[G.CHILDREN])
  const { assigneeInfo } = asObject(assigneeBox[G.CHILDREN])
  const { assignee } = asObject(assigneeInfo[G.CHILDREN])
  const { person: assigneePerson } = asObject(assignee[G.CHILDREN])
  const { name } = asObject(assigneePerson[G.CHILDREN])

  /**
   * Just search the last message, the information is
   * available in any message anyway. If there are
   * no messages for whatever reason, set partner
   * to {@code '-'}.
   */
  const submitter = messages[0]?.refs.submitter?.[0]
  const relevantObject = user.key() !== submitter ? 'sourcePartner' : 'targetPartner'
  const partner = messages[0]?.value[relevantObject] || '-'

  set(name, partner)
  show(assigneeBox)
  module[G.ADAPTER][G.UI].update(module)

  return messages
})

export default setPartner
