/* global G */
/* eslint-disable no-unused-vars */
import { curry, getFirstItem } from 'lib/util'
import concatQueryParams from 'app/_shared/events/search/query/util'
import internal from 'model/organisation/collection/internal'

/**
 * Return a search query that filters the servers response to only includes
 * suggested items that may be an original for the current organisation.
 *
 * NOTE: Will also return certain service items, since their {@code installedAt}
 * organisation may also be included in the suggestions. Howeever, the event handler
 * that called this function needs to map over the service items to get the
 * organisation itself. See {@link infiniteCandidates}.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 *
 * @returns {string}
 */
const isCandidate = (module, component, event) => {
  const { organisation = {} } = module[G.MODEL][G.CHILDREN]
  const orgId = organisation?.[G.STATE]?.[G.REF] || null
  const internalOrganisationsId = internal.map(x => x.key.replaceAll(':', '\\:'))

  // address
  const { value: { address: { zip = null, country = null } = {} } = {} } = organisation[G.CACHE]

  // equipment
  const { subServiceItems = [] } = module[G.STATE]
  const ownItems = subServiceItems.filter((x) => {
    const installedAt = getFirstItem(x.refs.installedAt)
    return installedAt.key === orgId
  })

  const itemData = ownItems.reduce((acc, key) => {
    const equipment = getFirstItem(key.refs?.equipment)
    equipment?.key && acc.equipment.push(equipment.key)
    key?.value?.serial && acc.serial.push(key.value.serial)

    return acc
  }, { equipment: [], serial: [] })

  const hasIds = itemData.equipment?.length
  const hasSerials = itemData.serial?.length
  const hasItemData = hasIds || hasSerials

  const itemDataStrings = Object.keys(itemData).reduce((acc, key) => {
    const target = acc[key].map(x => x.replaceAll(':', '\\:')).join(' OR ')
    acc[key] = `${key}:(${target})`
    return acc
  }, itemData)

  const params = {
    type: 'metatype:(organisation OR serviceitem)',
    notInternal: `AND NOT tree_parent:(${internalOrganisationsId.join(' OR ')})`,
    and: 'AND (',
    sameCountryAndZip: `(address_country:"${country}" AND address_zip:"${zip}")`,
    ...hasItemData && {
      // resulting string: (serial:(13 OR 14 OR ...) OR equipment:(EQ:\\1 OR EQ:\\2 OR ...))
      sameEquipmentOrSerial: `OR (${Object.values(itemDataStrings).join(' OR ')})`,
    },
    end: ')',
  }

  return concatQueryParams(params)
}

export default curry(isCandidate)
