/* global G */

/**
 * List of document types that should be ignored when bulk validating
 * @type {string[]}
 */
export const ignoredValidationDocTypes = [
  'request',
  'ticket',
]

/**
 * Maps each ref to its key
 *
 * @param {Object[]} target list of refs
 * @returns {{}}
 */
export const mapRefs = target => Object.keys(target).reduce((acc, key) => {
  const refValue = target[key]
  acc[key] = refValue.map(x => (typeof x === 'string' ? x : x.key))
  return acc
}, {})

/**
 * Composes the payload for every sub item of the current organisation by setting their
 * {@code toBeValidated} property to {@code null}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<[{payload},undefined,...*[]]>}
 */
const composePayloadSubItems = module => async (components, ...args) => {
  const moduleState = module[G.STATE]
  const { data = [] } = moduleState

  let payload

  if (!moduleState[G.ERROR] && data.length) {
    payload = data.reduce((acc, key) => {
      // We do not want to modify tickets or requests at all, the server will do that
      if (!ignoredValidationDocTypes.includes(key?.value?.docType)) {
        acc.push({
          type: key.value?.docType,
          _id: key.key,
          _rev: key._rev,
          refs: mapRefs(key.refs),
          value: {
            ...key.value,
            type: undefined,
            toBeValidated: null,
          },
        })
      }
      return acc
    }, [])
  }

  return [{ payload }, components, ...args]
}

export default composePayloadSubItems
