/* global G */
import { curry } from 'lib/util'

const descriptor = 'model::transformer::flatten'

/**
 * Flatten Sub-Model into Data Model
 *
 * It delegates data found in sub-model (item) to the data model (obj) attributes
 * if attribute key exists in data model
 *
 * The data should have been gone through validate(), create(), toValue() sequences
 * and should be found in data model's state data symbol.
 *
 * It deletes sub-model entry from data, as it is used as a helper to aggregate data, ie intern use
 *
 * @param {string} key - key, expected to be provisioned in composition
 * @param {Gaia.Model.Spec} obj - model, provided by sequence call
 * @param {Object} data - data, provided by sequence call
 * @return {*}
 */
const fn = (key, obj, data) => {
  // console.warn(descriptor)

  const _data = data
  try {
    const item = obj[G.CHILDREN][key]
    const objChildrenKeys = Object.keys(obj[G.CHILDREN])

    Object.keys(item[G.CHILDREN]).reduce((acc, _key) => {
      const { type } = obj[G.CHILDREN][_key][G.PROPS]
      const createdValue = item[G.STATE][G.DATA][type][_key]
      objChildrenKeys.includes(_key) && (
        acc[type][_key] = createdValue // || item[G.DATA][_key] || null
      )
      return acc
    }, _data)

    delete _data[item[G.PROPS].type][key]
  } catch (e) {
    console.warn(descriptor, obj._name, obj[G.CHILDREN][key]._name, e.message)
  }
  return _data
}

export default curry(fn)
