/* global G */
import { isArr } from 'lib/util'
import { translateItemProp } from './fromMessageType'

/**
 * From Message Type Name Decorator
 *
 * @param {string} sourceKey        the name of the attribute to retrieve the data from
 * @param {string} targetKey        the name of the attribute to store the data to
 * @param {Object[]} list           an array of items each with a key and a value properties
 * @param {string|number} list.key  the unique identifier of a list's element
 * @param {string} list.value       the value to be set as the cache if its current value matches
 *                                  the item's key
 * @param {Object} t                an object specifying the items' translation path
 */
const fn = (sourceKey, targetKey, list, t) => (obj, component) => {
  const source = obj[G.CHILDREN][sourceKey]
  const target = obj[G.CHILDREN][targetKey]
  const cache = source[G.CACHE]
  // if it is an array, it means it has been already decorated and its first item is what we want
  const item = isArr(cache) ? cache[0] : list.find(entry => entry.key === cache)
  target[G.CACHE] = item ? translateItemProp(obj, t, item, 'value') : cache
  return component
}

export default fn
