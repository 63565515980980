/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import { asyncPipeSpread, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { setData } from 'lib/sequence/model/api/set'
import validateUserRole from 'app/admin/action/approve/partials/submit/validateUserRole'
import composePayloadSubItems from 'app/admin/action/approve/partials/submit/composePayloadSubItems'
import submitUser from 'app/admin/action/approve/partials/submit/submitUser'
import submitPayload from 'app/admin/action/approve/partials/submit/submitPayload'
import checkModelErrorAndUpdateState from 'app/admin/action/approve/partials/submit/checkModelErrorAndUpdateState'

/**
 * Composes the payload for the organisation.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const composeOrganisation = module => async (components, ...args) => {
  const parentModel = module[G.MODEL]
  const model = parentModel[G.CHILDREN].organisation
  const modelState = model[G.STATE]
  const validationError = parentModel[G.STATE][G.ERROR] || model[G.STATE][G.ERROR]

  setKey(true, 'verified', modelState)

  !validationError && setData(model, {
    parent: null,
  })

  return args
}

/**
 * Admin Action Submit As Original Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  validateUserRole(module),
  checkModelErrorAndUpdateState('user')(module),
  composePayloadSubItems(module),
  submitPayload(true)(module),
  submitUser(true)(module),
  composeOrganisation(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
