/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import { get as getMessage } from 'app/_shared/events/message'

/**
 * Get Message Attachments From Attachments Group Event Handler
 *
 * Gets all the attachments related to messages from the `attachmentsGroup` defined
 * in the action component and returns them.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @returns {Promise<unknown>}
 */
const getMessageAttachmentsFromAttachmentsGroup = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { attachmentsGroup = null } = actionComponent[G.PROPS]
  const { [G.EVENTS]: eventBus } = module[G.ADAPTER]

  // Getting the current message
  const { currentMessage: { key: currentMessageId = null } = {} } = module[G.STATE][G.ACTION][G.STATE]
  const message = await getMessage(module, component, { key: currentMessageId })
  const { value: { attachments = [] } = {} } = message

  // Asking the parent group to give us all the attachments of the current message
  const data = !attachmentsGroup ? [] : await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.ATTACHMENT, G.DONE, attachmentsGroup), (attachmentEvent) => {
      const ticketAttachments = attachmentEvent.detail[G.DATA]
      const messageAttachments = ticketAttachments
        .filter(tAtt => attachments.findIndex(mAtt => mAtt.key === tAtt.key) > -1)
      resolve(messageAttachments)
    }, { once: true })
    eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.CACHE, attachmentsGroup), { [G.DATA]: {} })
  })

  return data?.length
    ? data
    : []
}

export default curry(getMessageAttachmentsFromAttachmentsGroup)
