/* global G */
import { curry } from 'lib/util'

const descriptor = 'model::transformer::toUserRoles'

/**
 * To users' roles Transformer
 *
 * Transforms a userRoles object to a roles array.
 *
 * @example
 * const userRoles = {
 *  0: { role: "system.active" },
 *  1: { role: "Customer", atOrg: "ORG:1" },
 *  2: { role: "Requester", atOrg: "ORG:2", delete: true }
 *}
 *
 * @example
 * const roles = [ "system.active", "Customer@ORG:1" ]
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} data         the payload data processed until now
 * @return {Object}             the payload data
 */
const fn = (key, obj, data) => {
  console.warn(descriptor, key)

  const _data = data
  const attribute = obj[G.CHILDREN][key]
  const attributeData = attribute[G.DATA]?.value

  _data.value[key] = Object.keys(attributeData).reduce((acc, index) => {
    if (!attributeData[index]?.delete) {
      acc.push(
        !attributeData[index]?.atOrg
          ? attributeData[index].role
          : `${attributeData[index].role}@${attributeData[index].atOrg}`,
      )
    }

    return acc
  }, [])

  return _data
}

export default curry(fn)
