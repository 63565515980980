/* eslint-disable no-unused-vars,max-len */
/* global PLATFORM, G */
import { hide, show } from 'lib/sequence/component/state/hidden'
import getFavorite from '@app/_shared/events/favorite/get'
import getQuickAction from 'app/_shared/events/appbar/quickActions/get'
import { isObj, isStr } from 'lib/util'

/**
 * Calls {@link getFavorite} with the given `payload` to determine if the document
 * at hand is a favorite or not. Afterward, executes platform specific logic to
 * activate the favorite button.
 *
 * @param {string} payload  the id of the favorite
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const displayFavoriteButtonsInAction = payload => module => async ([components, ...args]) => {
  const action = module[G.STATE][G.ACTION]
  const actionComponent = action[G.COMPONENT]
  const isFavorite = getFavorite(module, actionComponent, { detail: { value: payload } })

  if (PLATFORM === 'web') {
    const { btnFavorite, btnNoFavorite } = components

    payload && isFavorite
      ? hide(btnNoFavorite) && show(btnFavorite)
      : show(btnNoFavorite) && hide(btnFavorite)
  } else if (PLATFORM === 'mobile') {
    const { quickActions = [] } = action[G.UI]
    const favoriteAction = quickActions.find(quickAction => quickAction.key === 'favorite')

    payload && favoriteAction && (favoriteAction.active = !!isFavorite)
  }

  return [components, ...args]
}

/**
 * Display Favorite Buttons In Action Handler
 *
 * Hides / shows the `btnFavorite` and `btnNoFavorite` buttons depending on whether the
 * ID of the current model (either `attribute` sub model or `module[G.MODEL]`) is
 * favored or not.
 *
 * Gathers the ID of the favorite either from the main or a sub-model and calls
 * {@link displayFavoriteButtonsInAction} with it as a payload.
 *
 * @param {string|null} attribute  sub-model to get payload from
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
const displayFavoriteButtonsInActionFromModel = attribute => module => async (...args) => {
  const model = module[G.MODEL]
  const payload = attribute
    ? model[G.CHILDREN][attribute][G.STATE][G.REF]
    : model[G.STATE][G.REF]

  return await displayFavoriteButtonsInAction(payload)(module)(args)
}

/**
 * Display Favorite Buttons From Args Event Handler
 *
 * Gathers the id of the document from `args` and passes it on to
 * {@link displayFavoriteButtonsInAction} as the `payload`.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(...[*]): Promise<*[]>}
 */
export const displayFavoriteButtonsFromArgs = module => async (...args) => {
  const [_, data] = args || []

  let payloadId

  if (data && isObj(data)) {
    const { value: { documentationId } = {} } = data
    payloadId = documentationId
  } else if (data && isStr(data)) {
    payloadId = data
  }

  return await displayFavoriteButtonsInAction(payloadId)(module)(args)
}

/**
 * Wraps {@link displayFavoriteButtonsInActionFromModel} with `attribute` as the current sub-model.
 *
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
export const displayFavoriteButtonsFromAttribute = attribute => displayFavoriteButtonsInActionFromModel(attribute)

/**
 * Calls {@link displayFavoriteButtonsInActionFromModel} with the current module's `G.MODEL`.
 *
 * @returns {function(*): function(*, ...[*]): Promise<*[]>}
 */
export default displayFavoriteButtonsInActionFromModel(null)
