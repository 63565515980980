/* eslint-disable no-param-reassign,no-return-assign */
/* global G */
import { curry, getKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Obtains ACL props by calling the acl's component function and adds them to the component's state.
 *
 * @param {Gaia.AppModule.Spec} module - application module
 * @param {Gaia.Component} component - parent component
 * @param {string} key - component key
 * @return {Object} - acl props
 */
const applyACLProps = (module, component, key) => {
  const props = module[G.ADAPTER][G.ACL].component(component, key)
  const item = getKey(key, asObject(component[G.CHILDREN]))

  Object.keys(props).forEach(name => item[G.STATE][name] = props[name])

  return props
}

/**
 * component acl getter
 * this is a mandatory event handler, used in all ACL UI components
 * it allows working with protected acl property of the component
 * which is obfuscated from the UI layer
 *
 * it is strongly encouraged to use it ONLY in UI iterators such as AclForm
 *
 * @example config
 * { ..., options: { ..., events: { ..., acl: 'acl', ... }}}
 * @example module/[name]/events.js
 * import acl from 'app/_shared/acl/component'
 * export default { ..., acl: { acl }, ...}
 *
 * @param {Gaia.AppModule.Spec} module - application module
 * @param {Gaia.Component} component - parent component
 * @param {string} key - component key
 * @return {Object} - acl props
 */
const acl = (module, component, { key }) => applyACLProps(module, component, key)

export default curry(acl)
