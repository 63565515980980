/* global G */

/**
 * Sets the {@param module}'s state as error if any of the model's sub-models or the model itself
 * has error state.
 *
 * @param {Gaia.Component.Spec} attribute the attribute model to check
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const checkModelErrorAndUpdateState = attribute => module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { [attribute]: subModel = {} } = model[G.CHILDREN]
  const validationError = model[G.STATE][G.ERROR] || subModel?.[G.STATE]?.[G.ERROR]

  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  return [components, ...args]
}

export default checkModelErrorAndUpdateState
