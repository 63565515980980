/* global G */
import { curry, deleteKey, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { apply as applyAttachments, get as getAttachments } from 'app/_shared/events/attachment'
import { get, reset } from 'lib/sequence/component/state/value'
import validate from 'lib/sequence/model/validate'
import { fromTimeEntry } from 'app/ticket/event/note/time'
import { edit } from 'app/_shared/events/note'
import { hide, show } from 'lib/sequence/component/state/hidden'
import updateAttachments from 'app/_shared/events/message/updateAttachments'

/**
 * Update Note Event Handler
 *
 * Attempts to update a note on the server
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @return {Promise<void>}
 */
const update = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const ticket = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { addNoteBtn, spentTime: spentTimeField, form } = sequenceComponentFind(actionComponent)

  const attachments = await getAttachments(module, component, event)
  const attachmentsCount = attachments.length

  // Validation of spentTime
  await validate(ticket)(component)

  if (ticket[G.STATE][G.ERROR]) {
    setKey(true, 'error', component[G.STATE])
    module[G.ADAPTER][G.UI].update(module)

    throw Error('Model validation error')
  }

  const spentTime = get(spentTimeField)
  const { text, key, mentions, type } = event?.detail || event || {}

  const spentSeconds = spentTime
    ? fromTimeEntry(module, component, { value: spentTime })
    : null

  await edit(module, component, {
    key,
    type,
    text,
    spentTime: spentSeconds,
    mentions,
    ...attachmentsCount && { hasAttachments: true },
  }, async (item) => {
    const { value } = item

    // The attachment adapter works by deleting the attachments (that need to be deleted)
    // before uploading the ones that need to be uploaded (take a look at it's {@link apply}
    // function). That means we know when the whole process is finished, only by looking at
    // the local attachments and feeding them to {@link updateAttachments}.
    const localAttachments = attachments.filter(attachment => attachment?.value?.name && (attachment.key === attachment.value.name))

    setKey(key, G.REF, component[G.STATE]) // required for applyAttachments
    applyAttachments(module, component, event)
    await updateAttachments(module, component, localAttachments)

    // localAttachments changed, and therefore attachments changed. So we already have
    // the latest state of the attachments in `attachments`.
    value.attachments = attachments

    // Cleaning up
    reset(spentTimeField)
    reset(form)

    show(addNoteBtn)
    hide(form)

    await module[G.ADAPTER][G.UI].update(module)

    return true
  })
}

export default curry(update)
