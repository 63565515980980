/* eslint-disable object-curly-newline,prefer-destructuring */
/* global G */
import find from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentState from 'lib/sequence/component/state'
import stepper from 'app/_shared/action/stepper'
import { CONFIRM } from 'app/_shared/events/setStepTab'
import seqModelRead from 'lib/sequence/model/api/read'
import type from './create/type'
import device from './create/device'
import location from './create/location'
import partner from './create/partner'
import issue from './create/issue'

const {
  set: setTab,
} = sequenceComponentState('activeTab')

const setConfirmTab = step => setTab(step, CONFIRM)

/**
 * Sets wizard form data in advance.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component action's main component
 * @param {array} args                    additional action arguments
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
const preset = async (module, component, args) => {
  const { item, product, installedAt } = args[0] || {}
  if (item) {
    const model = module[G.MODEL]
    model[G.CHILDREN].item[G.CACHE] = item[G.CACHE]
    model[G.CHILDREN].item[G.STATE][G.REF] = item[G.STATE][G.REF]
    model[G.CHILDREN].itemData[G.CACHE] = item[G.CACHE]
    model[G.CHILDREN].itemInstalledAt[G.CACHE] = installedAt[G.CACHE]?.[0]
    model[G.CHILDREN].itemInstalledAt[G.STATE][G.REF] = installedAt[G.STATE][G.REF]
    model[G.CHILDREN].product[G.CACHE] = product[G.CACHE]?.[0]
    model[G.CHILDREN].product[G.STATE][G.REF] = product[G.STATE][G.REF]
    await seqModelRead(model[G.CHILDREN].item)(component)
    await seqModelRead(model[G.CHILDREN].itemData)(component)
    await seqModelRead(model[G.CHILDREN].itemInstalledAt)(component)
    // item may not have an assigned product
    product[G.STATE][G.REF] && await seqModelRead(model[G.CHILDREN].product)(component)
    // setting steps' confirmed state
    const { stepper: wizard } = asObject(component[G.CHILDREN])
    const steps = asObject(wizard[G.CHILDREN])
    steps.device[G.STATE].confirmed = true
    steps.location[G.STATE].confirmed = true
    // setting step's confirmed tab
    const { device: deviceStep } = find(component)
    setConfirmTab(deviceStep)
  }
}

/**
 * New action step actions manager.
 *
 * Executes one step action after another.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  await preset(module, component, args)
  const actions = { type, device, location, partner, issue }
  return stepper(module)(component)({ actions, ...args })
}
