/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { get as getNote } from 'app/_shared/events/note'

/**
 * Get Note Attachments From Attachments Group Event Handler
 *
 * Attempts to get the attachments for a note from the parent attachment group.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @returns {Promise<unknown>}
 */
const getNoteAttachmentsFromAttachmentsGroup = async (module, component, event) => {
  const { [G.EVENTS]: eventBus } = module[G.ADAPTER]

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { note: pane, form } = sequenceComponentFindProxy(actionComponent)
  const { attachmentsGroup = null } = pane[G.PROPS]

  // Getting the current note
  const currentNoteId = get(form)?.id
  const currentNote = await getNote(module, component, { key: currentNoteId })
  const { value: { attachments = [] } = {} } = currentNote || {}

  // Asking the parent group to give us all the attachments of the current note
  const data = !attachmentsGroup ? [] : await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.ATTACHMENT, G.DONE, attachmentsGroup), (attachmentEvent) => {
      const ticketAttachments = attachmentEvent.detail[G.DATA]
      const messageAttachments = ticketAttachments
        .filter(tAtt => attachments.findIndex(nAtt => nAtt.key === tAtt.key) > -1)
        .map(nAtt => ({ ...nAtt, acl: currentNote.acl }))
      resolve(messageAttachments)
    }, { once: true })
    eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.CACHE, attachmentsGroup), { [G.DATA]: {} })
  })

  return data?.length
    ? data
    : []
}

export default curry(getNoteAttachmentsFromAttachmentsGroup)
