import serialFieldConfig from './serialFieldConfig'
import ticketTypes from './ticketTypes'
import noteTypes from './noteTypes'
import roles from './roles'

/**
 * Schenck's application settings
 */
export default {
  languages: ['en', 'de'],
  // List of mail domains that are banned from registration
  bannedMailDomains: [
    'schenck', // banning all TLDs that contain 'schenck'
  ],
  suppressPersistence: true,
  wideLayoutLogin: true,
  secondaryColorLogin: true,
  forceExtendedRegistration: true,
  swapDeviceSerialAndName: true,
  serialImage: 'Serial.png',
  serialFieldConfig,
  suppressProductDocumentation: true,
  suppressEquipmentOptions: true,
  suppressProductDevices: true,
  suppressTicketFinishStepForRoles: ['Sales', 'CustomerServiceCooperator'],
  teamDefaultRoles: ['Sales', 'CustomerServiceAgent'],
  suppressSoftwareInfo: true,
  suppressFieldServiceInfo: true,
  registerRequestTypesDropdown: true,
  ticketTypes,
  noteTypes,
  roles,
}
