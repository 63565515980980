/* eslint-disable no-param-reassign */
/* global G */
import { curry, getFirstItem } from 'lib/util'

/**
 * Returns the timestamp as a localized date using {@param dateStyle}.
 *
 * @param {string} dateStyle    style the date should be returned as
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} component    the component that matched the attribute name during the mapping
 * @return {*}
 */
const fn = (dateStyle, key, obj, component) => {
  const sourceAttribute = obj[G.CHILDREN][key]
  const value = getFirstItem(sourceAttribute[G.CACHE]) || null

  sourceAttribute[G.CACHE] = !value ? '-' : obj[G.ADAPTER][G.INTL].getLocaleDate(value, { dateStyle })

  return component
}

export default curry(fn)
