/* global G */
import { curry } from 'lib/util'
import { search } from 'app/_shared/events/search/infinite'

/**
 * Params helper function
 *
 * Collects and returns the params for the current search request.
 *
 * @param {Gaia.AppModule.Spec} module            app module
 * @param {Gaia.Component.Spec} component         action component
 * @param {PlatformEvent|object} [eventOrOptions] information about the event that triggered this
 *                                                handler or a search term
 * @returns
 */
const _params = (module, component, eventOrOptions) => {
  const options = (eventOrOptions && eventOrOptions.detail) || eventOrOptions || {}
  const { term: eventTerm, page: eventPage, limit: eventLimit } = options
  const { filter: eventFilter, query: eventQuery } = options
  const componentStateMeta = component[G.STATE][G.META] || {}
  const { limit } = component[G.PROPS]
  const { term = eventTerm, filter = eventFilter, query = eventQuery } = componentStateMeta

  return {
    limit: eventLimit ?? limit,
    filter,
    page: eventPage || undefined,
    query: {
      term,
    },
    ...query && {
      lucene: query,
    },
  }
}

/**
 * Search Mixed Event Handler
 *
 * Send a request to the /api/v1/search/mixed route.
 *
 * Functions similiar to the {@link search} event handler, apart from using
 * {@code /api/v1/search/mixed} as a route with a reduced param set.
 *
 * @param {Gaia.AppModule.Spec} module          app module
 * @param {Gaia.Component.Spec} component       action component
 * @param {Event|object} [eventOrOptions]       information about the event that triggered this
 *                                              handler or a search term
 * @return {Promise<{value: string, key: *}[]>} void
 */
const mixedFn = async (module, component, eventOrOptions) => {
  try {
    const { version } = module[G.MODEL][G.PROPS]

    const params = _params(module, component, eventOrOptions)
    const url = `/api/v${version}/search/mixed`

    try {
      const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
      const result = await remoteAdapter.post({ url, params })

      // Server returns wrong structure...
      return result?.value.map((item) => {
        const { key, refs, ...rest } = item
        return {
          key,
          refs,
          value: rest,
        }
      })
    } catch (e) {
      console.error('search:: tried and failed getting remote resources', url)
    }
  } catch (e) {
    console.error(e)
  }
  return [
    { key: -1, value: 'Not Found' },
  ]
}

/**
 * Infinite Mixed Event Handler
 *
 * Send a request to the /api/v1/search/mixed route.
 *
 * Functions similar to the {@link infinite} event handler, apart from using
 * {@code /api/v1/search/mixed} as a route with a reduced param set.
 *
 * @param {Gaia.AppModule.Spec} module          app module
 * @param {Gaia.Component.Spec} component       action component
 * @param {Event|object} [eventOrOptions]       information about the event that triggered this
 *                                              handler or a search term
 * @return {Promise<{value: string, key: *}[]>} void
 */
const infiniteMixedFn = async (module, component, eventOrOptions) => {
  try {
    const { version } = module[G.MODEL][G.PROPS]

    const params = _params(module, component, eventOrOptions)
    const url = `/api/v${version}/search/mixed`

    try {
      const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
      const result = await search(remoteAdapter.post, url, params, [])

      return {
        ...result,
        // Server returns wrong structure...
        data: result.data.map((item) => {
          const { key, refs, ...rest } = item
          return {
            key,
            refs,
            value: rest,
          }
        }),
      }
    } catch (e) {
      console.error('search:: tried and failed getting remote resources', url)
    }
  } catch (e) {
    console.error(e)
  }
  return [
    { key: -1, value: 'Not Found' },
  ]
}

export default curry(mixedFn)

export const infiniteMixed = curry(infiniteMixedFn)
