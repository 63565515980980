/* eslint-disable no-unused-vars,no-nested-ternary */
/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Organisation Submit With Event Handler
 *
 * Receives a {@code key} from the component that called this handler and executes
 * the {@code organisationSubmit} action, passing it the key based on the value of
 * {@param action}.
 *
 * @param {'revert'|'submit'} action      type of operation that determines what gets passed
 *                                        to the {@code organisationSubmit} action
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<void>}
 */
const organisationSubmitWith = async (action, module, component, event) => {
  const { key = null } = event?.detail || event || {}
  const { organisationSubmit } = module[G.ACTIONS]

  await actionWithHooks(organisationSubmit)({
    duplicateOf: action === 'revert'
      ? undefined
      : key ? [key] : [],
  })
}

export default curry(organisationSubmitWith)
