import { curry } from 'lib/util'

/**
 * Trim Attachment Name Event Handler
 *
 * If the attachment is an internal one, meaning we don't have an URL for accessing it, it'll remove
 * the {@code =utf8-''} prefix from the attachment name. If we do have an URL, it will use its
 * file name (e.g. `.../file.mp4`) instead of the provided one (`attachment.value.name`).
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {PlatformEvent} event         information about the event that triggered this handler
 * @returns {*&{value: (*&{name: *})}}
 */
const trimAttachmentName = (module, component, event) => {
  const { value: attachment } = event?.detail || event || {}

  if (!attachment?.value?.name) return attachment

  return {
    ...attachment,
    value: {
      ...attachment.value,
      name: attachment.value?.url
        ? attachment.value.url.split('/').pop()
        : attachment.value.name.replace('=utf-8\'\'', ''),
    },
  }
}

export default curry(trimAttachmentName)
