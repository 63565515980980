/* global G */
import map from 'lib/sequence/model/api/map'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { set, get } from 'lib/sequence/component/state/value'
import { bulk, setKey } from 'lib/util'
import session, { settings } from 'app/_shared/session'
import { show, hide } from 'lib/sequence/component/state/hidden'

/**
 * Request detail action.
 *
 * Maps the model to the view and prepares its components to be shown according to some values.
 *
 * @param {Object} module the request module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]

  const {
    statusBox,
    infoBox,
    descriptionBox,
    itemInfo,
    message,
    messageField,
    reopenBox,
  } = sequenceComponentFind(component)
  const { serial, name } = sequenceComponentFind(itemInfo)

  // Mappings
  const bulkMapModel = bulk(map(model))
  bulkMapModel(statusBox, infoBox, itemInfo, descriptionBox)

  const serialValue = get(serial)
  const nameValue = get(name)
  settings.swapDeviceSerialAndName
    && set(name, serialValue)
    && set(serial, nameValue)

  // Message
  const sourceId = model[G.STATE][G.REF]
  const userId = session(module).user.key()
  !get(message) && set(message, { sourceId, userId })

  // message types
  const { status, type } = model[G.CHILDREN]
  const currentStatus = status[G.CACHE]?.[0]?.key

  setKey(currentStatus, 'currentStatus', messageField[G.STATE])
  setKey(type[G.CACHE], 'currentType', messageField[G.STATE])

  // Setting the message text in case there is one in the module state.
  module[G.STATE].text && set(messageField, { text: module[G.STATE].text })

  /**
   * Toggling between {@code messageField} and {@code reopenBox}
   * based on request status.
   *
   * @type {number}
   */
  const requestStatus = parseInt(currentStatus, 10)
  if (requestStatus >= 10 && requestStatus < 80) {
    show(messageField)
    hide(reopenBox)
  } else {
    hide(messageField)
    show(reopenBox)
  }

  return args
}
