/* global G */
import { set as setToken } from 'app/_shared/component/token'
import { translationsMap } from 'lib/util'

/**
 * UI Title Hook
 *
 * On initial render, it will set {@param obj[G.STATE].token} according to the incoming
 * {@code label} or {@code name} in the model cache and let the UI adapter translate the
 * action title.
 * On subsequent renders, it will translate the UI title itself. This is needed because
 * this logic should also work for modals, in which case the UI adapter will not re-translate
 * the UI title because {@code action[G.COMPONENT][G.REF]} doesn't change.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @returns {function(...[*]): *[]}
 */
const uiTitle = obj => (...args) => {
  const { name = null } = obj?.[G.MODEL]?.[G.CACHE] || {}
  const { value: { label = null } = {} } = args?.[0] || {}
  const { token: tokenState } = obj[G.STATE]
  const token = { name: label || name || 'PDF' }

  const action = obj[G.STATE][G.ACTION]

  if (label || name) {
    setToken(obj, token)
  }

  if (!action[G.COMPONENT][G.REF]) {
    return args
  }

  const targetToken = label || name
    ? token
    : tokenState

  // translating the UI title explicitly. The {@code ui} hook won't do this because
  // we've already drawn the modal.
  const { module } = obj[G.CONFIGURATION]
  const ns = translationsMap[module] || module
  const _key = `title.${module}.${action._name}`
  const defaultValue = action[G.UI].title
  const context = obj[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT]

  action[G.UI].title = obj[G.ADAPTER][G.INTL]._t(_key, {
    _key, ns, context, defaultValue, ...targetToken,
  })

  return args
}

export default uiTitle
