/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import { asyncPipeSpread, bulk, getFirstItem, isStr, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { check, checked, uncheck } from 'lib/sequence/component/state/checked'
import { disable, disabled, enable } from 'lib/sequence/component/state/disabled'
import { get, reset, set } from 'lib/sequence/component/state/value'
import { canBePublished, hasInternalParent } from 'app/organisation/action/detail'
import sublist from 'app/_shared/events/util/sublist'
import listStatus from 'app/_shared/events/collection/listStatus'
import listCountries from 'app/_shared/events/collection/listCountries'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Checks whether {@code toBeValidated} should be displayed or not.
 *
 * @param {Gaia.Component.Spec} module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayToBeValidated = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const modelState = model[G.STATE]
  const validationCache = model[G.CHILDREN].toBeValidated?.[G.CACHE]

  // component
  const { component, toBeValidated, parent } = children
  const { verify } = find(component[G.ACTIONS][0])

  // Saving the checkbox value into the model state so that it can be picked up be the transformer
  setKey(checked(verify), 'verified', modelState)

  // Don't display toBeValidated fields and checkbox if there is no data
  validationCache
    ? bulkShow(toBeValidated, verify)
    : bulkHide(toBeValidated, verify)

  if (canBePublished(model)) {
    disable(parent)
  }

  return [children, ...args]
}

/**
 * Determines whether to display the {@code publishToMasterData} checkbox
 *
 * @param {Gaia.Component.Spec} module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayPublishToMasterData = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]

  // component
  const { component, duplicateOf } = children
  const { verify, publishToMasterData } = find(component[G.ACTIONS][0])

  // Org's parent or grandparent is ORGANISATION:RA
  if (canBePublished(model)) {
    show(publishToMasterData)
  }

  // disable the checkbox if org has not yet been verified
  (!hidden(verify) && !checked(verify)) || get(duplicateOf)?.length
    ? disable(publishToMasterData)
      && reset(publishToMasterData)
      && uncheck(publishToMasterData)
    : enable(publishToMasterData)

  return [children, ...args]
}

/**
 * Determines whether the parent field should be shown.
 *
 * @param {Gaia.Component.Spec} module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayParent = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]

  // component
  const { parent } = children

  // Org's parent or grandparent is ORGANISATION:RA
  if (!hasInternalParent(model)) {
    show(parent)
  } else {
    hide(parent)
  }

  return [children, ...args]
}

/**
 * Determines whether the {@code duplicateOf} field should be shown.
 *
 * @param {Gaia.Component.Spec} module
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayDuplicateOf = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]

  // component
  const { duplicateOf } = children

  hide(duplicateOf)

  // Org's parent or grandparent is ORGANISATION:RA
  if (canBePublished(model)) {
    show(duplicateOf)
  }

  return [children, ...args]
}
/**
 * Determines whether {@code mergeToDuplicateTarget} should be displayed or not.
 *
 * @param {Gaia.Component.Spec} module  the current module object composition
 * @returns {(function(*, ...[*]): Promise<*>)|*}
 */
const displayMergeToDuplicateTarget = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const wasMerged = getFirstItem(model[G.CHILDREN]?.mergeToDuplicateTarget?.[G.CACHE])

  // Org was merged already, don't do anything
  if (wasMerged) return [children, ...args]

  // component
  const { mergeToDuplicateTarget, duplicateOf } = children

  show(mergeToDuplicateTarget)

  const duplicateOfField = get(duplicateOf)
  const hasDuplicate = getFirstItem(duplicateOfField)

  // Only offer the option if {@code duplicateOf} has been filled out
  hasDuplicate
    ? enable(mergeToDuplicateTarget)
    : disable(mergeToDuplicateTarget)
      && reset(mergeToDuplicateTarget)
      && uncheck(mergeToDuplicateTarget)

  // Hide checkbox if duplicateOf is also hidden
  hidden(duplicateOf) && hide(mergeToDuplicateTarget)

  return [children, ...args]
}

/**
 * Preset the {@code status} field.
 *
 * @param {Gaia.Component.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetStatus = module => async (children, ...args) => {
  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { status: statusField } = children

  const status = getFirstItem(listStatus(module, actionComponent, get(statusField)))

  // Only set the field if it's editable
  status
  && !hidden(statusField)
  && !disabled(statusField)
  && set(statusField, status)

  return [children, args]
}

/**
 * Preset the {@code status} field.
 *
 * @param {Gaia.Component.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const presetProvides = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const { support } = model[G.CHILDREN]
  const providesCache = getFirstItem(support[G.CHILDREN].provides[G.CACHE]) || false

  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { provides: providesField } = children
  const providesFieldValue = get(providesField)

  checked(providesField) || providesFieldValue === true
    ? set(providesField, true) && check(providesField)
    : reset(providesField) && uncheck(providesField)

  return [children, args]
}

/**
 * Preset the {@code status} field.
 *
 * @param {Gaia.Component.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const presetCountries = module => async (children, ...args) => {
  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { countries: countriesField, provides: providesField } = children
  const currentCountries = get(countriesField)
  const mappedCountries = currentCountries?.length
    ? currentCountries.map(country => (isStr(country) ? country : country.key))
    : []

  const resolvedCountries = sublist(mappedCountries, listCountries)(module, actionComponent, null)

  if (currentCountries?.length || checked(providesField) || get(providesField)) {
    set(countriesField, resolvedCountries)
    show(countriesField)
  } else {
    hide(countriesField)
  }

  return args
}
/**
 * Organisation Action Edit
 *
 * @param {Gaia.Component.Spec} module
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  displayToBeValidated(module),
  displayPublishToMasterData(module),
  displayDuplicateOf(module),
  displayMergeToDuplicateTarget(module),
  displayParent(module),
  presetStatus(module),
  presetProvides(module),
  presetCountries(module),
)(find(component), ...args)
