/* global G */
import { curry } from 'lib/util'
import { _url } from 'app/_shared/events/link'

/**
 * Link To Target Document Event Handler
 *
 * Creates an url for use with the {@code link} handler based on the
 * documents {@code docType}
 *
 * @param {Gaia.AppModule.Spec} module    app module
 * @param {Gaia.Component.Spec} component action component
 * @param {Gaia.PlatformEvent} event      event
 * @returns {string|null}
 */
const linkToTargetDocument = (module, component, event) => {
  const state = component[G.STATE]

  const docType = state?.item?.value?.docType
  const url = _url(docType, module, component, event)

  const key = state.key
      || event?.key
      || state.value?.[0]?.key
      || state.value?.key

  return key
    ? `${url}/${key}`
    : null
}

export default curry(linkToTargetDocument)
