/**
 * User roles collection
 *
 * Contains a list of possible roles a user can have.
 *
 * @type {userRole[]} userRoles
 */
const userRoles = [
  {
    value: 'Waiting for approval',
    key: 'Requester',
    canBePublishedTo: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: false,
  },
  {
    value: 'Verified customer',
    key: 'Customer',
    canBePublishedTo: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: false,
  },
  {
    value: 'Blocked',
    key: 'Blocked',
    canBeMentioned: false,
    canBeInvited: false,
    hasTeams: false,
    hasMessages: false,
  },
  {
    value: 'Customer Service Agent',
    key: 'CustomerServiceAgent',
    canBeMentioned: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: true,
  },
  {
    value: 'Customer Service Manager',
    key: 'CustomerServiceManager',
    canBeMentioned: true,
    canBeInvited: true,
    hasTeams: true,
    hasMessages: true,
  },
  {
    value: 'Field Service Engineer',
    key: 'FieldServiceEngineer',
    canMaintainDevices: true,
    canBePublishedTo: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: false,
  },
  {
    value: 'Customer Service External',
    key: 'CustomerServiceExternal',
    hasMessages: false,
    hasTeams: false,
  },
  {
    value: 'Customer Service Cooperator',
    key: 'CustomerServiceCooperator',
    canMaintainDevices: false,
    canBePublishedTo: true,
    canBeInvited: true,
    hasMessages: false,
    hasTeams: false,
  },
  {
    value: 'Sales',
    key: 'Sales',
    canBePublishedTo: true,
    canBeMentioned: true,
    canBeInvited: true,
    hasMessages: true,
    hasTeams: true,
  },
  {
    value: 'Master Data Administrator',
    key: 'MasterDataAdministrator',
    hasMessages: true,
    hasTeams: true,
  },
  {
    value: 'Instance Administrator',
    key: 'InstanceAdministrator',
    hasMessages: true,
  },
]

export default userRoles
