/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import { asyncPipeSpread, asyncPipeSpreadIf, bulk, getFirstItem, isArr, isObj, isStr, setKey } from 'lib/util'
import internalOrganisations from 'model/organisation/collection/internal'
import { get, set } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'
import map from 'lib/sequence/model/api/map'
import search from 'app/_shared/events/search'
import format from 'lib/util/string'

/**
 * Translate the address to a localized version
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayAddressSummary = module => async (children, ...args) => {
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { address } = organisation[G.CHILDREN]

  const { [G.INTL]: intl } = module[G.ADAPTER]

  const { summary } = children
  const summaryValue = get(summary)

  const localizedAddress = intl.address(address[G.CACHE], { inline: true })
  summaryValue && set(summary, localizedAddress)

  return [children, ...args]
}

/**
 * Enables/Disables field based on checkbox value of {@code isDuplicate}.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayVerifyButton = module => async (children, ...args) => {
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { refs: { duplicateOf = null } = {} } = organisation[G.CACHE] || {}

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const [buttons] = actionComponent[G.ACTIONS]
  const { btnVerify } = sequenceComponentFind(buttons)

  duplicateOf
    ? enable(btnVerify)
    : disable(btnVerify)

  return [children, ...args]
}

/**
 * Hides/shows the parent organisation button based on which org is currently
 * shown.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayParentOrganisationButton = module => async (children, ...args) => {
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { refs: { parent: parentRef = null } = {} } = organisation[G.CACHE] || {}
  const parent = getFirstItem(parentRef)
  const parentKey = parent && isStr(parent)
    ? parent
    : parent?.key

  const isRootOrg = internalOrganisations.some(x => x.key === parentKey)
  const { btnParentOrganisation } = children

  isRootOrg
    ? hide(btnParentOrganisation)
    : show(btnParentOrganisation)

  return [children, ...args]
}

/**
 * Bulk maps certain properties.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (children, ...args) => {
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { organisationSummary, infoContent } = children

  const bulkMap = bulk(map(organisation))

  bulkMap(organisationSummary, infoContent)

  return [children, ...args]
}

/**
 * Gets the type of the organisation and adds it to the returned data.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const getOrganisationType = module => async (children, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { value: { type = 'customer' } = {} } = organisation[G.CACHE]

  const organisationType = getFirstItem(listOrganisationTypes(module, actionComponent, { detail: { key: type } }))

  return [organisationType, children, ...args]
}

/**
 * Presets the organisation icon based on the incoming type.
 *
 * @param {string} icon name of the component to set
 * @returns {function(*): function(*, *, ...[*]): Promise<*>}
 */
const presetOrganisationIcon = icon => module => async (type, children, ...args) => {
  const { [icon]: target } = children

  type && setKey(type.icon, 'icon', target[G.STATE])

  return [children, ...args]
}

/**
 * Presets the {@code noDuplicateExplanation} field.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetNoDuplicateExplanation = module => async (children, ...args) => {
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { name } = organisation[G.CHILDREN]
  const organisationName = name[G.CACHE]

  const { noDuplicateExplanation } = children

  setKey({ name: organisationName }, 'token', noDuplicateExplanation[G.STATE])

  return [children, ...args]
}

/**
 * Queries the server to return all sub items of the current organisation. Saves all
 * sub-organisations into the module state and returns the count of each sub item
 * afterward.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(*, *, ...[*]): Promise<*>}
 */
const getSubItems = module => component => async (children, ...args) => {
  const model = module[G.MODEL]
  const { organisation = {} } = model[G.CHILDREN] || {}
  const { [G.STATE]: { [G.REF]: orgRef = null } = {} } = organisation
  const orgId = orgRef?.replaceAll(':', '\\:')

  const conditions = [
    `metatype:request AND requesterContactOrg:${orgId}`,
    `metatype:serviceitem AND tree_installedAt:${orgId}`,
    `metatype:person AND organisation:${orgId}`,
    `metatype:organisation AND tree_parent:${orgId}`,
  ]

  const query = conditions.map(cond => `(${cond})`).join(' OR ')

  const result = await search('verbose', module, component, { detail: { query } })

  const serviceItems = result.filter(x => x.refs?.installedAt)
  const serviceItemCount = serviceItems.length

  const requestCount = result.filter(x => x.refs?.requesterContactOrg)?.length
  const personCount = result.filter(x => x.refs?.user)?.length

  const organisations = result.filter(x => x.refs?.parent)
  const organisationCount = organisations.length

  const subItems = {
    serviceItems: serviceItemCount,
    requests: requestCount,
    persons: personCount,
    organisations: organisationCount,
  }

  // We save all the fetched suborganisations into the module state,
  // The onOpen handler of the sub-org list will use them, so it doesn't
  // need to re-fetch it
  setKey(organisations, 'subOrganisations', module[G.STATE])
  setKey(serviceItems, 'subServiceItems', module[G.STATE])

  return [subItems, children, ...args]
}

/**
 * Maps the incoming sub item data to the corresponding components based on
 * the defined mapping.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*[]>}
 */
const mapSubItems = module => async (subItems, children, ...args) => {
  const { subServiceItems, subRequests, subUsers, subOrganisations } = children

  const componentMapping = {
    serviceItems: subServiceItems,
    requests: subRequests,
    persons: subUsers,
    organisations: subOrganisations,
  }

  Object.keys(componentMapping).forEach((subItem) => {
    const count = subItems[subItem]
    const component = componentMapping[subItem]

    count === 0
      ? hide(component)
      : show(component) && setKey({ count }, 'token', component[G.STATE])
  })

  return [children, ...args]
}

/**
 * Displays the section for related organisations if the organisation has
 * sub organisations.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayRelatedOrganisationsList = module => async (children, ...args) => {
  const { relatedOrganisationsSection } = children

  const { subOrganisations = [] } = module[G.STATE]

  subOrganisations.length
    ? show(relatedOrganisationsSection)
    : hide(relatedOrganisationsSection)

  return [children, ...args]
}

/**
 * Checks whether the organisation has been checked for duplicates. If so, fetches the duplicate
 * and displays the right section according to it.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {(function(*, ...[*]): Promise<*>)|*}
 */
const displayRightSection = module => async (children, ...args) => {
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { options: { version, api } } = organisation[G.CONFIGURATION]

  const { checkedSection, notCheckedSection } = children

  const duplicateRef = organisation[G.CACHE]?.refs?.duplicateOf || false
  const isDuplicateChecked = !!duplicateRef

  const duplicate = (duplicateRef && isArr(duplicateRef) && getFirstItem(duplicateRef))
    || null

  const duplicateId = (duplicate && isObj(duplicate) && duplicate.key)
    || duplicate

  isDuplicateChecked
    ? show(checkedSection) && hide(notCheckedSection)
    : hide(checkedSection) && show(notCheckedSection)

  if (duplicateId) {
    // TODO: Check if we actually need to fetch it here
    //  Maybe we can adapt view on server to resolve it
    const { [G.HTTP]: httpAdapter } = module[G.MODEL][G.ADAPTER]
    const original = await httpAdapter.get({ url: `/api/v${version}/${api}/${duplicateId}` })
    return [{ original }, children, ...args]
  }

  return [{
    original: isDuplicateChecked
      ? duplicateId || []
      : null,
  }, children, ...args]
}

/**
 * Display an alert text informing the user that their can revert their decision
 * once the organisation has been checked.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function({original: *}, *, ...[*]): Promise<[{original},undefined,...*[]]>}
 */
const displayRevertAlert = module => async ({ original }, children, ...args) => {
  const { revertCheckDuplicateExplanation } = children

  original === null
    ? hide(revertCheckDuplicateExplanation)
    : show(revertCheckDuplicateExplanation)

  return [{ original }, children, ...args]
}

/**
 * Displays the {@code hasOriginal} section and hides the {@code hasNoOriginal} section.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function({original: *}, *, ...[*]): Promise<[{original},undefined,...*[]]>}
 */
const displayOriginalSection = module => async ({ original }, children, ...args) => {
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { hasOriginalSection, hasNoOriginalSection, originalExplanation } = children

  hide(hasNoOriginalSection)
  show(hasOriginalSection)

  const options = {
    ns: 'admin',
    _key: 'text.originalExplanationMD',
    defaultValue: get(originalExplanation),
    md: true,
  }

  const string = await module[G.ADAPTER][G.INTL].translate('text.originalExplanationMD', options)
  const formattedString = format(string, {
    duplicate: organisation[G.CACHE]?.value?.name, original: original?.value?.name,
  })
  set(originalExplanation, formattedString)

  return [{ original }, children, ...args]
}

/**
 * Displays the {@code hasNoOriginal} section and hides the {@code hasOriginal} section.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function({original: *}, *, ...[*]): Promise<[{original},undefined,...*[]]>}
 */
const displayNoOriginalSection = module => async ({ original }, children, ...args) => {
  const { hasOriginalSection, hasNoOriginalSection } = children

  show(hasNoOriginalSection)
  hide(hasOriginalSection)

  return [{ original }, children, ...args]
}

/**
 * Gets the type of the organisation and adds it to the returned data.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const getOriginalOrganisationType = module => async ({ original }, children, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { value: { type = 'customer' } = {} } = original

  const originalOrganisationType = getFirstItem(listOrganisationTypes(module, actionComponent, { detail: { key: type } }))

  return [originalOrganisationType, children, ...args]
}

/**
 * Translate the address to a localized version
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayAddressSummaryFromValue = module => async ({ original }, children, ...args) => {
  const { address } = original.value

  const { [G.INTL]: intl } = module[G.ADAPTER]

  const { originalOrganisationSummary } = children
  const { summary } = sequenceComponentFind(originalOrganisationSummary)

  const localizedAddress = intl.address(address, { inline: true })
  set(summary, localizedAddress)

  return [{ original }, children, ...args]
}

/**
 * Translate the address to a localized version
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayNameFromValue = module => async ({ original }, children, ...args) => {
  const { name } = original.value

  const { originalOrganisationSummary } = children
  const { name: nameComponent } = sequenceComponentFind(originalOrganisationSummary)

  set(nameComponent, name)

  return [{ original }, children, ...args]
}

/**
 * Admin Action Check Duplicate
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  displayParentOrganisationButton(module),
  displayVerifyButton(module),
  mapProperties(module),
  displayAddressSummary(module),
  getOrganisationType(module),
  presetOrganisationIcon('icon')(module),
  presetNoDuplicateExplanation(module),
  getSubItems(module)(component),
  mapSubItems(module),
  displayRelatedOrganisationsList(module),
  displayRightSection(module),
  displayRevertAlert(module),
  // if original passed by prev function is an obj (we have an original)
  asyncPipeSpreadIf(prevArgs => prevArgs?.original && isObj(prevArgs.original))(
    displayOriginalSection(module),
    displayAddressSummaryFromValue(module),
    displayNameFromValue(module),
    getOriginalOrganisationType(module),
    presetOrganisationIcon('originalOrganisationIcon')(module),
  ),
  // if original passed by prev function is an arr (arr will be empty, there is no duplicate)
  asyncPipeSpreadIf(prevArgs => prevArgs?.original && isArr(prevArgs.original))(
    displayNoOriginalSection(module),
  ),
)(sequenceComponentFind(component), ...args)
