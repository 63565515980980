/* global G */
import { curry } from 'lib/util'

const descriptor = 'model::decorator::fromUserRoles'

/**
 * From UserRoles Decorator
 *
 * Transforms a users' roles array to a userRoles array with split roles.
 *
 * @example
 * const roles = [ "system.active", "Customer@ORG:1" ]
 *
 * @example
 * const userRoles = [
 *   { index: 0, role: "system.active"},
 *   { index: 1, role: "Customer", atOrg: "ORG:1" }
 * ]
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} component    the component that matched the attribute name during the mapping
 * @returns {*}
 */
const fn = (key, obj, component) => {
  console.warn(descriptor, key)
  const attribute = obj[G.CHILDREN][key]

  attribute[G.CACHE] = attribute[G.CACHE].reduce((acc, role) => [
    ...acc,
    {
      index: attribute[G.CACHE].indexOf(role),
      role: role.split('@')[0],
      ...role.includes('@') && { atOrg: role.split('@')[1] },
    },
  ],
  [])

  return component
}

export default curry(fn)
