/* eslint-disable no-unused-vars */
/* global G */
import search from 'app/_shared/events/search'
import { curry } from 'lib/util'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Checks whether the organisation to be deleted has any open requests.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @returns {Promise<boolean>}
 * @private
 */
const _hasRequests = async (module, component) => {
  const model = module[G.MODEL]

  const { [G.STATE]: { [G.REF]: orgRef = null } = {} } = model
  const orgId = orgRef?.replaceAll(':', '\\:')

  try {
    const result = await search(null, module, component, { detail: {
      query: `metatype:request AND requesterContactOrg:(${orgId})`,
    } })

    return !!result?.length
  } catch (e) {
    console.error(e)
  }

  return true
}

/**
 * Show Dialog Or Redirect With Handler
 *
 * Shows a confirmation dialog if the organisation to be deleted has open requests.
 * If the dialog is confirmed, it will redirect to the specified block route.
 * If the dialog is cancelled, will simply close it.
 *
 * If the organisation doesn't have any open requests, it will redirect to the
 * specified delete route.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<void>}
 */
const showDialogOrRedirectWith = async (module, component, event) => {
  const organisation = module[G.MODEL]
  const moduleState = module[G.STATE]
  const { blockRoute, deleteRoute } = component[G.CONFIGURATION]
  moduleState[G.ROUTE] = routeComposition(deleteRoute.module, deleteRoute.action)

  if (await _hasRequests(module, component)) {
    const confirmed = await showBlockingDialog(module, component, {
      title: {
        key: 'dialog.approveDelete.hasRequests.title',
        defaultValue: 'User has open requests',
      },
      text: {
        key: 'dialog.approveDelete.hasRequests.text',
        defaultValue: 'The user of this organisation has open requests and therefore cannot be deleted. Do you want to block the user instead?',
      },
    })

    if (confirmed) {
      moduleState[G.ROUTE] = routeComposition(blockRoute.module, blockRoute.action)
    } else {
      return
    }
  }

  await redirectSequence(module)({ organisation })
}

export default curry(showDialogOrRedirectWith)
