/* eslint-disable no-unused-vars */
/* global G */
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { hide } from 'lib/sequence/component/state/hidden'
import { curry, setKey } from 'lib/util'

/**
 * Hide Alert And Scroll To Bottom Event Handler
 *
 * Hides the alert for new messages if it's visible and scrolls to the bottom
 * of the message list.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 */
const hideAlertAndScrollToBottom = (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { newMessageAlert, messagesContainer } = sequenceComponentFindProxy(actionComponent)

  hide(newMessageAlert)
  setKey({}, 'scrollBottom', messagesContainer[G.STATE])

  module[G.ADAPTER][G.UI].update(module)
}

export default curry(hideAlertAndScrollToBottom)
