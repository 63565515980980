/* global G */

/**
 * Attempts to the delete a singular sub-item.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {(function(*, *): Promise<void>)|*}
 */
const deleteSubItem = module => async (api, id) => {
  const { [G.HTTP]: httpAdapter } = module[G.MODEL][G.ADAPTER]
  const { options: { version } } = module[G.MODEL][G.CONFIGURATION]
  const url = `/api/v${version}/${api}/${id}`

  try {
    await httpAdapter.delete({ url })
  } catch (e) {
    console.error(e)
  }
}

/**
 * Deletes an array of sub-items if there {@code docType} is not part of
 * {@code ingoredDocTypes}.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const deleteSubItems = module => ignoredDocTypes => async (components, ...args) => {
  const moduleState = module[G.STATE]
  const { data = [] } = moduleState
  const deleteSubItemFn = deleteSubItem(module)

  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    if (!ignoredDocTypes.includes(item.value.docType)) {
      await deleteSubItemFn(item.value.docType, item.key)
    }
  }

  return [components, ...args]
}

export default deleteSubItems
