import settings from '@tenant/settings'

/**
 * List of keys that define a customer role
 * @type {string[]}
 */
const customerRoleKeys = [
  'Customer',
  'Requester',
]

/**
 * User role type definition
 *
 * @typedef {object} userRole
 * @property {string} value                 the readable string of the user role
 * @property {string} key                   the role key
 * @property {boolean} hasMessages          whether this role can send/receive messages
 * @property {boolean} hasTeams             whether this role can have teams
 * @property {boolean} [canBeMentioned]     whether this role can be mentioned using {@link Mention}
 * @property {boolean} [canBeInvited]       whether this role is an acceptable invitation role
 * @property {boolean} [canBePublishedTo]   whether a request can be published to this role
 *                                          if {@code true}, checkbox "Publish to customer" in
 *                                          ticket wizard will be shown
 * @property {boolean} [canMaintainDevices] whether this role can maintain a service item. If
 *                                          {@code true}, the "add to maintained devices" checkbox
 *                                          will be shown in request/serviceItem wizard
 */

/**
 * User roles collection
 *
 * Contains a list of possible roles a user can have.
 *
 * @type {userRole[]} userRoles
 */
const userRoles = settings.roles
  || [
    {
      value: 'Waiting for approval',
      key: 'Requester',
      canBePublishedTo: true,
      canBeInvited: true,
      hasMessages: true,
      hasTeams: false,
    },
    {
      value: 'Verified customer',
      key: 'Customer',
      canBePublishedTo: true,
      canBeInvited: true,
      hasMessages: true,
      hasTeams: false,
    },
    {
      value: 'Blocked',
      key: 'Blocked',
      canBeMentioned: false,
      canBeInvited: false,
      hasTeams: false,
      hasMessages: false,
    },
    {
      value: 'Customer Service Agent',
      key: 'CustomerServiceAgent',
      canBeMentioned: true,
      canBeInvited: true,
      hasMessages: true,
      hasTeams: true,
    },
    {
      value: 'Customer Service Manager',
      key: 'CustomerServiceManager',
      canBeMentioned: true,
      canBeInvited: true,
      hasTeams: true,
      hasMessages: true,
    },
    {
      value: 'Customer Service Representative',
      key: 'CustomerServiceRepresentative',
      canBeMentioned: true,
      canBeInvited: true,
      hasMessages: true,
      hasTeams: true,
    },
    {
      value: 'Field Service Engineer',
      key: 'FieldServiceEngineer',
      canMaintainDevices: true,
      canBePublishedTo: true,
      canBeInvited: true,
      hasMessages: true,
      hasTeams: false,
    },
    {
      value: 'Customer Service External',
      key: 'CustomerServiceExternal',
      hasMessages: false,
      hasTeams: false,
    },
    {
      value: 'Customer Service Cooperator',
      key: 'CustomerServiceCooperator',
      canMaintainDevices: false,
      canBePublishedTo: true,
      canBeInvited: true,
      hasMessages: false,
      hasTeams: false,
    },
    {
      value: 'Sales',
      key: 'Sales',
      canBePublishedTo: true,
      canBeMentioned: true,
      canBeInvited: true,
      hasMessages: true,
      hasTeams: true,
    },
    {
      value: 'Customer Service Expert',
      key: 'CustomerServiceExpert',
      hasMessages: true,
      hasTeams: true,
    },
    {
      value: 'Master Data Administrator',
      key: 'MasterDataAdministrator',
      hasMessages: true,
      hasTeams: true,
    },
    {
      value: 'Instance Administrator',
      key: 'InstanceAdministrator',
      hasMessages: true,
    },
    {
      value: 'Technical Documentation and Parts',
      key: 'TechdocPartsReader',
      canMaintainDevices: false,
      canBePublishedTo: false,
      canBeInvited: false,
      hasMessages: false,
      hasTeams: false,
    },
    {
      value: 'Technical Documentation and Parts Admin',
      key: 'TechdocPartsAdmin',
      canMaintainDevices: false,
      canBePublishedTo: false,
      canBeInvited: false,
      hasMessages: false,
      hasTeams: false,
    },
    {
      value: 'Account In Shop',
      key: 'OrderInShop',
      canMaintainDevices: false,
      canBePublishedTo: false,
      canBeInvited: false,
      hasMessages: false,
      hasTeams: false,
    },
  ]

/**
 * Composite roles collection
 *
 * A list of role that define a composition of other {@link userRole}s.
 * @type {userRole[]} composite roles
 */
const compositeRoles = [
  {
    value: 'Customer',
    key: 'Customers',
    hasMessages: false,
    hasTeams: false,
  },
  {
    value: 'Customer service',
    key: 'CustomerService',
    hasMessages: false,
    hasTeams: false,
  },
]

/**
 * A list of {@link userRole} `key`s that are considered fundamental, meaning they can't
 * be further composed as opposed to {@link compositeRoles}.
 *
 * @type {string[]}
 */
const fundamentalRoles = [
  'FieldServiceEngineer',
  'CustomerServiceRepresentative',
  'CustomerServiceExternal',
  'CustomerServiceCooperator',
  'Sales',
]

/**
 * Reduced Roles Collection
 *
 * A list of reduced {@link userRole}s that consist of the concatenation
 * of all {@link fundamentalRoles} and {@link compositeRoles}.
 *
 * @type {userRole[]}
 */
export const reducedRoles = compositeRoles
  .concat(userRoles.filter(role => fundamentalRoles.includes(role.key)))

/**
 * Requester roles collection
 *
 * Contains a list of possible roles a Requester/Customer can have.
 *
 * @typedef {userRole[]} userRoles
 */
export const customerRoles = userRoles.filter(role => customerRoleKeys.includes(role.key))

export default userRoles
