/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'

/**
 * Sets generic fields based on validation.
 * Uses {@code payloadId} in args passed from redirect handler
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const submitFavorite = module => async (components, ...args) => {
  const [payloadId, options] = args
  const settingsAdapter = module[G.ADAPTER][G.SETTINGS]
  const { description } = components

  if (!module[G.STATE][G.ERROR]) {
    try {
      const result = !options?.delete
        ? await settingsAdapter[G.API].setFavorite({
          key: payloadId,
          value: {
            type: options?.type || 'generic',
            description: get(description),
          },
        })
        : await settingsAdapter[G.API].unsetFavorite({
          key: payloadId,
        })

      // eslint-disable-next-line no-param-reassign
      module[G.MODEL][G.STATE][G.ERROR] = !result
    } catch (e) {
      console.error(e)
    }
  }

  return args
}

/**
 * Favorite Submit Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  submitFavorite(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
