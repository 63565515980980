import noop from '@gaia/util/noop/action'
import _new from '@app/serviceItem/action/new'
import create from '@app/serviceItem/action/create'
import edit from '@app/serviceItem/action/edit'
import detail from '@app/serviceItem/action/detail'
import submit from '@app/serviceItem/action/submit'
import bulk from '@app/serviceItem/action/bulk'
import index from '@app/serviceItem/action'

export default {
  index,
  own: noop,
  new: _new,
  create,
  edit,
  detail,
  location: noop,
  equipmentDocumentation: noop,
  productDocumentation: noop,
  equipmentOptions: noop,
  submit,
  bulk,
}
