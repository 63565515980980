/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import session from 'app/_shared/session'

/**
 * If the current user has any of the roles defined in the component's
 * {@code excludeNotConfirmedForRoles} prop, returns a query to filter out all items that have
 * {@code notConfirmed: true} as a value property.
 *
 * @param {Gaia.AppModule.Spec} module    app module
 * @param {Gaia.Component.Spec} component action component
 * @param {Event} event                   information about the event that triggered this handler
 * @return {string}                       the resulting query or an empty string
 */
const excludeNotConfirmedForRoles = (module, component, event) => {
  const { excludeNotConfirmedForRoles: aclContextRoles = ['Requester', 'Customer'] } = component[G.PROPS]
  const { roles = [] } = session(module).context()
  return roles.some(value => aclContextRoles.includes(value)) ? '*:* -notConfirmed:true' : ''
}

export default curry(excludeNotConfirmedForRoles)
