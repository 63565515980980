/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'

/**
 * Update Event Handler
 *
 * Initializes an UI update.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        the event object that triggered this handler
 */
const update = (module, component, event) => {
  module[G.ADAPTER][G.UI].update(module)
}

export default curry(update)
