/* global G */
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { curry, setKey } from 'lib/util'
import { show } from 'lib/sequence/component/state/hidden'

/**
 * Show Alert Or Scroll To Bottom Event Handler
 *
 * Either shows an alert if the newest message is outside the visible area
 * or scrolls to the bottom of the list in case it is.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 */
const showAlertOrScrollToBottom = async (module, component, event) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  const { isVisible = true, message = {} } = event?.detail || event || {}

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { newMessageAlert, messagesContainer } = sequenceComponentFindProxy(actionComponent)

  const currentMessages = await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.MESSAGE, G.DONE), ({ detail }) => {
      const messages = detail[G.DATA]
      resolve(messages)
    }, { once: true })
    eventBus.dispatch(eventBus.type(G.MESSAGE, G.CACHE))
  })

  // Only apply the logic below if the message is a new one
  const localMessage = currentMessages.find(x => x.key === message.key || x.id === message.key)
  const isEditedOrDeleted = localMessage?.value?.editTimestamp || localMessage?.value?.deleteTimestamp

  if (!isEditedOrDeleted) {
    isVisible
      ? setKey({}, 'scrollBottom', messagesContainer[G.STATE])
      : show(newMessageAlert)

    module[G.ADAPTER][G.UI].update(module)
  }
}

export default curry(showAlertOrScrollToBottom)
