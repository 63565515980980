/* global G */
import { curry, isArr } from 'lib/util'
import countries from 'model/address/collection/country'

/**
 * From Country Decorator
 *
 * Decorator to retrieve valid G.DATA value and set it in G.CACHE
 *
 * G.DATA will always contain VALID User interaction results
 * G.DATA will probably be non-existent, if it hasn't been validated
 *
 * we get the value from the component G.STATE, if G.DATA is empty (invalid)
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} component    the component that matched the attribute name during the mapping
 */
const fn = (key, obj, component) => {
  const source = obj[G.CHILDREN][key]
  const cache = source[G.CACHE]
  // if it already is an array, it means it has been already decorated
  cache && !isArr(cache) && (source[G.CACHE] = countries.filter(entry => entry.key === cache))
  return component
}

export default curry(fn)
