import { curry } from 'lib/util'

/**
 * searchUsers Event Handler
 *
 * Calls `searchFn` and modifies the returned list by adding a `loginName` property to each one of
 * its items with the item's `key` as its value.
 *
 * @param {Function} searchFn search function used to retrieve data
 * @param {string} key - listing type, ie short, verbose, etc
 * @returns {Gaia.AppModule.EventHandler}
 */
const searchUsers = (searchFn, key = 'default') => curry(async (module, component, event) => {
  const result = await searchFn(key, module, component, event)
  const { data: items = result } = result
  console.log('items', items)
  const data = items.map(row => ({ ...row, value: { ...row.value, loginName: row.key } }))
  return { ...result, data }
})

export default searchUsers
