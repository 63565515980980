/* global G */
import { bulk, curry, setKey } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { reset } from 'lib/sequence/component/state/value'
import resetState from '@app/_shared/component/reset'

const bulkResetState = bulk(resetState)

/**
 * Cancel Update Time From Note Event Handler
 *
 * Cancels the changes to the form previously done by {@link updateTimeFromNote}
 * and resets the original state.
 *
 * @param {Gaia.Module} module        the current module composition object
 * @param {Gaia.Component} component  the current action's main component
 * @param {Gaia.PlatformEvent} event  the event object that triggered this handler
 * @returns {Promise<void>}
 */
const cancelUpdateTimeFromNote = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const {
    btnUpdate,
    btnOk,
    btnCancel,
    note,
    spentTime,
    startDate,
    list,
    updateInfo,
  } = sequenceComponentFindProxy(actionComponent)

  setKey(undefined, 'currentTimeNote', module[G.STATE])

  show(btnOk)
  hide(btnUpdate)
  hide(btnCancel)
  hide(updateInfo)
  reset(list)

  reset(note)
  reset(spentTime)
  reset(startDate)

  setKey(undefined, 'error', actionComponent[G.STATE])

  bulkResetState(spentTime, startDate, note)

  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(cancelUpdateTimeFromNote)
