/* global G */
import { curry, isArr } from 'lib/util'
import toRef from 'model/_shared/transformer/toRef'

const descriptor = 'model::transformer::organisation::toDuplicateOf'

/**
 * To DuplicateOf Transformer
 *
 * Does nothing, if the {@code duplicateOf} ref has been set to an empty array
 * in the incoming data, otherwise executes {@link toRef}.
 *
 * @param key
 * @returns {(function(...[*]): (*))|*}
 */
const fn = key => curry((obj, data) => {
  const item = obj[G.CHILDREN][key]
  const { type } = item[G.PROPS]
  const target = data?.[type]?.[key]

  if (target && isArr(target) && target.length === 0) {
    return data
  }

  try {
    return toRef(key, obj, data)
  } catch (e) {
    console.warn(descriptor, obj._name, key, e.message)
  }

  return data
})

export default fn
