import send from './api/send'
import edit from './api/edit'
import remove from './api/remove'
import get from './api/get'
import target from './target'

const message = {
  send,
  edit,
  remove,
  get,
}

export {
  message as default,
  send,
  edit,
  remove,
  target,
  get,
}
