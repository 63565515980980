/* global G */
import { curry, isArr } from '@gaia/util'

const _toRef = value => ({ key: '', value })

/**
 * To Country (Model) Transformer
 *
 * transforms input into an country value
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} data         the payload data processed until now
 * @return {Object}             the payload data
 */
const fn = (key, obj, data) => {
  const _data = data
  try {
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]
    const value = item[G.DATA].value || item[G.CACHE] || obj[G.DATA][key]
    _data[type][key] = (value && isArr(value) && value[0].key) || value
    !_data[type][key] && delete _data[type][key]
  } catch (e) {
    console.error(e)
  }
  return _data
}

export default curry(fn)
