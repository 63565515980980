/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import { asyncPipeSpread, getFirstItem } from 'lib/util'
import { get } from 'lib/sequence/component/state/value'
import find from 'lib/sequence/component/children/find'
import session from 'app/_shared/session'
import { checked } from 'lib/sequence/component/state/checked'
import { mapRefs } from 'app/admin/action/approve/partials/submit/composePayloadSubItems'
import search from 'app/_shared/events/search'
import submitPayload from 'app/admin/action/approve/partials/submit/submitPayload'
import deleteSubItems from 'app/admin/action/approve/partials/submit/deleteSubItems'
import checkModelErrorAndUpdateState from 'app/admin/action/approve/partials/submit/checkModelErrorAndUpdateState'

/**
 * List of document types that should be ignored when bulk deleting
 * @type {string[]}
 */
const ignoredDeleteDocTypes = [
  'request',
  'ticket',
  'person',
]

/**
 * Composes ticket notes to be created if the user selected the option
 * in the UI.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<[{payload: *[]},undefined,...*[]]>}
 */
const composePayloadTicketNotes = module => async (components, ...args) => {
  const moduleState = module[G.STATE]
  const { data = [] } = moduleState

  const { note: ticketNote, confirmTicketNote } = components
  const ticketNoteValue = get(ticketNote)
  const submitter = session(module).user.key()

  let payload = []

  if (!moduleState[G.ERROR] && data.length && checked(confirmTicketNote)) {
    const tickets = data.filter(item => item.value.docType === 'ticket')
    payload = tickets.reduce((acc, key) => {
      acc.push({
        type: 'note',
        refs: {
          parent: [key.key],
          submitter: [submitter],
        },

        value: {
          type: 'close_cancel',
          text: ticketNoteValue,
        },
      })

      return acc
    }, [])
  }

  return [{ payload }, components, ...args]
}

/**
 * Composes tickets to be updated. Will update their status to closed.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, *, ...[*]): Promise<[{payload: *[]},undefined,...*[]]>}
 */
const composePayloadTickets = module => async (previousData, components, ...args) => {
  const moduleState = module[G.STATE]
  const { data = [] } = moduleState

  let payload = []

  if (!moduleState[G.ERROR] && data.length) {
    const tickets = data.filter(item => item.value.docType === 'ticket')
    payload = tickets.reduce((acc, ticket) => {
      acc.push({
        type: 'ticket',
        _id: ticket.key,
        _rev: ticket._rev,
        refs: mapRefs(ticket.refs),
        value: {
          ...ticket.value,
          docType: undefined,
          postponeDate: null,
          status: 80,
          statusReason: 89,
        },
      })

      return acc
    }, previousData.payload)
  }

  return [{ payload }, components, ...args]
}

/**
 * Composes the user to be updated. Will update its status and role.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, *, ...[*]): Promise<[{payload: *[]},undefined,...*[]]>}
 */
const composePayloadUser = module => async (previousData, components, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const moduleState = module[G.STATE]
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { [G.STATE]: { [G.REF]: orgRef = null } = {} } = organisation
  const orgId = orgRef?.replaceAll(':', '\\:')

  let combinedPayload

  if (!moduleState[G.ERROR]) {
    try {
      const result = await search(null, module, actionComponent, { detail: {
        query: `metatype:user AND organisation:${orgId}`,
      } })

      const user = getFirstItem(result)
      combinedPayload = [
        ...previousData.payload,
        {
          type: 'user',
          _id: user.key,
          _rev: user._rev,
          refs: {
            ...mapRefs(user.refs),
            organisation: ['ORGANISATION:BA'],
          },
          value: {
            ...user.value,
            roles: user.value.roles.map(x => (x.includes(orgId) ? 'Blocked@ORGANISATION:BA' : x)),
            status: 90,
          },
        },
      ]
    } catch (e) {
      console.error(e)
    }
  }

  return [{ payload: combinedPayload }, components, ...args]
}

/**
 * Composes the person to be updated. Will update its status and validation.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, *, ...[*]): Promise<[{payload: *[]},undefined,...*[]]>}
 */
const composePayloadPerson = module => async (previousData, components, ...args) => {
  const moduleState = module[G.STATE]
  const { data = [] } = moduleState

  let payload = []

  if (!moduleState[G.ERROR] && data.length) {
    const persons = data.filter(item => item.value.docType === 'person')
    payload = persons.reduce((acc, person) => {
      acc.push({
        type: 'person',
        _id: person.key,
        _rev: person._rev,
        refs: mapRefs(person.refs),
        value: {
          ...person.value,
          toBeValidated: null,
          docType: undefined,
          status: 90,
        },
      })

      return acc
    }, previousData.payload)
  }

  return [{ payload }, components, ...args]
}

/**
 * Deletes the main organisation
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, *, ...[*]): Promise<[{payload: *[]},undefined,...*[]]>}
 */
const deleteOrganisation = module => async (...args) => {
  const { [G.HTTP]: httpAdapter } = module[G.MODEL][G.ADAPTER]
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { options: { version, api } } = organisation[G.CONFIGURATION]
  const { [G.STATE]: { [G.REF]: orgRef = null } = {} } = organisation

  const url = `/api/v${version}/${api}/${orgRef}`

  if (orgRef) {
    try {
      await httpAdapter.delete({ url })
    } catch (e) {
      console.error(e)
    }
  }

  return args
}

/**
 * Admin Action Submit Block
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  checkModelErrorAndUpdateState('ticket')(module),
  deleteSubItems(module)(ignoredDeleteDocTypes),
  composePayloadTicketNotes(module),
  composePayloadTickets(module),
  composePayloadUser(module),
  composePayloadPerson(module),
  submitPayload(true)(module),
  deleteOrganisation(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
