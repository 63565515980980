/**
 * Invite Status type definition
 *
 * @typedef {Object} inviteStatus
 * @property {String} key                 key of the invite status
 * @property {String} value               value of the invite status
 * @property {String} icon                icon to display
 * @property {String} color               color of the icon
 * @property {invitationAction[]} actions possible actions for the current invite status
 */

/**
 * Invitation action type definition
 *
 * @typedef {Object} invitationAction
 * @property {String} key             key representing the action
 * @property {String} value           a string representing the action
 * @property {String} newStatus       a string representing the invitation status the person should
 *                                    have after performing this action
 */

/**
 * Invitation Actions type definition
 *
 * @typedef {Object} invitationActions
 * @property {invitationAction} to_be_invited represents renewing an existing invitation.
 *                                            Will yield {@param to_be_invited} status.
 * @property {invitationAction} cancelled     represents revoking an ongoing invitation.
 *                                            Will yield {@param cancelled} status.
 */
const invitationActions = {
  to_be_invited: {
    key: 'to_be_invited',
    value: 'Renew invitation',
  },
  cancelled: {
    key: 'cancelled',
    value: 'Revoke invitation',
  },
}

/**
 * Invitation statuses collection
 *
 * Contains a list of possible status an invitation can have.
 *
 * @returns inviteStatus[]
 */
export default [
  {
    key: 'to_be_invited',
    value: 'To be invited',
    icon: 'fiber_manual_record',
    color: 'gray.800',
    showStatus: false,
    showDescription: false,
  },
  {
    key: 'invited',
    value: 'Invited',
    icon: 'fiber_manual_record',
    color: 'pending.500',
    actions: [
      invitationActions.to_be_invited,
      invitationActions.cancelled,
    ],
    showStatus: true,
    showDescription: true,
  },
  {
    key: 'expired',
    icon: 'fiber_manual_record',
    value: 'Expired',
    color: 'warning.500',
    actions: [
      invitationActions.to_be_invited,
      invitationActions.cancelled,
    ],
    showStatus: true,
    showDescription: false,
  },
  {
    key: 'completed',
    icon: 'fiber_manual_record',
    value: 'Completed',
    color: 'success.500',
    showStatus: true,
    showDescription: false,
  },
  {
    key: 'cancelled',
    icon: 'fiber_manual_record',
    value: 'Cancelled',
    color: 'gray.500',
    actions: [
      invitationActions.to_be_invited,
    ],
    showStatus: true,
    showDescription: false,
  },
  {
    key: 'confirmed',
    icon: 'fiber_manual_record',
    value: 'Confirmed',
    color: 'gray.500',
    showStatus: true,
    showDescription: false,
  },
]
