/* global G */
import { curry, setKey } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import validate from 'lib/sequence/model/validate'
import { fromTimeEntry } from 'app/ticket/event/note/time'
import { edit as editNote } from 'app/_shared/events/note'
import { actionWithHooks } from 'lib/sequence/module/action'
import { mapRefs } from 'app/ticket/event/note/time/delete'

/**
 * Update Time Entry Event Handler
 *
 * Update the time entry for a specific note.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      event that triggered this handler
 * @returns {Promise<void>}
 */
const updateTimeEntry = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const ticket = module[G.MODEL]
  const eventBus = module[G.ADAPTER][G.EVENTS]

  const { currentTimeNote: id, timeNotes } = module[G.STATE]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const currentTimeNote = timeNotes.find(x => x.key === id)

  const { note, startDate, spentTime, form } = sequenceComponentFindProxy(actionComponent)
  const time = get(spentTime)
  const text = get(note)
  const start = get(startDate)

  try {
    // Validation of spentTime
    await validate(ticket)(form)

    if (ticket[G.STATE][G.ERROR]) {
      setKey(true, 'error', actionComponent[G.STATE]) // this will trigger a rerender
      throw Error('Model validation error')
    }

    const spentSeconds = time
      ? fromTimeEntry(module, component, { value: time })
      : null

    /** @type Gaia.Model.Note */
    const item = {
      key: id,
      _rev: currentTimeNote._rev,
      spentTime: spentSeconds,
      startDate: start,
      text,
    }

    const done = await new Promise((resolve) => {
      eventBus.add(eventBus.type(G.NOTE, G.DONE), () => {
        resolve(true)
      }, { once: true })

      currentTimeNote && editNote(module, component, item)
    })

    if (done) {
      currentTimeNote.value = {
        ...currentTimeNote.value,
        spentTime: spentSeconds,
        startDate: start,
        text,
      }
      setKey(undefined, 'currentTimeNote', module[G.STATE])

      await actionWithHooks(module[G.STATE][G.ACTION])([])
      await module[G.ADAPTER][G.UI].create(module)
    }
  } catch (e) {
    console.error(e)

    await module[G.ADAPTER][G.UI].update(module)
  }
}

export default curry(updateTimeEntry)
