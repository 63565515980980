/* global G */
import { isArr } from 'lib/util'
import { get } from 'lib/sequence/component/state/value'

/**
 * Validates the selected role for the user
 *
 * @param {Gaia.Component.Spec} module current module
 * @returns {*}
 */
const validateUserRole = module => async (components, ...args) => {
  const parentModel = module[G.MODEL]
  const { user } = parentModel[G.CHILDREN]

  const { role } = components
  const roleValue = get(role)
  const hasRole = roleValue && isArr(roleValue) && roleValue.length !== 0

  user[G.STATE][G.ERROR] = !hasRole

  return [components, ...args]
}

export default validateUserRole
