/* global G */
import { curry } from 'lib/util'

const descriptor = 'model::transformer::dataToExistingRef'

/**
 * Attempts to add a list of refs selected by the UI to an existing array of {@param obj}'s refs.
 *
 * This transformer should be used, if an array of IDs, selected via the UI (e.g. via a multiple
 * select field) should be set as the list of a specific {@param obj}'s ref.
 * However, if we want to add {@param obj}'s ref to the list instead, use {@link refToExistingRef}
 * instead.
 *
 * The list of arrays set via the UI takes precedence over the already existing refs in G.CACHE.
 * This means if we remove one ref from the UI, it should also be removed from the payload. This
 * means we can just use {@code obj[G.CHILDREN][key][G.DATA].value}
 *
 * @param {string} key          key, expected to be provisioned in composition
 * @param {Gaia.Model.Spec} obj model, provided by sequence call
 * @param {Object} data         data, provided by sequence call
 * @return {*}
 */
const fn = (key, obj, data) => {
  console.warn(descriptor, key)

  const _data = data
  const item = obj[G.CHILDREN][key]
  const { type } = item[G.PROPS]

  try {
    const newRefs = item[G.DATA]?.value

    // TODO: What if we want to send the whole object instead of only the key? Another transformer?
    _data[type][key] = newRefs?.map(ref => ref.key) || []
  } catch (e) {
    console.warn(descriptor, obj._name, key, e.message)
  }

  return _data
}

export default curry(fn)
