/* global G */
import { curry } from 'lib/util'
import chain from 'app/_shared/events/util/chain'
import modelReset from 'app/_shared/events/model/reset'
import { next } from 'app/_shared/events/step'
import setStepTab, { SELECT } from 'app/_shared/events/setStepTab'
import persistCheckAndAction from 'app/_shared/events/combined/persistCheckAndAction'
import listTicketTypes from 'app/_shared/events/collection/listTicketTypes'

/**
 * Persist the request's type and modifies its content according to it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<*>}
 */
export const persistType = curry(async (module, component, event) => {
  await persistCheckAndAction(module, component, event)
  const currentType = component[G.STATE].value
  const availableTypes = await listTicketTypes(module, component, null)
  const type = availableTypes.find(option => option.key === currentType)

  /**
   * Only remove sub-models if they are not needed for current type in
   * config/model/request/ui/wizard/index/step1.json5:
   *
   * {
   *   key: 'other',
   *   value: 'Other',
   *   icon: 'more_horiz',
   *   // devices not available
   * }
   */
  type.deviceInput === false
    && chain(
      setStepTab(SELECT, 'device'), // resetting device step's tab
      modelReset('item'),
      modelReset('itemData'),
      modelReset('equipment'),
      modelReset('product'),
      modelReset('itemInstalledAt'),
    )(module, component, event)
})

export default chain(
  persistType,
  next,
)
