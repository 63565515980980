/* global G */
import { curry, setKey } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { reset, set } from 'lib/sequence/component/state/value'

/**
 * Update Time From Note Event Handler
 *
 * Updates the form to prepare it for editing a note.
 *
 * @param {Gaia.Module} module        the current module composition object
 * @param {Gaia.Component} component  the current action's main component
 * @param {Gaia.PlatformEvent} event  the event object that triggered this handler
 * @returns {Promise<void>}
 */
const updateTimeFromNote = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const { id } = event?.detail || event || {}

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { timeNotes } = module[G.STATE]

  setKey(id, 'currentTimeNote', module[G.STATE])

  const currentTimeNote = timeNotes.find(x => x.key === id)

  const {
    btnUpdate,
    btnOk,
    btnCancel,
    note,
    spentTime,
    startDate,
    list,
    updateInfo,
  } = sequenceComponentFindProxy(actionComponent)

  hide(btnOk)
  show(btnUpdate)
  show(btnCancel)
  show(updateInfo)
  set(note, currentTimeNote.value.text)
  set(spentTime, (currentTimeNote.value.spentTime / 60).toString())
  set(startDate, currentTimeNote.value.startDate)
  set(list, id)

  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(updateTimeFromNote)
