/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import { asyncPipeSpread, getFirstItem, isStr, poll } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import validateUserRole from 'app/admin/action/approve/partials/submit/validateUserRole'
import composePayloadSubItems, { mapRefs } from 'app/admin/action/approve/partials/submit/composePayloadSubItems'
import submitUser from 'app/admin/action/approve/partials/submit/submitUser'
import submitPayload from 'app/admin/action/approve/partials/submit/submitPayload'
import checkModelErrorAndUpdateState from 'app/admin/action/approve/partials/submit/checkModelErrorAndUpdateState'
import noLoaderMiddleware from 'app/admin/action/approve/partials/submit/middleware'

/**
 * Submits the organisation
 *
 * @param {boolean} showLoader whether or not to show the loader for this request
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const submitOrganisation = showLoader => module => async (components, ...args) => {
  const parentModel = module[G.MODEL]
  const httpAdapter = parentModel[G.ADAPTER][G.HTTP]

  const model = parentModel[G.CHILDREN].organisation
  const modelCache = model[G.CACHE]
  const validationError = parentModel[G.STATE][G.ERROR] || model[G.STATE][G.ERROR]

  const { [G.CONFIGURATION]: { options: { api, version } = {} } = {} } = model

  if (!validationError) {
    try {
      const params = {
        _id: modelCache.key,
        _rev: modelCache._rev,
        refs: mapRefs(modelCache.refs),
        value: {
          status: 90,
          toBeValidated: null,
          mergeToDuplicateTarget: true,
        },
      }
      const requestArgs = { url: `/api/v${version}/${api}/${modelCache.key}`, params }

      !showLoader
        ? await httpAdapter.put(requestArgs, { middleware: noLoaderMiddleware })
        : await httpAdapter.put(requestArgs)
    } catch (e) {
      console.error(e)
    }
  }

  return [components, ...args]
}

/**
 * Polls the server to return the related items and only continues if they all have been
 * processed by the server.
 *
 * @param {boolean} showLoader whether or not to show the loader for this request
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const waitForOrganisationChildren = showLoader => module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { [G.HTTP]: httpAdapter } = model[G.ADAPTER]
  const { options: { version } } = model[G.CONFIGURATION]
  const { organisation } = module[G.MODEL][G.CHILDREN]

  const { [G.STATE]: { [G.REF]: orgRef = null } = {} } = organisation
  const orgId = orgRef?.replaceAll(':', '\\:')

  const conditions = [
    `metatype:serviceitem AND tree_installedAt:${orgId}`,
    `metatype:person AND tree_organisation:${orgId}`,
    `metatype:organisation AND tree_parent:${orgId}`,
  ]

  const lucene = conditions.map(cond => `(${cond})`).join(' OR ')
  const requestArgs = { url: `/api/v${version}/search`, params: { lucene } }

  await poll(
    () => (!showLoader
      ? httpAdapter.post(requestArgs, { middleware: noLoaderMiddleware })
      : httpAdapter.post(requestArgs)),
    response => response?.value?.length === 0,
    2000,
  )

  return [components, ...args]
}

/**
 * Toggles the loader depending on whether {@param activate} is {@code true} or not.
 *
 * @param {boolean} activate whether to enable or disable the loader
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const displayLoader = activate => module => async (components, ...args) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  activate
    ? eventBus.dispatch(eventBus.type(G.LOAD, G.INIT))
    : eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))

  return activate
    ? [components, ...args]
    : args
}

/**
 * Fetches all the service items that are installed at the organisation that was selected as the
 * original and compares them with the service items that are installed at the duplicate
 * organisation (only the top org, not any sub organisations that the requester may have added).
 *
 * If there are any service items that have the same equipment ref, these service items will be
 * deleted (the ones attached to the duplicate organisation)
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const deleteDuplicateServiceItems = module => async (components, ...args) => {
  const parentModel = module[G.MODEL]
  const model = parentModel[G.CHILDREN].organisation
  const modelCache = model[G.CACHE]
  const { options: { version } } = model[G.CONFIGURATION]

  const { [G.HTTP]: httpAdapter } = model[G.ADAPTER]

  const moduleState = module[G.STATE]

  // Getting the id of the original organisation
  const { refs: { duplicateOf: duplicateOfRef } = {} } = modelCache || {}
  const duplicateOf = getFirstItem(duplicateOfRef)
  const duplicateOfId = duplicateOf && isStr(duplicateOf)
    ? duplicateOf
    : duplicateOf?.key

  // Getting the service items attached to the duplicate organisation
  const topOrgServiceItems = moduleState.data
    ? moduleState.data.filter(item => item.value.docType === 'serviceitem' && item.refs.installedAt[0].key === modelCache.key)
    : []

  const originalOrgServiceItemArgs = { url: `/api/v${version}/search`,
    params: { lucene: `metatype:serviceitem AND installedAt:${duplicateOfId.replaceAll(':', '\\:')}` },
  }

  // Fetching the service items attached to the original organisation. Only if we
  // actually have service items at the duplicate organisation, otherwise
  // we have nothing to do
  const originalOrgServiceItems = topOrgServiceItems.length
    ? await httpAdapter.post(originalOrgServiceItemArgs, { middleware: noLoaderMiddleware })
    : { value: [] }

  // Looping through the service items
  for (let i = 0; i < originalOrgServiceItems.value.length; i++) {
    // And finding service items at the duplicate org with same equipment
    const sameItemAtDuplicate = topOrgServiceItems.find(
      topOrgItem => topOrgItem.refs?.equipment?.[0]?.key
        && (topOrgItem.refs.equipment?.[0]?.key === originalOrgServiceItems.value[i].refs.equipment?.[0]?.key),
    )

    if (sameItemAtDuplicate) {
      try {
        const { item: serviceItemModel } = parentModel[G.CHILDREN].ticket[G.CHILDREN] || {}
        const {
          options: { api, version: serviceItemVersion } = {},
        } = serviceItemModel[G.CONFIGURATION] || {}

        // deleting the duplicate service item
        const url = `/api/v${serviceItemVersion}/${api}/${sameItemAtDuplicate?.key}`
        await httpAdapter.delete({ url }, { middleware: noLoaderMiddleware })
      } catch (e) {
        console.error(e)
      }
    }
  }

  return [components, ...args]
}

/**
 * Deletes the duplicate organisation after the merge is done.
 *
 * @param {Gaia.Component.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const deleteDuplicateOrganisation = module => async (components, ...args) => {
  const parentModel = module[G.MODEL]
  const model = parentModel[G.CHILDREN].organisation
  const modelCache = model[G.CACHE]
  const { options: { api, version } } = model[G.CONFIGURATION]

  const { [G.HTTP]: httpAdapter } = model[G.ADAPTER]

  // Getting the id of the original organisation
  const { refs: { duplicateOf: duplicateOfRef } = {} } = modelCache || {}
  const duplicateOf = getFirstItem(duplicateOfRef)
  const duplicateOfId = duplicateOf && isStr(duplicateOf)
    ? duplicateOf
    : duplicateOf?.key

  const orgId = modelCache?.key

  try {
    const url = `/api/v${version}/${api}/${orgId}`
    await httpAdapter.delete({ url }, { middleware: noLoaderMiddleware })
  } catch (e) {
    console.error(e)
  }

  // Returning duplicateOfId, See changeRefOfLastHistoryEntry hook
  return [components, { key: duplicateOfId }, ...args]
}

/**
 * Informs the user about unlinked tickets.
 *
 * NOTE: DEACTIVATED FOR NOW
 *
 * @param module
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
// const checkUnlinkedTicketsAndInformUser = module => component => async (components, ...args) => {
//   const model = module[G.MODEL]
//   const { [G.HTTP]: httpAdapter } = model[G.ADAPTER]
//   const { options: { version } } = model[G.CHILDREN].ticket[G.CONFIGURATION]
//
//   const moduleState = module[G.STATE]
//   const person = moduleState.data.find(item => item.value.docType === 'person')
//   const userRef = getFirstItem(person.refs.user)
//   const userId = userRef && isStr(userRef)
//     ? userRef
//     : userRef?.key
//
//   const ticketArgs = { url: `/api/v${version}/search`,
//     params: {
//       lucene: `metatype:ticket AND submitter:${userId.replaceAll(':', '\\:')}`,
//     },
//   }
//
//   const tickets = await httpAdapter.post(ticketArgs, { middleware: noLoaderMiddleware })
//   const unlinkedTickets = tickets?.value.filter(ticket => (ticket.refs.equipment || ticket.value.itemData.serial) && !ticket.refs.item)
//
//   // TODO: Implement proper dialog
//   // await showDialog(module, component, {
//   //   title: {
//   //     ns: 'admin',
//   //     key: 'dialog.passwordResetError.title',
//   //     defaultValue: 'User inactive',
//   //   },
//   //   text: {
//   //     ns: 'admin',
//   //     key: 'dialog.passwordResetError.text',
//   //     defaultValue: 'An inactive user cannot reset password',
//   //   },
//   // })
//
//   return [components, ...args]
// }

/**
 * Admin Action Submit As Duplicate Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  validateUserRole(module),
  checkModelErrorAndUpdateState('user')(module),
  composePayloadSubItems(module),

  displayLoader(true)(module),

  submitPayload(false)(module),
  deleteDuplicateServiceItems(module),
  submitOrganisation(false)(module),
  waitForOrganisationChildren(false)(module),
  submitUser(false)(module),
  deleteDuplicateOrganisation(module),
  // checkUnlinkedTicketsAndInformUser(module)(component),

  displayLoader(false)(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
