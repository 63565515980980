/* eslint-disable implicit-arrow-linebreak,indent,no-unused-expressions */
/* global PLATFORM */
import { curry } from 'lib/util'

/**
 * Platform Util Event Handler.
 *
 * Executes the event handler assigned to the property of {@param platforms} that has the same name
 * as the current platform.
 *
 * @example
 * {
 *   onChange: {
 *     persistChange: platform({
 *       mobile: persistChange,
 *       web: chain(
 *         persistChange,
 *         resetForm,
 *       ),
 *     }),
 *   }
 * }
 *
 * @param {Object} platforms              an object consisting of properties with platform names
 *                                        each with a reference to the event handler that should be
 *                                        executed on that platform.
 * @returns {Gaia.AppModule.EventHandler}
 */
export default platforms => curry(async (module, component, event) => {
  event?.persist?.()
  const eventHandler = platforms[PLATFORM]
  eventHandler && await eventHandler(module)(component)(event)
})
