/* global G */
import { curry, setKey } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

/**
 * Delete Role In List Event Handler
 *
 * Updates the list's `data` property by deleting the element that matches
 * `event.detail.index`.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 */
const deleteRoleInList = (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { rolesList } = sequenceComponentFindProxy(actionComponent)

  const { data: oldData } = rolesList[G.STATE]

  const newData = oldData.map((item) => {
    if (item.key === event?.detail?.index) {
      return { ...item, value: { ...item.value, delete: !!event.detail.checked } }
    }
    return item
  })

  setKey(newData, 'data', rolesList[G.STATE])
  rolesList[G.STATE].update = {}

  module[G.ADAPTER][G.UI].update(module)
}

export default curry(deleteRoleInList)
