/* eslint-disable no-unused-expressions,no-plusplus,prefer-destructuring,object-curly-newline */
/* global G */
import { bulk } from 'lib/util'
import { hide } from 'lib/sequence/component/state/hidden'
import { set } from 'lib/sequence/component/state/value'
import sequenceComponentState from 'lib/sequence/component/state'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import session from 'app/_shared/session'
import { CONFIRM, LIST, SEARCH, SELECT } from 'app/_shared/events/setStepTab'
import initSerialImage from 'app/_shared/action/partial/initSerialImage'
import initSerialField from 'app/_shared/action/partial/initSerialField'

const {
  unset: clearError,
} = sequenceComponentState('error')

const {
  unset: clearHelperText,
} = sequenceComponentState('helperText')

const clear = component => clearError(component) && clearHelperText(component)
const bulkHide = bulk(hide)

/**
 * Device identification step action.
 *
 * Handles the display and status of the step's elements.
 *
 * Displays 3 different sub-steps:
 * - If the current user can see any service item, a list of selectable service items will be shown.
 * - If the user cannot see any service item or clicks on "Select another device", a search section
 *    will be shown, allowing the user to find a service item, an equipment or a product.
 * - If the search doesn't return any item, the serial number is kept and the "Cannot identify
 *    device?" button is displayed. Clicking on it brings to a list of selectable products.
 *
 * The selected/found elements will be used to compose the request object.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {function(*=): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { itemData, item } = model[G.CHILDREN]
  const stepState = component[G.STATE]

  // Check maintainedBy ref so we know if we should hide it
  const { person } = session(module)
  const itemCache = item[G.CACHE]
  const maintainedByRef = itemCache?.refs?.maintainedBy || []
  const isMaintainedByUser = maintainedByRef.some(user => user === person.key())
  const components = sequenceComponentFind(component)

  const {
    existingHelp,
    btnMore,
    productName,
    form,
    deviceInfo,
    addToMaintainedDevices,
  } = components

  const { serial: serialField } = asObject(form[G.CHILDREN])
  const { serial: serialNumber } = asObject(deviceInfo[G.CHILDREN])

  /**
   * Show addToMaintainedDevices checkbox if device is not already maintained by user.
   * We don't need to show it explicitly because it will already be shown if
   * (and only if) the user has role FieldServiceEngineer
   */
  isMaintainedByUser && hide(addToMaintainedDevices)

  // if there is a selected product
  if (itemData[G.CACHE]) {
    // set the step as complete
    stepState.completed = true
    // fill step's title and subtitle with model data
    stepState.title = itemData[G.CACHE].value.name
    stepState.subtitle = itemData[G.CACHE].value.serial
  } else {
    // set step as incomplete and unconfirmed
    stepState.completed = false
    // clear step's title and subtitle
    stepState.title = ''
    stepState.subtitle = ''
  }

  await initSerialImage(module)(components, ...args)
  await initSerialField(module)(components, ...args)

  // display sub-step according to step's mode
  // noinspection FallThroughInSwitchStatementJS
  switch (stepState.activeTab) {
    case SEARCH:
      // await initSerialImage(module)(components, ...args)
      // await initSerialField(module)(components, ...args)
      break
    case CONFIRM:
      set(serialNumber, itemData[G.CACHE].value.serial)
      set(productName, itemData[G.CACHE].value.name)
    case SELECT:
    case LIST:
    default:
      bulkHide(existingHelp, btnMore)
      clear(serialField)
  }
  return args
}
