/* global G */

/**
 *
 * Model Confirmation Validator
 *
 * validates data values on model level, propagating
 * to the same scope as children validators
 *
 * @param {Object} obj  the model object composition
 * @return {Object}     the call's arguments
 */
export default obj => async (uiState, key) => {
  const { value } = uiState
  const { options } = obj[G.CONFIGURATION].validator[key]
  const { target, error } = options

  const { [target]: targetField } = obj[G.DATA]

  if (value !== targetField) {
    throw TypeError(error)
  }
  return [uiState, key]
}

// export default (value, options) => (re.test(value) ? true : throw TypeError(options.error))
