import noop from '@gaia/util/noop/action'
import messageAttachments from 'app/_shared/action/messageAttachments'
import create from '@app/ticket/action/create'
import edit from '@app/ticket/action/edit'
import bulk from '@app/ticket/action/bulk'
import bulkForm from '@app/ticket/action/bulkForm'
import attachments from '@app/ticket/action/attachments'
import organisation from '@app/ticket/action/form/organisation'
import contact from '@app/ticket/action/form/contact'
import party from '@app/ticket/action/form/party'
import index from '@app/ticket/action/list'
import own from '@app/ticket/action/list/own'
import team from '@app/ticket/action/list/team'
import detail from '@app/ticket/action/detail/index'
import requester from '@app/ticket/action/detail/requester'
import device from 'app/ticket/action/detail/device'
import additionalParty from 'app/ticket/action/detail/additionalParty'
import description from '@app/ticket/action/detail/description'
import forward from '@app/ticket/action/quick/forward'
import form from '@app/ticket/action/form'
import confirm from '@app/ticket/action/confirm'
import sendNote from '@app/ticket/action/sendNote'
import timeTracking from '@app/ticket/action/timeTracking'
import showAttachments from 'app/_shared/action/showAttachments'
import created from 'app/ticket/action/created'
import editMessage from 'app/ticket/action/editMessage'

export default {
  index,
  own,
  simple: index,
  unfiltered: noop,
  team,
  form,
  create,
  edit,
  organisation,
  contact,
  party,
  bulk,
  bulkForm,
  bulkEdit: bulk,
  sendNote,
  created,
  confirm,
  detail,
  requester,
  device,
  additionalParty,
  description,
  attachments,
  noteAttachments: noop,
  messageAttachments,
  editMessage,
  showAttachments,
  forward,
  postpone: noop,
  close: noop,
  reopen: noop,
  stop: noop,
  timeTracking,
}
