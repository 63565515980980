/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Save Tree In Module Event Handler
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 */
const saveTreeInActionState = (module, component, event) => {
  setKey(event.detail.value, G.STATE, module[G.STATE][G.ACTION])
}

export default curry(saveTreeInActionState)
