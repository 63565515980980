/* eslint-disable no-shadow,no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'
import chain from 'app/_shared/events/util/chain'
import { back, persistChange, persistOptions, redirect, submit } from '@app/_shared/events'
import { list as listBreadcrumbs, jump as jumpBack } from 'app/_shared/events/breadcrumbs'
import search from 'app/_shared/events/search'
import createRedirect from 'app/_shared/events/appbar/createRedirect'
import detail from 'app/_shared/events/action/detail'
import list from 'app/_shared/events/list'
import menu from 'app/_shared/events/contextmenu'
import link from 'app/_shared/events/link'
import { document, reference } from 'app/_shared/events/pubsub'
import infinite from 'app/_shared/events/search/infinite'
import component from 'app/_shared/events/acl/component'
import setCountedLabel from 'app/_shared/events/tabs/setCountedLabel'
import searchWith from 'app/_shared/events/search/with'
import listStatus from 'app/_shared/events/collection/listStatus'
import getUserRole from 'app/_shared/events/user/role'
import listUserRoles from 'app/_shared/events/collection/listUserRoles'
import listOrganisationTypes from 'app/_shared/events/collection/listOrganisationTypes'
import { model, permission } from 'app/_shared/events/acl'
import withModelKey from 'app/_shared/events/redirect/withModelKey'
import searchUnsortedAndRecreate from 'app/_shared/events/appbar/searchUnsortedAndRecreate'
import persistTab from 'app/_shared/events/tabs/persist'
import remember from 'app/_shared/events/remember'
import toPerson from 'app/team/event/toPerson'
import closeDialog from 'app/team/event/closeDialog'
import searchUsers from 'app/team/event/searchUsers'
import setListDrawerTitle from 'app/_shared/events/tabs/setListDrawerTitle'

export default {
  acl: {
    acl: component,
    component,
    permission,
    model,
  },
  onOpen: {
    search: search('verbose'),
    searchWithFilter: chain(
      searchWith(['filter']),
      search(null),
    ),
    searchVerboseWithFilter: chain(
      searchWith(['filter']),
      search('verbose'),
    ),
    listing: list(null),
    listVerbose: list('verbose'),
    listStatus,
    link,
    reference,
    infinite: infinite('verbose'),
    infiniteWithFilter: chain(
      searchWith(['filter']),
      infinite(null),
    ),
    setTabSearch: searchWith(['filter']),
    setTabSearchAndListDrawerTitle: chain(
      searchWith(['filter']),
      setListDrawerTitle,
    ),
    searchUsers: searchUsers(search),
    infiniteUsersWithFilter: chain(
      searchWith(['filter']),
      searchUsers(infinite),
    ),
    listBreadcrumbs,
  },
  onShowAll: {
    infiniteUsers: searchUsers(infinite, 'default'),
    infiniteUsersWithFilter: chain(
      searchWith(['filter']),
      searchUsers(infinite),
    ),
  },
  onPublication: {
    document,
  },
  onSearch: {
    searchUnsortedAndRecreate,
  },
  onCreate: {
    createRedirect,
  },
  onClose: {
    back,
    closeDialog,
  },
  onClick: {
    submit,
    submitContact: curry(async (m, c, e) => await submit(m, c, e, 'submitContact')),
    back,
    detail,
    closeDialog,
    redirect,
    redirectWithKey: withModelKey,
    toPerson,
    jumpBack,
  },
  onChange: {
    persistOptions,
    persistChange,
    persistTabAndRemember: chain(
      persistTab,
      remember,
    ),
  },
  onContextMenu: {
    menu: menu(null),
  },
  onPage: {
    remember,
  },
  onSecondaryAction: {
    confirmRemoval: curry(async (module, component, event) => {
      const moduleState = module[G.STATE]
      event.preventDefault()
      event.stopPropagation()
      moduleState[G.ROUTE] = routeComposition(null, 'remove')
      await redirectSequence(module)({})
    }),
    // removeMember: curry(async (module, component, event) => {
    //   console.log('Removed member')
    // }),
  },
  getLabel: {
    setLabel: setCountedLabel({ ns: 'team', key: 'members' }),
    listOrganisationTypes,
    listUserRoles,
  },
  getStatus: {
    listStatus,
  },
  getRole: {
    getUserRole: curry((module, component, roles) => getUserRole(roles, module, component, null)),
  },
  filter: {
    team: curry((module, component, event) => ({
      team: module[G.MODEL][G.STATE][G.REF],
    })),
    isActive: curry((module, component, event) => ({
      status: { max: 79 },
    })),
  },
}
