/* global G */
import { curry, isArr, isObj, isStr } from 'lib/util'
import roles from 'model/account/collection/roles'

/**
 * Attempts to filter {@link roles} to only contain those who match the part before the {@code @}
 * in {@param availableRoles}.
 *
 * @param {userRole[]} availableRoles   list of available {@link userRole}s.
 * @returns userRole
 */
const getUserRole = availableRoles => roles
  .find(role => availableRoles
    .map(availableRole => availableRole.substring(0, availableRole.indexOf('@')))
    .includes(role.key))

/**
 * Get the possible roles for a given user.
 *
 * @param {Gaia.AppModule.Spec} module              app module
 * @param {Gaia.Component.Spec} component           action component
 * @param {Gaia.PlatformEvent|string} eventOrKey    event that triggered this handler
 * @return {(userRole&{value: string})[]} user roles
 */
const listUserRoles = (module, component, eventOrKey) => {
  const rolesOrKey = eventOrKey?.detail || eventOrKey
  const key = rolesOrKey
    ? (isArr(rolesOrKey) && getUserRole(rolesOrKey)?.key)
      || (isObj(rolesOrKey) && rolesOrKey?.key)
      || (isStr(rolesOrKey) && rolesOrKey)
    : null

  const scope = key ? roles.filter(item => item.key === key) : roles

  return scope.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `roles.${item.key}`,
      {
        ns: 'custom',
        _key: `roles.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
}

/**
 * Get the possible roles of the user with an additional {@code filter}.
 *
 * @type {(function(...[*]): (*))|*}
 */
export const filterUserRoles = curry((filter, module, component, eventOrKey) => {
  const rolesOrKey = eventOrKey?.detail || eventOrKey
  const key = rolesOrKey
    ? (isArr(rolesOrKey) && getUserRole(rolesOrKey)?.key)
      || (isObj(rolesOrKey) && rolesOrKey?.key)
      || (isStr(rolesOrKey) && rolesOrKey)
    : null

  let scope = filter ? roles.filter(item => item[filter] === true) : roles
  scope = key ? scope.filter(item => item.key === key) : scope

  return scope.map(item => ({
    ...item,
    value: module[G.ADAPTER][G.INTL]._t(
      `roles.${item.key}`,
      {
        ns: 'custom',
        _key: `roles.${item.key}`,
        defaultValue: item.value,
      },
    ),
  }))
})

export default curry(listUserRoles)
