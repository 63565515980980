/* global G */
import settings from '@tenant/settings'
import { asyncPipeSpread, bulk, def, getFirstItem, setKey } from 'lib/util'
import { empty } from 'lib/util/object'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { disable, disabled, enable } from 'lib/sequence/component/state/disabled'
import { get, set } from 'lib/sequence/component/state/value'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { check, checked } from 'lib/sequence/component/state/checked'
import listStatus from 'app/_shared/events/collection/listStatus'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)
const bulkDisable = bulk(disable)

/**
 * Presets the {@code serialNumber}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetSerial = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]

  const { serial: serialModel } = model[G.CHILDREN]
  const { serial: serialData = serialModel } = model[G.DATA]

  const serialValue = serialData[G.CACHE] || serialData

  // component
  const { serialNumber } = children

  // setting the serial value from model if there is no one set
  serialValue && !get(serialNumber) && set(serialNumber, serialValue)

  return [children, ...args]
}

/**
 * Presets {@code name} with the proper {@code product}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetProduct = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const { product: newProduct } = model[G.DATA]

  // component
  const { name } = children

  // if a new product is selected/found...
  if (newProduct?.length) {
    // ...we set its name for the new service item...
    set(name, newProduct[0].value.name)
    // ...and disable the name field
    disable(name)
  } else {
    // ...otherwise, we set the current name from the model...
    !get(name) && set(name, model[G.CACHE].value.name)
    // ...and enable the name field
    enable(name)
  }

  return [children, ...args]
}

/**
 * Preset the {@code status} field.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const presetStatus = module => async (children, ...args) => {
  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { status: statusField } = children

  const status = getFirstItem(listStatus(module, actionComponent, get(statusField)))

  // Only set the field if it's editable
  status
  && !hidden(statusField)
  && !disabled(statusField)
  && set(statusField, status)

  return [children, ...args]
}

/**
 * Determines whether {@code serialNumber} and {@code name} should be disabled based on the
 * {@code equipment}.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayEquipment = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const { equipment: newEquipment } = model[G.DATA]
  const equipment = model[G.CHILDREN].equipment[G.CACHE]

  // component
  const { name, serialNumber } = children

  // in any case, if the current model has an equipment, and no new equipment is found...
  if (equipment?.length && !newEquipment?.length) {
    // ...we don't allow its serial and name to be changed
    bulkDisable(serialNumber, name)
  }

  return [children, ...args]
}

/**
 * Determines whether {@code toBeValidated} should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayToBeValidated = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const { toBeValidated: toBeValidatedModel } = model[G.CHILDREN]
  const toBeValidatedData = toBeValidatedModel?.[G.CACHE]

  // component
  const { component, toBeValidated } = children

  const { verify } = sequenceComponentFind(component[G.ACTIONS][0])

  // Only showing toBeValidated section if it has content
  def(toBeValidatedData) && !empty(toBeValidatedData)
    ? bulkShow(toBeValidated, verify)
    : bulkHide(toBeValidated, verify)

  return [children, ...args]
}

/**
 * Displays the 'confirm' checkbox only if the current model has a defined value for its
 * notConfirmed attribute.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayConfirm = module => async (children, ...args) => {
  if (settings.enableDevicesConfirmation) {
    const model = module[G.MODEL]
    const { notConfirmed } = model[G.CACHE].value

    const { confirm } = sequenceComponentFind(children.component[G.ACTIONS][0])

    // setting initial field value according to the model
    !def(get(confirm, true)) && check(confirm, !notConfirmed)

    show(confirm)
  }

  return [children, ...args]
}

/**
 * Saves the {@code verify} status for later use by the transformer.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const saveVerifyStatus = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const modelState = model[G.STATE]

  // component
  const { component } = children
  const { verify } = sequenceComponentFind(component[G.ACTIONS][0])

  setKey(checked(verify), 'verified', modelState)

  return [children, ...args]
}

/**
 *
 * ServiceItem action edit
 *
 * Assigns the equipment found by searchSerial (if any) to the current model and the name of its
 * product as the Service Item's name.
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  presetSerial(module),
  presetProduct(module),
  presetStatus(module),
  displayEquipment(module),
  displayToBeValidated(module),
  displayConfirm(module),
  saveVerifyStatus(module),
)(sequenceComponentFind(component), ...args)
