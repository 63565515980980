/* global G */
import asObject from 'lib/sequence/component/children/asObject'

const descriptor = Symbol('hook::module::stepper').toString()

/**
 * Stepper Hook
 *
 * Configures a {@link VerticalStepper} component placed at the action component's root.
 *
 * It keeps track of the actual active step by also considering those steps that are hidden, and
 * stores it inside the stepper state's {@code currentStep} property. It also sets the current step
 * state as visited.
 *
 * @param {Object} obj  the current module composition object
 * @return {function(...[*]): *[]}
 */
const hook = obj => (...args) => {
  try {
    const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
    const { stepper } = asObject(actionComponent[G.CHILDREN])
    const stepperState = stepper[G.STATE]
    const steps = asObject(stepper[G.CHILDREN])
    // grouping all step actions and obtaining a list with their names
    const actionKeys = Object.keys(asObject(stepper[G.CHILDREN]))
    // obtaining the current step according to the stepper state (the hidden ones don't count)
    let { activeStep = 0 } = stepperState
    stepperState.activeStep = activeStep
    // looping steps
    actionKeys.filter(key => steps[key]).forEach((key, index) => {
      const step = steps[key]
      const stepState = step[G.STATE]
      const stepProps = step[G.PROPS]
      // finding out whether it is hidden
      const { hidden } = { ...stepProps, ...stepState }
      if (!hidden) { // if it is not...
        if (index === activeStep) { // ...and its index is the active one according to the stepper
          stepperState.currentStep = key // ...it is the current step...
          stepState.visited = true // ...and we are visiting it
        }
      } else { // if it is hidden...
        activeStep += 1 // ...we add 1 to the index we obtained from the stepper
      }
    })
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }

  return args
}

export default hook
