/* global G */
import { curry, deleteKey, setKey } from 'lib/util'
import { back } from 'app/_shared/events'
import { edit } from 'app/_shared/events/message'
import { get } from 'lib/sequence/component/state/value'
import { apply as applyAttachments, get as getAttachments } from 'app/_shared/events/attachment'
import { setDetailUndoKey } from 'app/ticket/action/detail'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import updateAttachments from 'app/_shared/events/message/updateAttachments'

/**
 * Edit And Back From Message Event Handler
 *
 * Updates a given message alongside its attachments. Goes back afterward.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @return {Promise<void>}
 */
const sendAndBackFromMessage = async (module, component, event) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  event.preventDefault()
  event.stopPropagation()

  setKey(null, G.UNDO, module[G.STATE][G.ACTION][G.STATE])
  setDetailUndoKey(module, 'message', false)

  try {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { messageField } = sequenceComponentFind(actionComponent)
    const attachments = await getAttachments(module, component, event)
    const attachmentsCount = attachments.length

    const text = get(messageField)

    const { currentMessage: message = {} } = module[G.STATE][G.ACTION][G.STATE]
    const key = message?.key

    const callback = async (item) => {
      const { value } = item
      // Setting the message key so that the attachment adapter knows where
      // to upload
      setKey(key, G.REF, component[G.STATE])

      // The attachment adapter works by deleting the attachments (that need to be deleted)
      // before uploading the ones that need to be uploaded (take a look at it's {@link apply}
      // function). That means we know when the whole process is finished, only by looking at
      // the local attachments and feeding them to {@link updateAttachments}.
      const localAttachments = attachments.filter(attachment => attachment?.value?.name && (attachment.key === attachment.value.name))

      applyAttachments(module, component, event)
      await updateAttachments(module, component, localAttachments)

      // Removing the loader again after we are done
      eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))

      // localAttachments changed, and therefore attachments changed. So we already have
      // the latest state of the attachments in `attachments`.
      value.attachments = attachments

      eventBus.dispatch(eventBus.type(G.MESSAGE, G.CHANGE), { [G.DATA]: {
        ...item,
        value: {
          ...item.value,
          attachments,
        },
      } })

      // Cleaning up the module state
      deleteKey('currentMessage', module[G.STATE])

      await back(module, component, event)

      return true
    }

    const editEvent = {
      ...event,
      detail: {
        data: { key, text },
        callback,
        ...attachmentsCount && { hasAttachments: attachmentsCount },
      },
    }

    await edit(module, component, editEvent)
  } catch (e) {
    console.error(e)
  }
}

export default curry(sendAndBackFromMessage)
