/* global G */
/* eslint-disable no-unused-vars */
import { curry, isArr, setKey } from 'lib/util'
import { back } from 'app/_shared/events'
import routeComposition from 'trait/composition/route'
import redirectSequence from '@gaia/sequence/module/adapter/router/redirect'

export default {
  acl: {
    noop: curry((m, c, e) => true),
  },
  onClick: {
    goBackOrHome: curry(async (module, component, event) => {
      const history = module[G.ADAPTER][G.SESSION][G.STATE][G.PREV]
      const { key = '' } = module[G.STATE][G.ACTION][G.STATE]
      const lastRoute = history && isArr(history) && history.length
        ? history[history.length - 1]
        : null

      // If the ref of the last route is equal to the key we got, it means
      // that we have route to a non-existent document in the history.
      // Therefore, delete it
      if (lastRoute && key && lastRoute?.[G.REF] === key) {
        history.pop()
      }

      const isLogged = !!module[G.ADAPTER][G.SESSION][G.STATE][G.META]
      const cameFromContext = lastRoute?.[G.MODULE] === 'guest'
          && lastRoute[G.ACTION] === 'context'
      const canGoBack = history.length && (!isLogged || !cameFromContext)

      if (canGoBack) {
        await back(module, component, event)
      } else {
        const defaultRoute = module[G.ADAPTER][G.ROUTER].defaultRoute()
        const nextRoute = routeComposition(defaultRoute.module, defaultRoute.action)
        setKey(nextRoute, G.ROUTE, module[G.STATE])

        await module[G.ADAPTER][G.UI].provide(module)
        await redirectSequence(module)({})
      }
    }),
  },
}
