/* global G */
import { curry } from 'lib/util'
import { remove as deleteNote } from 'app/_shared/events/note'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { purge } from 'app/_shared/events/attachment'

/**
 * Event Handler Search Step and Recreate
 *
 * Sets the event detail's value as current search term for the list of the current step and
 * recreates the module, so that it is taken into account the next time the list is rendered.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Gaia.PlatformEvent} event    information about the event that triggered this handler
 * @return {Promise<void>}
 */
const del = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const confirm = await showBlockingDialog(module, component, {
    title: {
      ns: 'ticket',
      key: 'dialog.deleteNote.title',
      defaultValue: 'Delete note',
    },
    text: {
      ns: 'ticket',
      key: 'dialog.deleteNote.text',
      defaultValue: 'Are you sure that you want to delete the note?',
    },
  })

  if (confirm) {
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { list } = sequenceComponentFindProxy(actionComponent)

    const { note } = event.detail || event || {}
    const noteAttachments = note?.value?.attachments || []

    const { options: { group = null } = {} } = list[G.CONFIGURATION]

    for (let i = 0; i < noteAttachments.length; i++) {
      const { key = null, id = null } = noteAttachments[i]
      const attachment = {
        ...noteAttachments[i],
        // 'key' may still be the local attachment name, but we set 'id'
        // to the correct remote id when we activated the message, so we
        // need to check for both, id should take prescedence.
        key: id || key,
      }

      attachment?.key && purge(module, component, group, attachment, event)
    }

    await deleteNote(module, component, { key: note.key })
    await module[G.ADAPTER][G.UI].update(module)
  }
}

export default curry(del)
