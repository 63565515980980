/**
 * @typedef internalOrganisation
 * @type {object}
 *
 * @property {string} key                   id of the organisation
 * @property {string} value                 name of the organisation
 * @property {boolean} canBePublished       whether an organisation that has this organisation
 *                                          as a parent can be published
 */

/**
 * Internal Organisation Collection.
 *
 * Contains a list of internal organisations to be checked against in various parts of the
 * application. The list contains the actual organisation IDs. They will never change, and,
 * at least at the moment, there is no other way of checking against them (like a flag or
 * something similar).
 *
 * @type {internalOrganisation[]} internalOrganisations
 */
const internalOrganisations = [
  {
    key: 'ORGANISATION:RA', // parent for all unapproved orgs
    value: 'Requester Administration',
    canBePublished: true,
  },
  {
    key: 'ORGANISATION:BA', // parent for all blocked users
    value: 'Blocked Administration',
    canBePublished: false,
  },
  {
    key: 'ORGANISATION:IA', // parent for all internal users
    value: 'Internal Administration',
    canBePublished: false,
  },
]

export default internalOrganisations
