import add from './add'
import edit from './edit'
import remove from './remove'
import get from './get'

const note = {
  add,
  edit,
  remove,
  get,
}

export {
  note as default,
  add,
  edit,
  remove,
  get,
}
