/* eslint-disable no-unused-vars */
/* global G */
import { curry, getFirstItem, isArr, isObj } from 'lib/util'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'

/**
 * Redirect To Approve As Original Or Duplicate Event Handler
 *
 * Redirects to the correct route configured in the component based on the
 * {@code duplicateOf} ref of the organisation
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<void>}
 */
const redirectToApproveAsOriginalOrDuplicate = async (module, component, event) => {
  const moduleState = module[G.STATE]
  const { organisation } = module[G.MODEL][G.CHILDREN]
  const { refs: { duplicateOf: duplicateOfRef = null } = {} } = organisation[G.CACHE] || {}

  const { originalRoute, duplicateRoute } = component[G.CONFIGURATION]

  const duplicateOf = getFirstItem(duplicateOfRef)
  const targetRoute = (duplicateOf === null && originalRoute)
    || (duplicateOf && isObj(duplicateOf) && duplicateRoute)
    || false

  if (targetRoute) {
    moduleState[G.ROUTE] = routeComposition(targetRoute.module, targetRoute.action)

    await redirectSequence(module)({})
  }
}

export default curry(redirectToApproveAsOriginalOrDuplicate)
