/* eslint-disable symbol-description */

const _Symbol = description => Symbol(description)

/**
 * G - Gaia Library Static Definitions.
 *
 * @type {Readonly<{
* INTL: symbol,
* API: symbol,
* CONFIGURATION: symbol,
* VIEW: symbol,
* MODEL: symbol,
* CHILDREN: symbol,
* ACTIONS: symbol,
* TEMPLATE: symbol,
* VALIDATOR: symbol,
* DB: symbol,
* UI: symbol,
* REF: symbol,
* COMPONENT: symbol,
* FN: symbol,
* EVENTS: symbol,
* APP: symbol,
* HOOKS: symbol,
* SESSION: symbol,
 * SETTINGS: symbol,
* PROVIDER: symbol,
* STATE: symbol,
* ADAPTER: symbol,
* ROUTER: symbol,
* DATA: symbol,
* DECORATOR: symbol,
* PLUGIN: symbol,
* ERROR: symbol,
* HTTP: symbol,
* CACHE: symbol,
* PROPS: symbol,
* GROUP: symbol,
* FILTER: symbol,
* STORAGE: symbol,
* PERSISTENCE: symbol,
* PARENT: symbol,
* LANGUAGE: symbol,
* ROUTE: symbol,
* CONTEXT: symbol,
* MODULE: symbol,
* ACTION: symbol,
* CURRENT: symbol,
* NEXT: symbol,
* PREV: symbol,
* ORIGIN: symbol,
* USER: symbol,
* LIST: symbol,
* META: symbol,
* LIMIT: symbol,
* OFFSET: symbol,
* PAGE: symbol,
* KEYS: symbol,
* KEY: symbol,
* MODAL: symbol,
* TRANSFORMER: symbol,
* ATTACHMENT: symbol,
* MESSAGE: symbol,
* NOTE: symbol,
* NOTIFICATION: symbol,
* CART: symbol,
* DRAWER: symbol,
* FRAME: symbol,
* STRIPE: symbol,
* CREATE: symbol,
* READ: symbol,
* UPDATE: symbol,
* DELETE: symbol,
* DRAFT: symbol,
* ACTIVATE: symbol,
* ADD: symbol,
* REMOVE: symbol,
* APPLY: symbol,
* UNDO: symbol,
* INIT: symbol,
* DESTROY: symbol,
* DONE: symbol,
* ACL: symbol,
* BULK: symbol,
* LOAD: symbol,
* INSERT: symbol,
* }>}
 */
const G = Object.freeze({
  INTL: _Symbol('intl'),
  API: _Symbol('api'),
  CONFIGURATION: _Symbol('configuration'),
  VIEW: _Symbol('view'),
  MODEL: _Symbol('model'),
  CHILDREN: _Symbol('children'),
  ACTIONS: _Symbol('actions'),
  TEMPLATE: _Symbol('template'),
  VALIDATOR: _Symbol('validator'),
  DB: _Symbol('db'), // not sure we need this one
  UI: _Symbol('ui'),
  REF: _Symbol('ref'),
  COMPONENT: _Symbol('component'),
  FN: _Symbol('fn'),
  EVENTS: _Symbol('events'),
  APP: _Symbol('app'),
  HOOKS: _Symbol('hooks'),
  SESSION: _Symbol('session'),
  SETTINGS: _Symbol('settings'),
  PROVIDER: _Symbol('provider'),
  STATE: _Symbol('state'),
  ADAPTER: _Symbol('adapter'),
  ROUTER: _Symbol('router'),
  DATA: _Symbol('data'),
  DECORATOR: _Symbol('decorator'),
  PLUGIN: _Symbol('plugin'),
  ERROR: _Symbol('error'),
  HTTP: _Symbol('http'),
  CACHE: _Symbol('cache'),
  PROPS: _Symbol('props'),
  GROUP: _Symbol('group'),
  FILTER: _Symbol('filter'),
  STORAGE: _Symbol('storage'),
  PERSISTENCE: _Symbol('persistence'),
  PARENT: _Symbol('parent'),
  ONLINE: _Symbol('online'),

  LANGUAGE: _Symbol('language'),
  ROUTE: _Symbol('route'), // route:[fn, module]
  CONTEXT: _Symbol('context'),
  MODULE: _Symbol('module'),
  ACTION: _Symbol('action'),
  CURRENT: _Symbol('current'),
  NEXT: _Symbol('next'),
  PREV: _Symbol('prev'),
  ORIGIN: _Symbol('origin'),
  USER: _Symbol('user'),

  LIST: _Symbol('list'),
  META: _Symbol('meta'),
  LIMIT: _Symbol('limit'),
  OFFSET: _Symbol('offset'),
  PAGE: _Symbol('page'),

  KEYS: _Symbol('keys'),
  KEY: _Symbol('key'),
  // MODAL: _Symbol('modal'),
  TRANSFORMER: _Symbol('transformer'),

  ATTACHMENT: _Symbol('attachment'),
  MESSAGE: _Symbol('message'),
  NOTE: _Symbol('note'),
  NOTIFICATION: _Symbol('notification'),
  CART: _Symbol('cart'),

  MODAL: _Symbol('modal'),
  DRAWER: _Symbol('drawer'),
  FRAME: _Symbol('frame'),
  MENU: _Symbol('menu'),

  CREATE: _Symbol('create'),
  READ: _Symbol('read'),
  UPDATE: _Symbol('update'),
  DELETE: _Symbol('delete'),
  DRAFT: _Symbol('draft'),
  ACTIVATE: _Symbol('activate'),
  ADD: _Symbol('add'),
  REMOVE: _Symbol('remove'),
  APPLY: _Symbol('apply'),
  UNDO: _Symbol('undo'),
  INIT: _Symbol('init'),
  DESTROY: _Symbol('destroy'),
  DONE: _Symbol('done'),
  ACL: _Symbol('acl'),
  BULK: _Symbol('bulk'),
  CHANGE: _Symbol('change'),
  INSERT: _Symbol('insert'),
  LOAD: _Symbol('load'),
})

module.exports = G
