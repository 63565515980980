/* eslint-disable no-unused-vars */
import { curry } from 'lib/util'
import concatQueryParams from 'app/_shared/events/search/query/util'
import session from 'app/_shared/session'
import { customerRoles } from 'model/account/collection/roles'

/**
 * From User Orgs Event Handlder
 *
 * Sets the {@code query} parameter for the current search, based on the given {@param type} in
 * and {@param includeUserOrgsAs}. Meant to be used with the {@code trees} object.
 *
 * @param {string} type                     type of document to look for in {@code trees}
 * @param {string} includeUserOrgsAs        if non-nil, will add a filter for each user role
 *                                          organisation equal to the value of this param,
 *                                          concatenated with {@code OR}. If the param is equal
 *                                          to {@code 'id'}, it would add
 *                                          {@code 'OR id:ORG\\:7 OR id:ORG\\:13'} if the user has
 *                                          two role organisations
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 * @returns {string}
 */
const fromUserOrgs = (type, includeUserOrgsAs, module, component, event) => {
  const { value: { roles = [] } = {} } = session(module).user.ref({ wrap: false }) || {}
  const customerRoleKeys = customerRoles.map(customerRole => customerRole.key)

  const customerRoleOrganisations = roles.reduce((acc, key) => {
    const [role, organisation] = key.split('@')
    if (customerRoleKeys.includes(role)) {
      acc.push(organisation.replace(':', '\\:'))
    }
    return acc
  }, [])

  const params = {
    treeOrgs: customerRoleOrganisations.length && type
      ? `tree_${type}:(${customerRoleOrganisations.join(' ')})`
      : '',
    userOrgs: includeUserOrgsAs
      ? `OR ${customerRoleOrganisations.map(x => `${includeUserOrgsAs}:${x}`).join(' OR ')}`
      : '',
  }

  return `(${concatQueryParams(params)})`
}

export default curry(fromUserOrgs)
