/* global G */
import { curry, setKey } from 'lib/util'
import { hide, show } from 'lib/sequence/component/state/hidden'
import asObject from 'lib/sequence/component/children/asObject'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { set } from 'lib/sequence/component/state/value'

/**
 * Edit Note Event Handler
 *
 * Shows the form to edit a note and sets the required fields.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @return {Promise<void>}
 */
const edit = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { notes } = asObject(actionComponent[G.CHILDREN])
  const { addNoteBtn, form, spentTime } = sequenceComponentFindProxy(notes)

  const { note } = event?.detail || event || {}

  hide(addNoteBtn)
  show(form)

  set(form, {
    id: note?.key,
    text: note?.value?.text,
    type: note?.value?.type,
  })

  note?.value?.spentTime && set(spentTime, (note.value.spentTime / 60).toString())

  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(edit)
