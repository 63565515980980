/* eslint-disable no-plusplus,no-unused-vars,max-len */
/* global G */
import { curry, deleteKey, setKey } from 'lib/util'
import { _group } from 'app/_shared/events/attachment'

/**
 * Clean up the residual event listeners and transient properties.
 *
 * @param {Gaia.AppModule.Spec} module              the current module composition object
 * @param {Gaia.Component.Spec} component           the current action's main component
 * @param {Gaia.Adapter.Attachment[]} [attachments] optional attachments for the note
 * @param {Function} handler                        event handler for each attachment
 * @private
 */
const _cleanup = (module, component, attachments, handler) => {
  const eventAdapter = module[G.ADAPTER][G.EVENTS]
  for (let i = 0; i < attachments.length; i++) {
    eventAdapter.remove(eventAdapter.type(G.ATTACHMENT, G.READ, attachments[i].key), handler)

    // Removing the local attachments we just successfully uploaded. So the user see's an empty
    // dropzone again and can upload something else.
    eventAdapter.dispatch(
      eventAdapter.type(G.ATTACHMENT, G.REMOVE, _group(component)),
      { [G.DATA]: attachments[i] },
    )
  }
}

/**
 * Attempts to activate attachments for a newly created note by registering an event
 * handler for each attachment, that will save the remote id of the attachment
 * to the note. After all attachments are done uploading, it will hide the loader
 * and unregister all event handlers
 *
 * @param {Gaia.AppModule.Spec} module              the current module composition object
 * @param {Gaia.Component.Spec} component           the current action's main component
 * @param {Gaia.Adapter.Attachment[]} [attachments] optional attachments for the note
 * @returns {Promise<boolean>}
 */
const activateAttachments = async (module, component, attachments) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  return new Promise((resolve) => {
    if (attachments.length) {
      for (let i = 0; i < attachments.length; i++) {
        const handler = ({ detail }) => {
          /**
           * The {@code G.ATTACHMENT, G.READ, key} event handler is the correct one to listen to.
           * It will get fired when the server returns a response after uploading an attachment.
           * However, it also gets fired before that. So in order to catch the correct event
           * we check for {@code status === 201}.
           */
          if (detail.key === attachments[i].key && detail.status === 201) {
            const key = detail?.response?.[0]?.id
            setKey(key, 'id', attachments[i])
          }

          if (!attachments.find(att => !att.id)) {
            _cleanup(module, component, attachments, handler)
            resolve(true)
          }
        }
        eventBus.add(eventBus.type(G.ATTACHMENT, G.READ, attachments[i].key), handler)
      }
    } else {
      resolve(true)
    }
  })
}

export default curry(activateAttachments)
