/* eslint-disable no-nested-ternary */
/* global G */
import { curry, getFirstItem, isArr, isObj, setKey } from 'lib/util'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import find from 'lib/sequence/component/children/find'
import { get, set } from 'lib/sequence/component/state/value'

/**
 * Change At Orgs Event Handler
 *
 * If the user has roles with the same organisation as `event.detail.item`, it will
 * show a dialog that, if confirmed, will set the `atOrg` property of all roles to
 * the currently chosen organisation.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        the event object that triggered this handler
 * @returns {Promise<void>}
 */
const changeAtOrgs = async (module, component, event) => {
  event.preventDefault()
  event.stopPropagation()

  const { item } = event?.detail || event || {}

  if (item) {
    const newOrg = item?.value?.name

    // component
    const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
    const { rolesList, organisation, newAtOrganisation } = find(actionComponent)
    const oldOrganisationKey = getFirstItem(get(organisation))?.key || null
    const { data: oldData } = rolesList[G.STATE]

    const currentRoles = !oldData ? [] : isArr(oldData) ? oldData : Object.values(oldData)
    const canChangeRoles = currentRoles?.some(x => x?.value?.atOrg === oldOrganisationKey)

    let confirmed = false
    if (canChangeRoles) {
      confirmed = await showBlockingDialog(module, component, {
        title: {
          ns: 'admin',
          key: 'dialog.changeOrg.title',
          defaultValue: 'Change organization',
        },
        text: {
          ns: 'admin',
          key: 'dialog.changeOrg.text',
          defaultValue: 'Do you want to change the user organisation to {{type}} in roles?',
          type: newOrg,
        },
      })
    }

    if (confirmed) {
      // Replacing the new user organisation in all roles that had the old organisation if the user
      // confirmed the dialog.
      const newData = isArr(oldData)
        ? oldData.map(role => (role?.value?.atOrg === oldOrganisationKey
          ? { ...role, value: { ...role.value, atOrg: item?.key } }
          : role))
        : Object.keys(oldData).reduce((acc, key) => ({
          ...acc,
          [key]: { ...oldData[key],
            ...oldData[key]?.value?.atOrg === oldOrganisationKey && { atOrg: item?.key } },
        }), {})

      // Setting the new roles
      setKey(newData, 'data', rolesList[G.STATE])

      // In case the user has added a new role/org below the list of roles, we need to change the
      // org there as well
      const newAtOrg = isObj(newData) ? newData?.new : false
      newAtOrg?.atOrg === item?.key && set(newAtOrganisation, item)
      rolesList[G.STATE].update = {}
    }
  }
}

export default curry(changeAtOrgs)
