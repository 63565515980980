/* global G */

import { asyncPipeSpread, isNum } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { isAction } from 'app/_shared/action/util'
import { hidden } from 'lib/sequence/component/state/hidden'
import validate from 'lib/sequence/model/validate'

/**
 * Validates the `inviteForm` component and, if its data is valid, saves it in the current model's
 * `invite` attribute.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @return {function(*, ...[*]): Promise<*[]>}
 */
const validateInviteForm = module => async (children, ...args) => {
  if (isAction(module, 'newContact')) {
    const model = module[G.MODEL]
    const { inviteForm } = children
    const { invite } = model[G.CHILDREN]

    !hidden(inviteForm) && await validate(invite)(inviteForm)
  }
  return [children, ...args]
}

/**
 * Sets the module state based on errors in the model. It will pass the result as
 * {@code validationError} down the chain so that following function can skip their functionality
 * if an error is present.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setModuleState = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  return [validationError, children, ...args]
}

/**
 * Sets a default status in case there isn't one set already (by IA for example) when we are
 * creating a person.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {(function(*, *, ...[*]): Promise<*[]>)|*}
 */
const setStatusOnCreate = module => async (validationError, children, ...args) => {
  // Skip this function if we have a validation error
  if (validationError) return args

  // model
  const model = module[G.MODEL]

  if (isAction(module, 'newContact')) {
    // Defaulting to 50 if no status is set (0 is a valid status as well!)
    const statusData = model[G.DATA]?.status
    model[G.DATA].status = !isNum(statusData) ? 50 : statusData
  }

  if (isAction(module, 'newColleague')) {
    model[G.DATA].firstName = '-'
    model[G.DATA].lastName = '-'
  }

  return args
}

/**
 * Person Submit Action
 *
 * @param {Gaia.AppModule.Spec} module  the current module object composition
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  validateInviteForm(module),
  setModuleState(module),
  setStatusOnCreate(module),
)(sequenceComponentFind(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
