/* global G */
import { curry, setKey } from 'lib/util'
import redirectSequence from '@gaia/sequence/module/adapter/router/redirect'
import routeComposition from '@gaia/trait/composition/route'

/**
 * Detail Event Handler
 *
 * It executes module action detail() by "Fire-N-Forget"
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.AppModule.Action} component - action component
 * @param {Event} event               the event that triggered this handler
 * @param {Object} event.detail       custom event details
 * @param {string} event.detail.key   a model key to pass to {@link redirectSequence}
 * @return {Promise<Event>} void
 */
const detail = async (module, component, event) => {
  try {
    const { key } = event.detail
    const moduleState = module[G.STATE]
    const { route } = component[G.CONFIGURATION]
    moduleState[G.ROUTE] = routeComposition(route.module, route.action)
    setKey(key, G.REF, module[G.MODEL][G.STATE])
    await redirectSequence(module)({ key })
  } catch (e) {
    console.error(e)
  }
  return event
}

export default curry(detail)
