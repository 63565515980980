/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { checked } from 'lib/sequence/component/state/checked'
import validate from 'lib/sequence/model/validate'

/**
 * Validates the main model
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const validateTeam = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  await validate(model)(actionComponent)

  return [components, ...args]
}

/**
 *
 * Validates the notify checkboxes.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const validateNotify = module => async (components, ...args) => {
  const { members, team, msgNotifyError } = components

  const hasError = !checked(members) && !checked(team)

  if (!module[G.STATE][G.ERROR]) {
    setKey(hasError, G.ERROR, module[G.STATE])
  }

  hasError
    ? show(msgNotifyError)
    : hide(msgNotifyError)

  return args
}

/**
 * Team Admin Submit Action
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 *
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  validateTeam(module),
  validateNotify(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)
