/* global G */
import { curry, def, isArr, isStr } from 'lib/util'

const descriptor = 'model::transformer::refToExistingRef'

/**
 * Attempts to add {@param obj}'s ref to an existing array of refs.
 *
 * This transformer should be used, if the goal is to add the ref of the current model to an array
 * of existing refs. Meaning if we want to add the current userId, serviceItemId, whateverId to a
 * list of ids amongst the existing refs.
 * If we want to add an array of IDs that have been determined through the UI (e.g. via a multiple
 * select field), then use {@link dataToExistingRef}.
 *
 * If there are no existing refs, it will add the new ref as the sole item
 * @example
 * ref: "PERSON:1", existing refs: [] -> ["PERSON:1"]
 *
 * If there are existing refs, it will concatenate the new ref to it.
 * @example
 * ref: "PERSON:1", existing refs: ["PERSON:2"] -> ["PERSON:2", "PERSON:1"]
 *
 * If the ref to be added is already in the array of existing refs, it will not add it again.
 * If there is no ref to be added, it will delete the {@param key} in question from the data
 * sent to the server.
 *
 * @param {string} key - key, expected to be provisioned in composition
 * @param {Gaia.Model.Spec} obj - model, provided by sequence call
 * @param {Object} data - data, provided by sequence call
 * @return {*}
 */
const fn = (key, obj, data) => {
  console.warn(descriptor, key)

  const _data = data
  const item = obj[G.CHILDREN][key]
  const itemState = item[G.STATE]
  const { type } = item[G.PROPS]

  try {
    const ref = itemState ? itemState[G.REF] : null
    const existingRefs = item[G.CACHE]

    def(ref) ? _data[type] = _data[type] || {} : delete _data[type][key]

    if (ref !== null) {
      isArr(ref)
        && (_data[type][key] = ref)

      isStr(ref)
        && !existingRefs?.includes(ref)
        && (_data[type][key] = existingRefs?.length ? existingRefs.concat(ref) : [ref])
    } else {
      _data[type][key] = item[G.CACHE]
    }
  } catch (e) {
    console.warn(descriptor, obj._name, key, e.message)
  }

  return _data
}

export default curry(fn)
