/* eslint-disable object-curly-newline,no-param-reassign,no-unused-expressions,
no-case-declarations */
/* global G */
import { v4 as uuidV4 } from 'uuid'
import asObject from 'lib/sequence/component/children/asObject'
import validate from 'lib/sequence/model/validate'
import { hidden } from 'lib/sequence/component/state/hidden'
import validateStepperFn from 'app/_shared/component/stepper/validate'
import session from 'app/_shared/session'
import seqModelReset from 'lib/sequence/model/api/reset'
import { check, checked } from 'lib/sequence/component/state/checked'
import refresh from 'lib/sequence/model/api/refresh'
import { asyncPipeSpread, asyncPipeSpreadIf, deleteKey, getFirstItem, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get, set } from 'lib/sequence/component/state/value'

/**
 *
 * @param target
 * @param source
 * @private
 */
const _assign = (target, source) => {
  target[G.STATE][G.REF] = source[G.STATE] ? source[G.STATE][G.REF] : source?.key?.()
  target[G.DATA].value = source[G.CACHE] || source?.ref?.({ wrap: true })
}

/**
 * Finishes the creation of the ticket's data by deducing some of its own references from the ones
 * in some of its model attributes or session data. Specifically.
 *
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const composeTicket = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContact, item, status, statusReason } = model[G.CHILDREN]
  const { submitter, submitterTeam } = model[G.CHILDREN]
  const { organisation } = requesterContact[G.CHILDREN]
  const { installedAt, serviceBy, product, equipment } = item[G.CHILDREN]
  const currentSession = session(module)
  const { user, team } = currentSession

  // Getting assignee and possible pending or closed checkboxes
  const { assignee, status: creationStatus } = components
  const currentCreationStatus = creationStatus && get(creationStatus)
  const currentAssignee = assignee && getFirstItem(get(assignee))

  // set the initial status of the ticket
  !model[G.DATA].status
  && !status[G.CACHE]
  && (status[G.DATA].value = 30)
  && (statusReason[G.DATA].value = currentAssignee && !currentCreationStatus ? 32 : 31)
  // set the current user as the ticket's submitter if the ticket doesn't already have it
  !submitter[G.STATE][G.REF] && _assign(submitter, user)
  // set the team of the current user as the ticket's submitterTeam if the ticket doesn't have it
  !submitterTeam[G.STATE][G.REF] && _assign(submitterTeam, team)
  // set the contact's organisation as the ticket's requesterContactOrg
  _assign(model[G.CHILDREN].requesterContactOrg, organisation)
  // set the item's installedAt as the ticket's itemInstalledAt
  _assign(model[G.CHILDREN].itemInstalledAt, installedAt)
  // set the item's serviceBy as the ticket's itemServiceBy
  _assign(model[G.CHILDREN].itemServiceBy, serviceBy)
  // set the item's product as the ticket's product
  _assign(model[G.CHILDREN].product, product)
  // set the item's equipment as the ticket's equipment
  _assign(model[G.CHILDREN].equipment, equipment)

  return [components, ...args]
}

/**
 * Checks whether any of the registerInstalledAt checkboxes placed at the device and party steps is
 * checked and modifies the data so that the device is saved accordingly.
 *
 * @param {Gaia.AppModule.Spec} module
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const registerDevice = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterOrg, item, additionalParty } = model[G.CHILDREN]
  const { installedAt } = item[G.CHILDREN]
  const { device, party } = components
  const { registerInstalledAt: registerAtRequesterOrg } = device ? find(device) : {}
  const { registerInstalledAt: registerAtAdditionalParty } = party ? find(party) : {}
  const checkedRegisterAtRequesterOrg = device && checked(registerAtRequesterOrg)
  const checkedRegisterAtAdditionalParty = party && checked(registerAtAdditionalParty)
  // store model reference corresponding to selected checkbox
  const installTarget = (checkedRegisterAtRequesterOrg && requesterOrg)
    || (checkedRegisterAtAdditionalParty && additionalParty)
    || null
  // if the device is to be registered somewhere...
  installTarget
    // set the installation target as the item's installedAt attribute
    && await refresh(installedAt, installTarget[G.CACHE])
    // set the item to be added to the root of the request's payload
    && setKey(true, G.BULK, item[G.STATE])
    // if the item does not have an id
    && !item[G.STATE][G.REF]
    // set an id to it
    && setKey(uuidV4(), G.REF, item[G.STATE])
    // set its initial status
    && setKey(50, 'status', item[G.DATA])

  return [components, ...args]
}

/**
 * Sets error state to the current module and model if any one of the previous validations failed.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateTicket = module => () => async (components, stepperValid, ...args) => {
  const model = module[G.MODEL]
  const moduleState = module[G.STATE]
  const { requesterContactData, additionalPartyData, description } = model[G.CHILDREN]
  // gather model errors
  const modelValid = !model[G.STATE][G.ERROR]
    && !description[G.STATE][G.ERROR]
    && !additionalPartyData[G.STATE][G.ERROR]
    && !requesterContactData[G.STATE][G.ERROR]
  // store validation result
  const valid = stepperValid && modelValid
  // update module and model states
  model[G.STATE][G.ERROR] = !valid
  moduleState[G.ERROR] = !valid

  return [components, ...args]
}

/**
 * Checks that all required steps have completed and confirmed states and sets them all as visited.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateStepper = module => () => async (components, ...args) => {
  const result = validateStepperFn(module)
  return [components, result, ...args]
}

/**
 * Validates the finish step against the model and its postponeDate attribute.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateFinishStep = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { postponeDate } = model[G.CHILDREN]
  const { stepper } = components
  const { finish } = asObject(stepper[G.CHILDREN])
  const { closed, pending } = finish ? asObject(finish[G.CHILDREN]) : {}
  const stepVisible = finish && !hidden(finish)
  // validate ticket's team and assignee attributes
  stepVisible
    && await validate(model)(finish)
  // validate ticket's closed attribute
  closed
    && stepVisible
    && !hidden(closed)
    && await validate(model)(closed)
  // validate ticket's pending attribute
  pending
    && stepVisible
    && !hidden(pending)
    ? await validate(model)(pending)
    : seqModelReset(postponeDate)
      && (model[G.DATA].postponeDate = null)

  return [components, ...args]
}

/**
 * Validates the issue step, if it is not hidden, against the model's description attribute.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateIssueStep = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { description } = model[G.CHILDREN]
  const { stepper } = components
  const { issue } = asObject(stepper[G.CHILDREN])
  const {
    description: descriptionSection,
    softwareInfo,
    fieldServiceInfo,
  } = issue ? asObject(issue[G.CHILDREN]) : {}
  const stepVisible = issue && !hidden(issue)
  // clearing attribute's error state
  deleteKey(G.ERROR, description[G.STATE])
  // validate ticket's description attribute
  stepVisible
    && await validate(description)(descriptionSection)
  // validate description's device* attributes
  softwareInfo
    && stepVisible
    && !hidden(softwareInfo)
    && await validate(description)(softwareInfo)
  fieldServiceInfo
    && stepVisible
    && !hidden(fieldServiceInfo)
    && await validate(description)(fieldServiceInfo)

  return [components, ...args]
}

/**
 * Validates the party step, if it is not hidden, against the model's additionalPartyData attribute.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validatePartyStep = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { additionalPartyData } = model[G.CHILDREN]
  const { stepper } = components
  const { party: step } = asObject(stepper[G.CHILDREN])
  const { confirm } = step ? asObject(step[G.CHILDREN]) : {}
  const stepVisible = step && !hidden(step)
  // clearing attribute's error state
  deleteKey(G.ERROR, additionalPartyData[G.STATE])
  // validate additionalParty's contactDetails and informationTransferApproved attributes
  stepVisible
  && await validate(additionalPartyData)(confirm)

  return [components, ...args]
}

/**
 * Validates the type step, if it is not hidden, against the model.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateTypeStep = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { stepper } = components
  const { type } = asObject(stepper[G.CHILDREN])
  const stepVisible = type && !hidden(type)
  // validate type attribute if the step is not hidden
  stepVisible && await validate(model)(type)

  return [components, ...args]
}

/**
 * Validates the contact step against the model and its requesterContactData attribute.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(*, ...[*]): Promise<*>}
 */
const validateContactStep = module => () => async (components, ...args) => {
  const model = module[G.MODEL]
  const { requesterContactData } = model[G.CHILDREN]
  const { stepper } = components
  const { contact: step } = asObject(stepper[G.CHILDREN])
  const { stepForm } = find(step)
  const { createRequest } = asObject(stepForm[G.CHILDREN])

  createRequest[G.STATE].value === undefined
    && set(createRequest)
    && check(createRequest)
  // clearing attribute's error state
  deleteKey(G.ERROR, requesterContactData[G.STATE])
  // validate requesterContact's prefChannel attribute
  await validate(requesterContactData)(stepForm)
  // validate ticket's createRequest attribute
  await validate(model)(stepForm)

  return [components, ...args]
}

/**
 * Removes any previous module and model errors.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(): function(...[*]): Promise<*>}
 */
const purgeError = module => () => async (...args) => {
  const model = module[G.MODEL]
  deleteKey(G.ERROR, module[G.STATE])
  deleteKey(G.ERROR, model[G.STATE])
  return args
}

/**
 * Checks whether the currently inserted ticket data is valid and all required steps are completed.
 * If that's the case, finishes filling the ticket with data that can be deduced.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
const bulk = module => () => async (...args) => asyncPipeSpread(
  purgeError(module)(),
  validateContactStep(module)(),
  validateTypeStep(module)(),
  validatePartyStep(module)(),
  validateIssueStep(module)(),
  validateFinishStep(module)(),
  validateStepper(module)(),
  validateTicket(module)(),
  asyncPipeSpreadIf(() => !module[G.STATE][G.ERROR])(
    registerDevice(module)(),
    composeTicket(module)(),
  ),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)

export default bulk
