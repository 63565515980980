/* global G */
import { curry } from 'lib/util'

/**
 * From ContactChannels Decorator
 *
 * Transforms a contactChannels array to a contactChannels object.
 *
 * @example
 * const contactChannelsArray = [
 *   {
 *      type: 'phone',
 *      name: 'phone',
 *      value: '+49 (30) 3432622-113'
 *   },
 *   {
 *      type: 'phone',
 *      name: 'officePhone',
 *      value: '+34 478 98 51 45'
 *   },
 *   {
 *      type: 'email',
 *      name: 'email',
 *      value: 'e.zulauf@weber.de'
 *   }
 * ]
 *
 * @example
 * const contactChannelsObject = {
 *  phone: '+49 (30) 3432622-113',
 *  officePhone: '+34 478 98 51 45',
 *  email: 'e.zulauf@weber.de'
 *}
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} component    the component that matched the attribute name during the mapping
 * @returns {*}
 */
const fn = (key, obj, component) => {
  const attribute = obj[G.CHILDREN][key]
  const childKeys = Object.keys(attribute[G.CHILDREN])

  // const cache = obj[G.CACHE] && (obj[G.CACHE][0] ? obj[G.CACHE][0] : obj[G.CACHE].value)
  // attribute[G.CACHE] = cache ? cache[key] : null
  // now i am going to iterate over attribute children, and see if the new payload contains same
  // keys as attribute children.
  attribute[G.CACHE] !== null && attribute[G.CACHE].reduce((acc, item) => {
    const index = acc.findIndex((childKey) => {
      const child = attribute[G.CHILDREN][childKey]
      const { channelType } = child[G.CONFIGURATION].options
      const found = channelType === item.type
      found && (child[G.CACHE] = item.value)
      return found
    })
    index > -1 && acc.splice(index, 1)
    return acc
  }, childKeys)

  return component
}

export default curry(fn)
