/* global G */
import { curry } from '@gaia/util'

/**
 * count values based on url params
 *
 * @param {Gaia.AppModule.Spec} module - app module
 * @param {Gaia.Component.Spec} component - action component
 * @param {Event} event - the event that triggered this handler
 * @param {Object} event.detail - custom event details
 * @param {string} event.detail.key - a model key to use for the count api call
 * @param {string} event.detail.type - a type to use for the count api call
 * @return {Promise<{total: {count}}>} void
 */
const count = async (module, component, event) => {
  const { key, type: relation } = event.detail
  const obj = module[G.MODEL]
  const { api, version } = obj[G.PROPS]
  // /api/v1/organisation/ORG:001/count/serviceItem
  // /api/v1/person/USER:ID/count/message
  const url = `/api/v${version}/${api}/${key}/count/${relation}`
  try {
    return await obj[G.ADAPTER][G.HTTP].get({ url })
  } catch (e) {
    return { total: { count: e.message } }
  }
}

export default curry(count)
