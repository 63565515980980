/* global G */
import { getFirstItem, isArr } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import { checked } from 'lib/sequence/component/state/checked'

/**
 * Enables/Disables the {@code verify} button based on the selected role and confirm
 * checkbox.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayVerifyButton = module => async (children, ...args) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const [checkboxes, actions] = actionComponent[G.ACTIONS]

  const { role } = children
  const { confirm } = sequenceComponentFind(checkboxes)
  const { btnVerify } = sequenceComponentFind(actions)

  const roleValue = get(role)
  const hasValue = roleValue && isArr(roleValue) && roleValue.length

  hasValue && checked(confirm)
    ? enable(btnVerify)
    : disable(btnVerify)

  return args
}

export default displayVerifyButton
