/* global G */
import { curry } from 'lib/util'

/**
 * Get Node Attachment Event Handler
 *
 * Will be given a list of attachments inside `event.detail`, which are the attachments
 * for the group defined in the action config. It'll then use the current file node defined
 * in the action state and use it to find the correct attachment for said node using either
 * the `url` property, in case it's a video, or the `value.resourceId` property.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      the event object that triggered this handler
 * @returns {Attachment|null}
 */
const getNodeAttachment = (module, component, event) => {
  const { fileNode = {} } = module[G.STATE][G.ACTION][G.STATE]
  const { url = null, attachment: { value: { resourceId = null } = {} } = {} } = fileNode

  const { attachments = [] } = event?.detail || event || {}

  return attachments.find(attachment => (url && attachment?.url === url)
    || (resourceId && attachment?.value?.resourceId === resourceId))
    || null
}

export default curry(getNodeAttachment)
