/* global G */
import { curry } from '@gaia/util'

/**
 * ToNullable Transformer
 *
 * Nulls the input if requested
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} data         the payload data processed until now
 * @return {*}
 */
const toNullable = (key, obj, data) => {
  const _data = data

  const item = obj[G.CHILDREN][key]
  const { type } = item[G.PROPS]

  const value = obj[G.DATA][key]

  if (value?.length === 0 || !value) {
    _data[type][key] = null
  } else {
    _data[type][key] = value
  }

  return _data
}

export default curry(toNullable)
