/* eslint-disable no-unused-vars */
import { curry, getFirstItem, isNum } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { checked } from 'lib/sequence/component/state/checked'
import internal from 'model/organisation/collection/internal'
import concatQueryParams from 'app/_shared/events/search/query/util'

/**
 * Sets the {@code query} parameter for the current search, based on the given search term in
 * {@param event.detail.term} and the currently selected {@param filters} fields.
 *
 * @param {string} type                     document type to query
 * @param {string[]} filters                list of filters that can be applied
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 * @returns {string}
 */
const hasMultipleFilters = (type, filters, module, component, event) => {
  const {
    status,
    country,
    isToBeValidated,
    isToBeApproved,
    providesSupport,
  } = find(component)

  // status
  const selectedStatus = status ? getFirstItem(get(status)) : null
  const statusKey = isNum(selectedStatus?.key) ? selectedStatus.key : null

  // toBeApproved
  const internalOrgs = internal
    .map(i => i.key.replaceAll(':', '\\:'))
    .join(' ')

  // country
  const selectedCountry = country ? getFirstItem(get(country)) : null
  const countryKey = selectedCountry?.key ?? null

  const params = {
    type: type ? `metatype:${type}` : '*:*',
    term: event?.detail?.value
      ? `AND id:${event.detail.value}*`
      : '',
    status: filters.includes('status') && isNum(statusKey)
      ? `AND status:(${statusKey})`
      : '',
    validated: filters.includes('toBeValidated') && checked(isToBeValidated)
      ? 'AND toBeValidated_action:(c* u* d*)'
      : '',
    approved: filters.includes('toBeApproved') && checked(isToBeApproved)
      ? `AND parent:(${internalOrgs})`
      : '',
    support: filters.includes('providesSupport') && checked(providesSupport)
      ? 'AND support_provides:(true)'
      : '',
    country: filters.includes('country') && countryKey
      ? `AND address_country:(${countryKey})`
      : '',
  }

  return concatQueryParams(params)
}

export default curry(hasMultipleFilters)
