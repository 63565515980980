/* eslint-disable no-unused-vars */
import { hide, show } from 'lib/sequence/component/state/hidden'

/**
 * Displays the delete text of the right side and hides the normal text.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayDeleteDescription = module => async (children, ...args) => {
  const { dataDescription, dataDeleteDescription } = children

  hide(dataDescription)
  show(dataDeleteDescription)

  return [children, ...args]
}

export default displayDeleteDescription
