/* global G */
import { poll } from 'lib/util'

/**
 * Wraps {@link poll} within a loader.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @param {Function} func       function to call in each iteration
 * @param {Function} predicate  predicate to check in each iteration
 * @param {number} timeout      time in ms to wait between each iteration
 * @returns {Promise<void>}
 */
const pollWithLoader = async (module, func, predicate, timeout) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  eventBus.dispatch(eventBus.type(G.LOAD, G.INIT))

  await poll(func, predicate, timeout)

  eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))
}

const utils = {
  poll,
  pollWithLoader,
}

export {
  utils as default,
  pollWithLoader,
}
