/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'

const {
  set: setDrawerTitle,
  get: drawerTitle,
} = sequenceComponentState('drawerTitle')

/**
 * Sets the result of calling the component's getLabel event handler as the drawerTitle prop of the
 * nearest list child. Then, updates the UI in order to apply the changes.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
const setListDrawerTitle = async (module, component, event) => {
  const { list = component } = find(component)
  const { title } = await component[G.EVENTS].getLabel?.(null) || {}
  const currentTitle = drawerTitle(list)

  if (list && title && !currentTitle) {
    setDrawerTitle(list, title)
    module[G.ADAPTER][G.UI].update(module)
  }
}

export default curry(setListDrawerTitle)
