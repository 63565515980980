/* eslint-disable object-curly-newline */
/* global G */
import { curry, setKey } from '@gaia/util'

/**
 * @typedef {Gaia.Module.EventHandler} Gaia.Module.AttachmentEventHandler
 * @param {Object} group      the attachment group {@param attachment} belongs to
 * @param {Object} attachment the attachment to delete
 */

/**
 * Returns the group prop of {@param component}, used to direct the attachment events.
 *
 * @param {Gaia.Component.Spec} component  a component
 * @return {string}                     either the value of the group prop or 'default'
 * @private
 */
export const _group = component => component?.[G.PROPS]?.group || 'default'

/**
 * Module Adapter Events Dispatch Sequence
 *
 * executes native events adapter api method dispatch() {@link EventBus.dispatch}
 *
 * @example
 * seqModuleAdapterEventsDispatch(
 *   module,
 *   [G.ATTACHMENT, G.DATA],
 *   { [G.DATA]: files, [G.PROPS]: component[G.PROPS].filter },
 * )
 *
 * @param {Gaia.AppModule.Spec} obj - module composition
 * @param {array} type - registered type path
 * @param {Object} detail - payload detail
 * @param args - arguments to pass thru
 * @return {function(...[*]): *[]}
 */
const seqModuleAdapterEventsDispatchFn = (obj, type, detail) => (...args) => {
  const adapter = obj[G.ADAPTER][G.EVENTS]
  adapter.dispatch(adapter.type(...type), { [G.DATA]: detail })
  return args
}
const seqModuleAdapterEventsDispatch = curry(seqModuleAdapterEventsDispatchFn)

/**
 * adds local attachments
 *
 * @type {Gaia.AppModule.EventHandler}
 */
const add = curry((module, component, e) => {
  e.preventDefault()
  e.stopPropagation()
  const files = (e.dataTransfer && [...e.dataTransfer.files])
    || (e.detail && [...e.detail.files])
  const { undo = true } = e.detail

  e.dataTransfer && e.dataTransfer.clearData()

  const { filter, single, api } = component[G.PROPS]
  const { value } = component[G.STATE]

  undo && setKey(true, G.UNDO, module[G.STATE][G.ACTION][G.STATE])

  return seqModuleAdapterEventsDispatch(
    module,
    [G.ATTACHMENT, G.ADD, _group(component)],
    {
      [G.DATA]: single ? [files.pop()] : files,
      [G.PROPS]: filter,
      [G.REF]: typeof single === 'string' ? single : '',
      [G.API]: { ...api, ref: value },
    },
  )(e)
})

/**
 * Immediately removes a local or remote file.
 *
 * provision key param via parent (gallery) children iterator
 *
 * @type {Gaia.Module.AttachmentEventHandler}
 */
const purge = curry((module, component, group, attachment, e) => seqModuleAdapterEventsDispatch(
  module,
  [G.ATTACHMENT, G.DELETE, group],
  attachment,
)(e))

/**
 * Removes a local file or marks a remote file to be deleted.
 *
 * @type {Gaia.Module.AttachmentEventHandler}
 */
const remove = curry((module, component, group, attachment, e) => seqModuleAdapterEventsDispatch(
  module,
  [G.ATTACHMENT, G.REMOVE, group],
  attachment,
)(e))

/**
 * provision attachment param via parent children iterator
 *
 * @type {Gaia.Module.AttachmentEventHandler}
 */
const create = curry((module, component, group, attachment, e) => {
  if (attachment.key !== attachment.value.name) {
    console.log('already remote', attachment.key)
    return e
  }
  return seqModuleAdapterEventsDispatch(
    module,
    [G.ATTACHMENT, G.CREATE, group],
    attachment,
  )(e)
})

/**
 * automated bulk add create
 *
 * @type {Gaia.AppModule.EventHandler}
 * @type {function(...[*]): (*)}
 */
const addCreate = curry((module, component, e) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  eventBus.add(eventBus.type(G.ATTACHMENT, G.DONE), ({ detail }) => {
    const { [G.DATA]: data } = detail
    data.forEach((attachment) => {
      attachment.key === attachment.value.name
      && create(module, component, attachment, e)
    })
  }, { once: true })
  return add(module, component, e)
})

/**
 * Creates all added files and removes all remote files marked to be removed.
 * Displays a loader for the duration of the upload.
 *
 * @type {Gaia.AppModule.EventHandler}
 * @type {(function(...[*]): (*))|*}
 */
const apply = curry(async (module, component, e) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  const eventData = await new Promise((resolve) => {
    // init loader
    eventBus.dispatch(eventBus.type(G.LOAD, G.INIT))
    const args = seqModuleAdapterEventsDispatch(
      module,
      [G.ATTACHMENT, G.APPLY, _group(component)],
      // { ref: component[G.STATE][G.REF] },
      {
        ...component && {
          [G.API]: {
            ref: component[G.STATE][G.REF],
            draft: component[G.STATE][G.DRAFT] || false,
          },
        },
      },
    )(e)

    // Attachment adapter will dispatch `G.ATTACHMENT, G.DONE, group` once all attachments
    // have been uploaded
    eventBus.add(eventBus.type(G.ATTACHMENT, G.DONE, _group(component)), () => {
      resolve(args)
    })
  })

  // Closing the loader once all attachments have been uploaded
  eventData && eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))

  return eventData
})

/**
 * Removes all added files and unmarks all remote files marked to be removed.
 *
 * @type {Gaia.AppModule.EventHandler}
 * @type {(function(...[*]): (*))|*}
 */
const undo = curry((module, component, e) => seqModuleAdapterEventsDispatch(
  module,
  [G.ATTACHMENT, G.UNDO, _group(component)],
  {},
)(e))

/**
 * Returns the current list of items attached to the group defined by the {@param component}'s
 * {@code group} prop.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
const get = curry(async (module, component, e) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]
  const group = _group(component)

  return new Promise((resolve) => {
    eventBus.add(eventBus.type(G.ATTACHMENT, G.DONE, group), ({ detail }) => {
      resolve((detail && detail[G.DATA]) || [])
    }, { once: true })

    seqModuleAdapterEventsDispatch(module, [G.ATTACHMENT, G.CACHE, group], {})(e)
  })
})

/**
 * Returns the current number of items attached to the group defined by the {@param component}'s
 * {@code group} prop.
 *
 * @type {Gaia.AppModule.EventHandler}
 */
const count = curry(async (module, component, e) => {
  const attachments = await get(module, component, e)
  return attachments?.length || 0
})

const attachmentEvents = {
  add, // adds single or bulk local files
  create,
  purge,
  remove,
  addCreate,
  apply,
  undo,
  get,
  count,
}

export {
  attachmentEvents as default,
  add,
  create,
  purge,
  remove,
  addCreate,
  get,
  apply,
  undo,
  count,
}
