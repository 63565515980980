/* global G */
import { curry, getFirstItem, isArr } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'

/**
 * Obtains the organisation with the id given in {@param event.detail.value} by comparing it to
 * the user's organisation and returning it in that case, or querying the server about the
 * information.
 *
 * This handler is used to get the organisation for a particular role (e.g. "ROLE@ORG:1").
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 *
 * @return {Promise<*|[{}]>}
 */
const getAtOrganisation = async (module, component, event) => {
  // model
  const model = module[G.MODEL]
  const { organisation } = model[G.CHILDREN]
  const { [G.HTTP]: httpAdapter } = model[G.ADAPTER]
  const { version, api } = organisation[G.CONFIGURATION].options
  const { value, index } = event?.detail || event

  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { organisation: organisationField, roles } = find(actionComponent)
  const currentUserOrg = getFirstItem(get(organisationField))
  const currentRoles = get(roles)
  const currentAtOrg = isArr(currentRoles)
    ? currentRoles.find(role => role.index === parseInt(index, 10))
    : Object.values(currentRoles).find(role => role.index === parseInt(index, 10))

  // It's possible for this component to get newly created by changing the users org.
  // If that's the case, we need to check for it and apply the new org instead of the old value
  if (currentAtOrg && currentUserOrg && currentAtOrg.atOrg === currentUserOrg.key) {
    return [{ ...currentUserOrg, disabled: currentAtOrg?.delete || false }]
  }

  // In create action, we have no org, event will be null
  if (!event) return [{}]

  // The resolved organisation ref is the one we need, no need to query the server
  if (organisation[G.STATE][G.REF] === value) {
    return {
      ...organisation[G.CACHE],
      disabled: currentAtOrg?.delete || false,
    }
  }

  try {
    const url = `/api/v${version}/${api}/${value}`
    const result = await httpAdapter.get({ url })
    return {
      ...result,
      disabled: currentAtOrg?.delete || false,
    }
  } catch (e) {
    console.error(e)

    return [{}]
  }
}

export default curry(getAtOrganisation)
