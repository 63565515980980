/* eslint-disable no-unused-expressions */
/* global G */
import sequenceComponentFind from 'lib/sequence/component/children/find'
import validate from 'lib/sequence/model/validate'
import { get } from 'lib/sequence/component/state/value'
import { add as addNote } from 'app/_shared/events/note'
import { fromTimeEntry } from 'app/ticket/event/note/time'
import { setKey } from 'lib/util'
import { sortNotesByDate } from 'app/ticket/hook/note'

/**
 * Returns the payload used to set the ticket as open and remove its assignee.
 *
 * @type QuickPayload
 *
 * @returns {Object}
 */
export default async (module, component) => {
  const model = module[G.MODEL]
  const { content, note, spentTime } = sequenceComponentFind(component)

  content && await validate(model)(content)
  model[G.STATE][G.ERROR] && throw Error('Model validation error')

  const noteText = get(note)
  const time = get(spentTime)
  const spentSeconds = time
    ? fromTimeEntry(module, component, { value: time })
    : null;

  ((noteText && noteText.length) || time) && addNote(module, component, {
    text: !noteText && time ? '-' : noteText,
    type: 'stop',
    ...!noteText && time && { timeOnly: true },
    ...spentSeconds && { spentTime: spentSeconds },
  }, (item) => {
    const { value } = item
    const timeNotes = module[G.STATE]?.timeNotes
    // So that the new time note is available without fetching them again
    value?.spentTime && setKey(sortNotesByDate([item, ...timeNotes]), 'timeNotes', module[G.STATE])
  })

  return {
    value: {
      status: 30,
      statusReason: 0,
      postponeDate: null,
    },
    refs: {
      assignee: [],
    },
  }
}
