/* global G */
import { isArr } from 'lib/util'
import routeComposition from 'trait/composition/route'
import redirect from 'lib/hook/router/redirect'
import back from 'app/_shared/hook/redirect/back'

/**
 * Redirect To Approve Hook
 *
 * Redirects to either the {@link approveAsOriginal} or {@link approveAsDuplicate}
 * route based on the result of the duplicate check.
 *
 * If the check has not been performed. It will execute {@link back}.
 *
 * @param {Gaia.AppModule.Spec} obj    the current module composition object
 * @returns {(function(...[*]): Promise<*|undefined|*[]|*[]>)|*}
 */
const redirectToApprove = obj => async (...args) => {
  const moduleState = obj[G.STATE]
  const updatedData = obj[G.MODEL][G.CHILDREN].organisation?.[G.STATE]?.[G.DATA] || {}
  const duplicateOf = updatedData?.refs?.duplicateOf

  if (duplicateOf && isArr(duplicateOf)) {
    if (!duplicateOf.length) {
      moduleState[G.ROUTE] = routeComposition(null, 'approveAsOriginal')
    } else {
      moduleState[G.ROUTE] = routeComposition(null, 'approveAsDuplicate')
    }
    return redirect(obj)(args)
  }

  // Fallback
  return back(obj)(args)
}

export default redirectToApprove
