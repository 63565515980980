/* global G */
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { curry, setKey } from 'lib/util'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'

/**
 * Set Search Term And Reload List Event Handler
 *
 * Reloads the list with the proper search term.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {PlatformEvent} event           the event object that triggered this handler
 * @returns {Promise<void>}
 */
const setSearchTermAndReloadList = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { duplicateOf } = sequenceComponentFindProxy(actionComponent)

  setKey({}, 'reload', duplicateOf[G.STATE])
  setKey({}, G.META, duplicateOf[G.STATE])

  await searchAndRecreate(module, component, { ...event, detail: { ...event.detail, reload: false } })
  await module[G.ADAPTER][G.UI].update(module)
}

export default curry(setSearchTermAndReloadList)
