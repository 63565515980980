/* global G */
import ui from 'lib/hook/ui/update'

const _baseHooks = {
  before: [],
  after: [
    ui,
  ],
}

export default {
  [G.ACTIONS]: {
    index: _baseHooks,
    acl: _baseHooks,
  },
}
