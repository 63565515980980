/* eslint-disable no-unused-vars */
/* global G */
import { curry, isNum } from 'lib/util'
import session from 'app/_shared/session'

/**
 * Default params for a listing.
 *
 * @type {{limit: number, descending: boolean}}
 */
const defaultParams = {
  limit: 100,
  descending: false,
}

/**
 * Default response for a listing in case the db query failed.
 *
 * @type {[{value: string, key: number}]}
 */
const defaultResponse = [
  { key: -1, value: 'Not Found' },
]

/**
 * Get params used for the listing based on {@link defaultParams}.
 *
 * @param {Object} params   additional query params overwriting the default ones
 * @returns {*}
 * @private
 */
const _getParams = (params = {}) => Object.keys(params).reduce(
  (acc, key) => ({ ...acc, ...params[key] || isNum(params[key]) ? { [key]: params[key] } : {} }),
  defaultParams,
)

/**
 * Get properties of {@param component}'s {@code G.PROPS} dynamically.
 *
 * @example
 * Basically does the same as
 * {@code const { key, limit, api } = component[G.PROPS]}
 * with the ability to specify additional props in {@param additionalProps}.
 *
 * @param {Gaia.Component.Spec} component ui component composition
 * @returns {*}
 * @private
 */
const _getComponentProps = component => ({
  key: component[G.PROPS].key,
  limit: component[G.PROPS].limit,
  api: component[G.PROPS].api,
  refName: component[G.PROPS].refName,
  minStatus: component[G.PROPS].minStatus,
  maxStatus: component[G.PROPS].maxStatus,
})

/**
 * API Listing Reference Resolver
 *
 * It resolves the reference, based on provided key and model composition.
 * It takes into account, if model attribute is a model
 * It uses model's configuration as default return value. todo: bdd.
 *
 * @param {Gaia.Model.Spec} model model composition
 * @param {string} key            used to identify model attribute
 * @return {{listing: string}}    listing - listing reference
 * @private
 */
const _resolveListingReference = (model, key) => {
  const { [G.CHILDREN]: children, [G.PROPS]: props } = model
  const modelAttributeProps = children?.[key]?.[G.PROPS]
  const { listing } = !!modelAttributeProps && (modelAttributeProps || modelAttributeProps.api)
  return { listing: listing || props.listing }
}

// const _resolveIdByModelRef = (model, key) => {
//   console.log('key', key)
//   console.log(model[G.CHILDREN][key])
//   console.log(model)
//
//   if (model[G.CHILDREN][key][G.CHILDREN]) {
//     return _resolveIdByModelRef(model[G.CHILDREN][key], key)
//   }
//
//   const { [G.REF]: ref } = key
//     ? model[G.CHILDREN][key][G.STATE] // this throws, if attribute is not a model
//     : model[G.STATE]
//
//   console.log(ref)
//   return ref
// }

/**
 * Resolve the id for the current listing.
 *
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {String} key                  listing reference
 * @param {String} attribute            ref
 * @returns {*}
 * @private
 */
const _resolveListingId = (module, key, attribute) => {
  const { [G.REF]: ref } = attribute
    ? module[G.MODEL][G.CHILDREN][attribute][G.STATE] // this throws, if attribute is not a model
    : module[G.MODEL][G.STATE]

  const id = session(module)[key] && session(module)[key].key() // org|user id
  return ref || id
  // an issue arises in request.new.step3,
  // as there is a mixed bag of id & ref,
  // both belonging to api.listing | api.ref
  // todo: compare listing | ref, only then decide what to return
  // return ref || id
}

/**
 * List
 *
 * used for fetching data for rendered iterable ui components
 * executes remote api call with given parameters
 *
 * todo: move to sequence::model::list? can list and stepper coexist? both use same namespaces.
 *  think, different scopes - stepper as module, list as component and vise versa, ok
 *
 * @param {string} type                   listing type, ie short, verbose, etc
 * @param {Gaia.AppModule.Spec} module    app module
 * @param {Gaia.Component.Spec} component action component
 * @param {Event} event                   event
 * @return {Promise<void>} void
 */
const listFn = async (type, module, component, event) => {
  try {
    const { version } = module[G.MODEL][G.PROPS]
    const { key, limit, api, minStatus = null, maxStatus = null } = _getComponentProps(component)
    const { listing, ref, descending } = api || _resolveListingReference(module[G.MODEL], key)
    // ref can be injected via an action, ie request step1..
    const refId = _resolveListingId(module, listing, component[G.STATE][G.REF])

    const url = `/api/v${version}/${listing}/${refId}/listing/${ref || key}${type ? `/${type}` : ''}` // + (type && `/${type}`)
    const params = _getParams({ limit, descending, min_status: minStatus, max_status: maxStatus })

    try {
      const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
      const result = await remoteAdapter.get({ url, params })
      return result.value
    } catch {
      console.error('list:: tried and failed getting remote resources', url)
    }
  } catch (e) {
    console.error(e)
  }

  return defaultResponse
}

/**
 * refList
 *
 * works as {@link listFn}, with the exception that the name of
 * reference to resolve can be specified (like {@code parent}, {@code itemInstalledBy}, ...)
 *
 * @param {string} type                   listing type, ie short, verbose, etc
 * @param {Gaia.AppModule.Spec} module    app module
 * @param {Gaia.Component.Spec} component action component
 * @param {Event} event                   event
 * @returns {Promise<{value: string, key: number}[]|*>}
 */
const refListFn = async (type, module, component, event) => {
  const { version } = module[G.MODEL][G.PROPS]
  const { key, refName, limit, api, minStatus = null, maxStatus = null } = _getComponentProps(component)
  const { listing, ref, descending } = api || _resolveListingReference(module[G.MODEL], key)
  const refId = _resolveListingId(module, listing, component[G.STATE][G.REF])

  const url = `/api/v${version}/${listing}/${refId}/reflisting/${ref || key}/${refName}${type ? `/${type}` : ''}`
  const params = _getParams({ limit, descending, min_status: minStatus, max_status: maxStatus })

  try {
    const remoteAdapter = module[G.MODEL][G.ADAPTER][G.HTTP]
    const result = await remoteAdapter.get({ url, params })
    return result.value
  } catch {
    console.error('list:: tried and failed getting remote resources', url)
  }

  return defaultResponse
}

export const refList = curry(refListFn)
export default curry(listFn)
