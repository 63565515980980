/* global G */
import { curry, isObj } from 'lib/util'

const descriptor = 'model::transformer::toStatus'

/**
 * To Status Transformer
 *
 * Transforms a status object to a status number.
 *
 * If {@code status} is an object, it'll use its {@code key} attribute.
 * If it's not an object, it will use the value itself.
 *
 *
 * @param {string} key          the name of the attribute to store the data to
 * @param {Gaia.Model.Spec} obj the current model object composition
 * @param {Object} data         the payload data processed until now
 * @return {Object}             the payload data
 */
const fn = (key, obj, data) => {
  console.warn(descriptor, key)
  const _data = data
  const attribute = obj[G.CHILDREN][key]
  const attributeData = attribute[G.DATA]?.value || obj[G.DATA][key]

  _data.value[key] = isObj(attributeData) ? attributeData.key : attributeData

  return _data
}

export default curry(fn)
