/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { actionFn } from 'lib/sequence/module/action'

/**
 * Persist a partial value as {@param target} in the components' {@code state.value} {@param index}
 * position.
 *
 * It's meant to be used to add a property for a combined value (e.g. "Customer@ORG:1").
 *
 * @param {String} source               the element to mutate the state of
 * @param {String} target               the property of the object to set
 * @param {Gaia.AppModule.Spec} module  app module
 * @param {Gaia.Component} component    ui component
 * @param {Gaia.PlatformEvent} event    event
 * @return {Promise<void>}              void
 */
const persistPartial = async (source, target, module, component, event) => {
  event?.preventDefault?.()
  event?.stopPropagation?.()

  const { index } = event?.detail || event || {}

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { [source]: element = component } = sequenceComponentFindProxy(actionComponent)

  const state = element[G.STATE]

  // TODO: Future use cases: What if the value is not decorated (an array)?
  state.value = {
    ...state.value,
    [index]: {
      ...state.value?.[index] || {},
      [target]: event.detail.item?.key || '',
    },
  }
}

export default curry(persistPartial)
